import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FormGenerator from "../../../tools/components/v1/FormGenerator/FormGenerator";
import {
  editMutationAction,
  toggleToastAction,
} from "../../../store/actions/actions";
import URLS from "../../../shared/js/urls";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import { withRouter } from "react-router-dom";
import Spinner from "tools/components/v1/widgets/Spinner/Spinner";
import "../verification.css";
import NoItems from "tools/components/v1/widgets/NoItems/NoItems";
import { VERIFCATION_STATUSES } from 'shared/js/typeConstants';

class EnterpriseIncorporationDocuments extends Component {
  constructor(props) {
    super(props);
    this.documentsRef = React.createRef();

    this.state = {
      loading: false,
    };
  }
  componentDidMount() {
    let { onMount } = this.props;
    if (onMount) {
      onMount(() => this.saveDataToDB());
    }
  }

  extractDocument = (data) => {
    if (data?.primary_requirement_document?.data) {
      data.primary_requirement_document =
        data?.primary_requirement_document?.data?.file?.data;
    } else {
      delete data.primary_requirement_document;
    }
    if (data?.secondary_requirement_document?.data) {
      data.secondary_requirement_document =
        data?.secondary_requirement_document?.data?.file?.data;
    } else {
      delete data.secondary_requirement_document;
    }
    if (data?.other_requirement_document?.data) {
      data.other_requirement_document =
        data?.other_requirement_document?.data?.file?.data;
    } else {
      delete data.other_requirement_document;
    }
    return data;
  };

  saveDataToDB = async () => {
    let { enterprise, enterprises, requirements, compliance_documents } =
      this.props;
    const data = this.documentsRef.current?.onClickFromOutside();
    let res;
    if (data) {
      this.setState({ loading: true });
      data.enterprise = enterprise?.id;
      data.country_requirement = requirements?.id;
      data.status = VERIFCATION_STATUSES.SUBMITTED;

      let toSend = this.extractDocument(data);

      if (compliance_documents?.id) {
        toSend.id = compliance_documents?.id;
        res = await ApiCallHandler.send(
          URLS.UPDATE_ENTERPRISE_COMPLIANCES,
          "POST",
          toSend
          );
        } else {
        res = await ApiCallHandler.send(
          URLS.CREATE_ENTERPRISE_COMPLIANCES,
          "POST",
          toSend
        );
      }
      if (res?.success) {
        this.setState({ loading: false });
        let newEnterprise = enterprise;
        newEnterprise.compliance_documents = res?.data;
        this.props.editRecord(enterprises, newEnterprise);
        this.props.toggleToast({
          show: true,
          message:
            "Congratulations! Your compliance documents and information have been successfully submitted. Familiarize yourself with the platform as we verify you. Thank you.",
          type: "success",
        });
        this.props.history.push("/dashboard");
      } else {
        this.setState({ loading: false });
        this.props.toggleToast({
          show: true,
          message: "An error occured. Please try again",
          type: "failure",
        });
      }
    }
  };
  render() {
    let { requirements, compliance_documents } = this.props;
    const main = [
      {
        fieldType: FormGenerator.Fields.FILE,
        label: requirements?.id
          ? requirements?.primary_requirement_name
          : "Primary Document",
        name: "primary_requirement_document",
        placeholder: "Select Document",
        required: true,
        value: compliance_documents?.id
          ? compliance_documents?.primary_requirement_document
          : "",
      },
      {
        fieldType: FormGenerator.Fields.FILE,
        label: requirements?.id
          ? requirements?.secondary_requirement_name
          : "Secondary Document",
        name: "secondary_requirement_document",
        placeholder: "Select Document",
        required: true,
        value: compliance_documents?.id
          ? compliance_documents?.secondary_requirement_document
          : "",
      },
    ];

    const otherDoc = [
      {
        fieldType: FormGenerator.Fields.FILE,
        label: requirements?.id
          ? requirements?.other_requirement_name
          : "Other Document",
        name: "other_requirement_document",
        placeholder: "Select Document",
        required: true,
        value: compliance_documents?.id
          ? compliance_documents?.other_requirement_document
          : "",
      },
    ];

    const formData = requirements?.other_requirement_name
      ? [...main, ...otherDoc]
      : main;
      let {enterprise} = this.props;
    return (
      <div style={{ position: "relative" }}>
        {" "}
        {enterprise?.country ? (
          <FormGenerator
            elevation={0}
            subtitle=""
            title=""
            formSubmitBtnText={"Save Changes"}
            fields={formData}
            hideSubmitBtn={true}
            ref={this.documentsRef}
          />
        ) : (
          <div id="no-country-selected-notification">
            <NoItems text={`Incorporation Document vary for different countries. Please set
              the country of your enterprise in "Address section" to see the
              required documents.`} />
          </div>
        )}
        {this.state.loading ? (
          <div id="loading-overlay-root">
            <div id="activity-indicators-container">
              <Spinner variation={"TwoHalfCirclesType"} />
              <p>submitting data ...</p>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const enterpriseLoc = localStorage.currentEnterprise
    ? JSON.parse(localStorage.currentEnterprise)
    : { name: "" };
  const enterprise = state.enterprises?.filter(
    (item) => item.id === enterpriseLoc.id
  )[0];
  return {
    enterprise: enterprise,
    requirements: state.countryEnterpriseRequirements ||{},
    compliance_documents:
      enterprise?.compliance_documents !== null
        ? enterprise?.compliance_documents
        : {},
    enterprises: state.enterprises,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editRecord: (current_data, new_data) =>
        dispatch(
          editMutationAction("ENTERPRISE_MUTATION", current_data, new_data)
        ),

      toggleToast: toggleToastAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EnterpriseIncorporationDocuments)
);
