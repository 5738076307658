import { CURRENCIES } from "./typeConstants";
import { ALL_COUNTRIES_AND_CURRENCIES } from './countries';

/* eslint-disable no-useless-escape */
export const NAME = "____name____";
export const OBJECT_OBJECT = "[object Object]";
export const VALUE = "value";
export const LABEL = "label";
export const isArrayOfObjects = (data) => {
  if (!data) return false;
  const one = data[0];
  if (typeof one === "object") return true;
  return false;
};
export const lowKeyValidation = (props) => {
  const { data, valueExtractor, labelExtractor } = props;
  if (!data)
    console.error(
      "You have not provided any 'data'. 'data' should be an array of [string || object]"
    );

  const elemsAreObjs = isArrayOfObjects(data);
  if (elemsAreObjs && !labelExtractor)
    console.error(
      "[labelExtractor] Provide a function that returns parts of the object you would like to display as the checkbox label... "
    );

  if (elemsAreObjs && !valueExtractor)
    console.warn(
      "[vaueExtractor] Provide a function that returns parts of the object you would like to return as value onChange for each checkbox..."
    );
};

/**
 *
 * Convert a base64 String back to a file object
 * @param {base64String} base64String
 * @param {String} filename
 * @returns {File} image File Object
 *
 */
export const base64StringtoFile = (
  base64String,
  filename = "filename-" + getRandomStringKey()
) => {
  var arr = base64String.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const getValueOrLabel = (item, key, props) => {
  const { labelExtractor, valueExtractor, data } = props;
  const isObjects = isArrayOfObjects(data);
  if (key === LABEL && labelExtractor && isObjects) return labelExtractor(item);
  if (key === VALUE && valueExtractor && isObjects) return valueExtractor(item);
  return (item || "...").toString();
};

export const getRandomStringKey = (limit = 9999999) => {
  return Math.random(limit).toString();
};

export const stripItemFromArray = (value, comparisonFieldName, array) => {
  if (!array) return [];
  var rest = [];
  const found = array.filter((item) => {
    var isItem = item[comparisonFieldName] === value;
    if (!isItem) rest.push(item);
    return isItem;
  })[0];

  return [found, rest];
};


export const getFileType = (file) => {
  if(typeof file === "object") return "jpeg";
  const _file = file?.split("?");
  return _file[0].substr(_file.length - 5);
};

export const convertNumberToShortForm = (number) => {
  var pow = Math.pow,
    floor = Math.floor,
    abs = Math.abs,
    log = Math.log;
  var abbrev = [" K", "M", " B", "T"];

  function round(n, precision) {
    var prec = Math.pow(10, precision);
    return Math.round(n * prec) / prec;
  }

  var base = floor(log(abs(number)) / log(1000));
  var suffix = abbrev[Math.min(2, base - 1)];
  base = abbrev.indexOf(suffix) + 1;
  return suffix ? round(number / pow(1000, base), 2) + suffix : "" + number;
};

export const getMeaningfulDate = (date) => {
  const today = new Date();
  let newDate = date ? new Date(date) : today;
  let options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return Intl.DateTimeFormat("en-US", options).format(newDate);
};

export const validateEmail = (email) => {
  if (!email) return false;
  var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };


export const encodeUserDataForCreateEnterprise = (data) => {
      let toEncode = JSON.stringify(data);
      const encodedData = btoa(toEncode);
      const redirectUrl = `/success/?entry=${encodedData}`;
      window.location.href = redirectUrl;
}

export const slugifyMe = (text) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}


export const parseMe = (stringified)=>{
  if(typeof stringified === "string") return JSON.parse(stringified);
  return stringified 
}

export const convertAmountWithCurrencyRate = (data) => {
  let { code, amount, rates } = data;
  const rate = rates[code];
  let currencyObj = CURRENCIES.find((item) => item.code === code);
  amount = parseFloat(amount * rate);
  return { amount: amount, ...currencyObj };
};



  export const getMyCountryDetails = (type,name) => {
    if (!name) {
      name = "Ghana";
    }
    let arr = name?.split("-");
    if (arr?.length <= 1) {
      arr = ["Ghana"];
    }

    let country = ALL_COUNTRIES_AND_CURRENCIES?.find((country) => country.name === arr[0]);
    return country;
  };

  // Gh
export const getLocalEnterprise = () => {
  const localEnt = localStorage.getItem("currentEnterprise")
    ? JSON.parse(localStorage.getItem("currentEnterprise"))
    : {};
    return localEnt;
}

export const removeCountriesWithEmptyCurency = () => {
  const countries = ALL_COUNTRIES_AND_CURRENCIES.filter(
    (country) => country.currency.symbol
  );
  return countries;
};

export const replaceCountriesWithEmptyCurency = ()=>{
  const countries = ALL_COUNTRIES_AND_CURRENCIES.map(item=>{
    if(item?.currency?.name === false){
      return {...item,currency:{code:"", name:"", symbol:""}}
    }
    if (item?.currency?.symbol === false){
      return {...item,currency:{...item.currency,symbol:""}}
    }
    if (item?.currency?.code === false){
      return {...item,currency:{...item.currency,code:""}}
    }

    return item;
  })
  return countries;
}

export const validatePhoneNumber = (phoneNumber) => {
  // eslint-disable-next-line
  const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
  return regex.test(phoneNumber);
};



export const capitalizeMe = (text)=>{
  if (!text) return "";
  return text.charAt(0).toUpperCase() + text.slice(1);
}


export const isEmpty = (value) => {
  if (
    value === undefined ||
    value === null ||
    value === "" ||
    value === [] ||
    value === "null" ||
    value === "undefined" ||
    Object?.keys(value)?.length === 0 ||
    value === {}
  )
    return true;
  return false;
}

// export const hasEmptyFields = (obj)=>{
//   for (let key in obj) {
//     if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
//       return true;
//     }
//   }
//   return false;
// }

export const hasEmptyFields = (obj) => {
  return Object.values(obj).every((value) => {
    if (value === null || value === undefined || value === "") {
      return true;
    }

    return false;
  });
};

export const sortKeysAlphabetically = (obj) => {
  return Object.keys(obj)
    .sort()
    .reduce((result, key) => {
      result[key] = obj[key];
      return result;
    }, {});
}