import {
  faFileAlt,
  faFilePdf,
  faImage,
  faPen,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "../../../shared/imgs/klogo.png";
import Spinner from "../../../tools/components/v1/widgets/Spinner/Spinner";
import "./AssetDetails.css";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import URLS from "../../../shared/js/urls";
import {
  editMutationAction,
  getAssetsAction,
  toggleModalAction,
  toggleToastAction,
} from "../../../store/actions/actions";
import { bindActionCreators } from "redux";
import Title from "../../../components/title/Title";
import NoItems from "../../../tools/components/v1/widgets/NoItems/NoItems";
import Table from "../../../tools/components/v1/widgets/Table/Table";
import AssetDocumentCreation from "./AssetDocumentCreation";

class AssetDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      docs: [],
    };
  }

  handleOpenDocOrImage = (doc) => {
    window.open(doc, "_blank");
  };

  getFileTyle = (file) => {
    const _file = file.split("?");
    return _file[0].substr(_file.length - 5);
  };
  showType = (file) => {
    const _file = file.split("?");
    const fileType = _file[0].substr(_file.length - 5);
    const IMAGE_TYPES = ["jpeg", "jpg", "png"];

    if (IMAGE_TYPES.includes(fileType))
      return <FontAwesomeIcon icon={faImage} fontSize="3rem" color="maroon" />;
    else if (fileType === "pdf")
      return (
        <FontAwesomeIcon icon={faFilePdf} fontSize="3rem" color="maroon" />
      );

    return <FontAwesomeIcon icon={faFileAlt} fontSize="3rem" color="maroon" />;
  };

  prepareTableData = (doc) => {
    let tableData = doc.map((item) => {
      return [
        <p>{this.showType(item.file)}</p>,
        <p
          onClick={() => this.handleOpenDocOrImage(item?.file)}
          id="doc-name-txt"
        >
          {item?.name}
        </p>,
        <p id="doc-type-container">
          {this.getFileTyle(item.file).toUpperCase()}
        </p>,
        <div id="actions">
          <div
            className="elevate-float action-item-container accept"
            onClick={() => this.handleAddDocument(item)}
          >
            <FontAwesomeIcon icon={faPen} />
          </div>
          <div
            className="elevate-float action-item-container reject"
            onClick={() => this.handleDelete(item, doc)}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </div>
        </div>,
      ];
    });
    return tableData;
  };

  handleSelectImage = () => {
    let fileInput = document.getElementById("asset-img-select");
    fileInput.click();
    const { assets, currentAsset } = this.props;
    fileInput.onchange = async (e) => {
      this.setState({ uploading: true });
      let res = await ApiCallHandler.send(URLS.UPDATE_ASSETS_IMAGE, "POST", {
        image: e.target.files[0],
        asset: currentAsset?.id,
      });
      if (res && res.success) {
        this.props.editRecord(assets, res?.data);
        this.setState({ uploading: false });
      } else {
        this.setState({ uploading: false });
        this.props.toggleToast({
          showToast: true,
          message: "An error occured. Try again",
          type: "failure",
        });
      }
    };
  };

  close = () => {
    this.props.toggleModal({
      show: false,
    });
  };

  handleDelete = (item, docs) => {
    if (docs?.length < 1) return;
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.name}"`,
        children: `Are you sure you want to delete "${item?.name}"`,
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => {
            ApiCallHandler.send(URLS.ARCHIVE_ASSETS_DOCUMENTS, "POST", {
              id: item?.id,
            }).then(() => {
              this.props.getAssets()
            });
          },
        },
      },
    });
  };
  handleAddDocument = (item = null) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `${item?.id ? "Update" : "Add New "} document`,
        children: <AssetDocumentCreation close={this.close} doc={item} />,
        themeColor: "var(--app-theme)",
        cancel: false,
        size: "large",
      },
    });
  };
  

  render() {
    const { currentAsset: asset } = this.props;
    return (
      <div id="asset-detail-wrapper" key={asset?.documnets?.length}>
        <div id="img-and-title-container">
          <div style={{ position: "relative" }}>
            <img src={asset?.image || logo} alt="logo" id="asset-img" />
            {this.state.uploading ? (
              <div className="loading-overlay-container">
                <Spinner className="my-spinner" />
              </div>
            ) : null}
            <input
              type="file"
              style={{ display: "none" }}
              id="asset-img-select"
            />
            <div id="change-asset-image" onClick={this.handleSelectImage}>
              <FontAwesomeIcon icon={faPen} color="white" />
            </div>
          </div>

          <div id="asset-info-txts-container">
            <div>
              <h2>{asset?.name}</h2>
              <span className="asset-type">{asset?.asset_type?.name}</span>
            </div>
          </div>
        </div>

        <div id="asset-detail-container">
          <Title>About Asset</Title>
          <p>{asset?.description || "Something about your asset.."}</p>
        </div>

        <div id="asset-docs-container">
          <Title style={{ marginBottom: "2rem" }}>DOCUMENTS</Title>

          <div style={{ marginBottom: "1.5rem" }}>
            <Title
              className="text-toggler"
              onClick={() => this.handleAddDocument()}
            >
              <FontAwesomeIcon icon={faPlus} /> Add Document
            </Title>
          </div>
          {asset?.documents?.length > 0 ? (
            <Table
              columns={["", "Name", "Type", "Actions"]}
              data={this.prepareTableData(asset?.documents)}
              hoverAnimation={false}
              rootClass="asset-docs-table"
            />
          ) : (
            <NoItems text="This asset has no documents yet!" />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    assets: state.assets,
    currentAsset:
      state.assets?.filter((x) => x.id === localStorage.assetID)[0] || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      editRecord: (current_data, new_data) =>
        dispatch(editMutationAction("ASSETS_MUTATION", current_data, new_data)),
    toggleModal:toggleModalAction,
    getAssets:getAssetsAction
  },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(AssetDetail);
