import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  toggleModalAction,
  toggleToastAction,
  editMutationAction,
} from "store/actions/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Title from "components/title/Title";
import Table from "tools/components/v1/widgets/Table/Table";
import NoItems from "tools/components/v1/widgets/NoItems/NoItems";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import ShowActions from '../../../../shared/components/showActions/ShowActions';
import { getLocalEnterprise, getMeaningfulDate, getMyCountryDetails } from '../../../../shared/js/utils';
import CreateOpportunityExpense from "./CreateOpportunityExpense";
import { withRouter } from 'react-router-dom';

class ListSingleOpportunityExpenses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToast: false,
      newlyCreated: {},
      assets: [],
      expenseTypes: [],
      msg: "",
      toast_type: "",
      routeName: "",
      currentData: {
        asset: "",
        type: "",
        id: "",
        period_start: "",
        period_end: "",
        amount: "",
      },
      showActions: false,
      id: "",
    };
  }

  showOppExpenseDeleteModal = (item) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.expense_type?.name}"`,
        children: (
          <div style={{ padding: "10px 20px" }}>
            Are you sure you want to delete{" "}
            <strong>{item?.expense_type?.name}</strong>
          </div>
        ),
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => this.handleDeleteOpportunityExpense(item),
        },
      },
    });
  };
  toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      this.setState({
        showActions: false,
        id: "",
      });
    } else {
      this.setState({
        showActions: true,
        id: item?.id,
      });
    }
  };
  prepareTableData = () => {
    let { allSingleOpportunityExpenses } = this.props;
    let tableData = [];
    if (
      allSingleOpportunityExpenses &&
      allSingleOpportunityExpenses.length > 0
    ) {
      allSingleOpportunityExpenses.forEach((item) => {
        tableData.push([
          <p onClick={() => this.toggleShowAllExpenseInfo(item)}>
            {item?.expense_type?.name}
          </p>,
          <p onClick={() => this.toggleShowAllExpenseInfo(item)}>
            {parseFloat(item?.amount)?.toFixed(2)}
          </p>,
          <p onClick={() => this.toggleShowAllExpenseInfo(item)}>
            {getMeaningfulDate(item?.transaction_date?.split("T")[0])}{" "}
          </p>,
          <p onClick={() => window.open(item?.receipt, "_blank")}>
            {item?.receipt ? "View" : "No Receipt"}
          </p>,
          <p onClick={() => this.toggleShowAllExpenseInfo(item)}>
            {item?.reference_id
              ? item?.reference_id?.substring(0, 20) + " ...."
              : "No Ref. Id"}{" "}
          </p>,

          <ShowActions
            onEdit={this.handleAddOrEditOpportunityExpense}
            onDelete={this.showOppExpenseDeleteModal}
            item={item}
            id={this.state.id}
            toggleShowMoreOptions={this.toggleShowMoreOptions}
            show={this.state.showActions}
          />,
        ]);
      });
    }
    return tableData;
  };

  toggleShowAllExpenseInfo = (expense) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Expense Details`,
        children: this.renderExpenseFullDetails(expense),
        themeColor: "var(--app-theme)",
        size: "medium",
      },
    });
  };

  renderExpenseFullDetails = (expense) => {
    let { currentEnterprise } = this.props;
    let { currency } = getMyCountryDetails(
      "currency",
      currentEnterprise?.currency
    );
    return (
      <div className="detail-main-container">
        <div>
          <p>Type</p>
          <span>{expense?.expense_type?.name}</span>
        </div>
        <div>
          <p>Amount</p>
          <span>
            {currency?.symbol} {parseFloat(expense?.amount)?.toFixed(2)}
          </span>
        </div>
        <div>
          <p>Receipt</p>
          {expense?.receipt ? (
            <span onClick={() => window.open(expense?.receipt, "_blank")}>
              View Receipt
            </span>
          ) : (
            <span>N/A</span>
          )}
        </div>
        <div>
          <p>Transaction Date</p>
          <span>
            {getMeaningfulDate(expense?.transaction_date?.split("T")[0])}
          </span>
        </div>
        <div>
          <p>Reference ID</p>
          <span>{expense?.reference_id}</span>
        </div>
        <div>
          <p>Notes</p>
          {expense?.description ? (
            <p
              style={{
                fontWeight: "normal",
                fontSize: "1rem",
                textAlign: "justify",
                color: "black",
              }}
            >
              {expense?.description}
            </p>
          ) : (
            <span>N/A</span>
          )}
        </div>
      </div>
    );
  };

  showOpportunityExpenses = () => {
    let { allSingleOpportunityExpenses, currentEnterprise } = this.props;
      let { currency } = getMyCountryDetails(
        "currency",
        currentEnterprise?.currency
      );
    return (
      <div>
        <Title
          className="text-toggler"
          onClick={() => this.handleAddOrEditOpportunityExpense()}
        >
          Add Expenses <FontAwesomeIcon icon={faPlus} />
        </Title>
        <div style={{ borderRadius: 10 }}>
          <Title>Opportunity Expenses</Title>

          {allSingleOpportunityExpenses &&
          allSingleOpportunityExpenses.length ? (
            <Table
              columns={[
                "Expense Type",
                `Amount (${currency?.symbol})`,
                "Transaction Date",
                "Receipt",
                "Ref. ID",
                "Actions",
              ]}
              data={this.prepareTableData()}
              hoverAnimation={false}
            />
          ) : (
            <NoItems text="To be able to record an expense, please first create an expense type." />
          )}
        </div>
      </div>
    );
  };

  close = () => {
    this.props.toggleModal({ show: false });
  };

  handleAddOrEditOpportunityExpense = (item = {}) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `${item?.id ? "Update" : "Create"} Expense`,
        children: (
          <CreateOpportunityExpense
            editData={item}
            close={this.close}
            currentInvestment={this.props.currentInvestment?.id}
          />
        ),
        themeColor: "var(--app-theme)",
        size: "medium",
      },
    });
  };

  DeleteItemfromRedux = (id) => {
    let { currentInvestment, investments } = this.props;
    let filteredExpense = currentInvestment?.expenses?.filter(
      (item) => item.id !== id
    );
    currentInvestment.expenses = filteredExpense;
    this.props.updateItemInredux(investments, currentInvestment);
  };

  handleDeleteOpportunityExpense = async (item) => {
    let res = await ApiCallHandler.send(
      URLS.ARCHIVE_OPPORTUNITY_EXPENSE,
      "POST",
      {
        id: item?.id,
      }
    );
    if (res && res.success) {
      this.DeleteItemfromRedux(res?.data?.id);
      this.props.toggleToast({
        show: true,
        message: "Expense deleted successfully",
        type: "success",
      });
    } else {
      this.props.toggleToast({
        showToast: true,
        message: "An error occurred. Try again",
        type: "failure",
      });
    }
  };

  render() {
    return <div>{this.showOpportunityExpenses()}</div>;
  }
}

const mapStateToProps = (state,ownerProps) => {
  const currentInvestment = state.investments.filter(
    (x) => x.id ===ownerProps?.match?.params?.id
  )[0];
    const currentEnterprise = state.enterprises?.find(
      (item) => item.id === getLocalEnterprise()?.id
    );
  return {
    allSingleOpportunityExpenses: currentInvestment?.expenses,
    expenseTypes: currentInvestment?.expense_types,
    currentInvestment,
    investments: state.investments,
    currentEnterprise,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      
      toggleModal: toggleModalAction,
      updateItemInredux: (current_data, new_data) =>
        dispatch(editMutationAction("INVESTMENT", current_data, new_data)),
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListSingleOpportunityExpenses)
);
