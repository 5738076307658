import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  toggleSidePane,
  setAssetCreateOrEditTypeAction,
  setEditSetterAction,
  toggleModalAction,
  editMutationAction,
  toggleToastAction,
} from "store/actions/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Title from "components/title/Title";
import Table from "tools/components/v1/widgets/Table/Table";
import NoItems from "tools/components/v1/widgets/NoItems/NoItems";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import FormGenerator from "tools/components/v1/FormGenerator/FormGenerator";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router";
import { getAllInvestmentsAction } from '../../../../store/actions/actions';
import { convertNumberToShortForm, getLocalEnterprise } from 'shared/js/utils';
import { getMyCountryDetails, isEmpty } from '../../../../shared/js/utils';

class InvestmentsPayout extends Component {
  constructor(props) {
    super(props);
    this.onIncomeSubmit = this.onIncomeSubmit.bind(this);
  }

  getConfigSummary() {
    let { currentData } = this.props;

    const expenseArray = currentData?.expense_config;
    const incomeArray = currentData?.income_config;

    if (
      expenseArray == null ||
      incomeArray == null ||
      expenseArray.length < 1 ||
      incomeArray.length < 1
    ) {
      return 0;
    }

    const frequencyValues = {
      DAYS: 1 / 7,
      WEEKS: 1,
      MONTHS: 4,
      YEARS: 52.6,
    };
    let expensesSum = 0;
    expenseArray.forEach((val) => {
      const amountInWeeks =
        val.amount / (frequencyValues[val.frequency_type] * val.frequency);
      expensesSum += amountInWeeks;
    });
    expensesSum = Math.round(expensesSum);

    let incomeSum = 0;
    incomeArray.forEach((val) => {
      const amountInWeeks =
        val.amount / (frequencyValues[val.frequency_type] * val.frequency);
      incomeSum += amountInWeeks;
    });

    if (expensesSum === 0 || incomeSum === 0) {
      return 0;
    }

    return expensesSum * currentData?.income?.length;
  }

  currentBalance = () => {
    let { income, payouts } = this.props.currentData;
    let sum = 0;
    let pay_ = 0;

    if (income && income.length > 0) {
      income.forEach((inc) => {
        sum += parseFloat(inc.amount);
      });
    }

    if (payouts && payouts.length > 0) {
      payouts.forEach((pay) => {
        pay_ += parseFloat(pay.amount);
      });
    }

    let expensesToDeduct = this.getConfigSummary();

    return sum - (pay_ + expensesToDeduct);
  };

  async createIncomeConfigRecord(data) {
    const json = await ApiCallHandler.send(
      URLS.CREATE_PAYOUT,
      ApiCallHandler.POST,
      data
    );
    return json;
  }

  async handleCreateResponse(json, reset) {
    if (json?.success) {
      await this.props.editRecord(this.props.investments, json.data);
      this.props.setEditPayload({});
      this.props.toggleToast({
        show: true,
        message: "Created Successfully",
        type: "success",
      });
      this.props.toggleModal({ show: false });
      return reset();
    }

    if (json?.success === false) {
      return this.props.toggleToast({
        show: true,
        message: json?.error,
        type: "failure",
      });
    }

    this.props.toggleToast({
      show: true,
      message: "Sorry something happened, couldnt create!",
      type: "failure",
    });
  }

  async onIncomeSubmit(data, reset) {
    if (!data) return;
    const currentBalance = this.currentBalance();
    data = {
      sender: this.props?.currentData?.enterprise.id,
      opportunity: this.props?.currentInvestmentId,
      amount: parseInt(data.amount),
      confirmed: "False",
      ...data,
    };
    if (data.amount > currentBalance) {
      alert(`You Cant Payout More Than ${currentBalance}`);
      reset();
      this.props.toggleModal({ show: false });
    } else {
      this.props.toggleModal({ show: false });
      let json = {};
      json = await this.createIncomeConfigRecord(data);
      this.handleCreateResponse(json, reset);
    }
  }

  showCreateForm = () => {
    let fieldsArray = [
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "Amount",
        name: "amount",
        placeholder: "Amount Here...",
        value: this.currentBalance(),
        required: true,
      },
      {
        fieldType: FormGenerator.Fields.DATE,
        label: "Next Payout Date",
        name: "next_payout_date",
        required: true,
      },
      {
        fieldType: FormGenerator.Fields.TEXTAREA,
        label: "Notes",
        name: "notes",
        placeholder: "Short Notes Here...",
      },
    ];
    let { currentEnterprise } = this.props;
     let { currency } = getMyCountryDetails(
       "currency",
       currentEnterprise?.currency
     );

    return (
      <div>
        <div id="max-payout-container">
          <p>Max Payout </p>
          <span style={{ textAlign: "center" }}>
            {currency?.code} {convertNumberToShortForm(this.currentBalance())}
          </span>
        </div>
        <FormGenerator
          onSubmit={this.onIncomeSubmit}
          elevation={0}
          subtitle=""
          title=""
          formSubmitBtnText="Create"
          fields={fieldsArray}
        />
        <br />
        <br />
      </div>
    );
  };

  createBulkSettlements = (payout) => {
    const { partnerships, id, shares } = this.props.currentData;
    if (isEmpty(partnerships)) {
       return this.props.toggleToast({
         show: true,
         message: "Please You can't trigger a settlement without any partners",
         type: "failure",
       });

    }
      
    const dpps = parseFloat(payout.amount) / parseFloat(shares);
    partnerships?.forEach(async (partner) => {
      const full_amount = dpps * parseFloat(partner.shares);
      const transaction_charges = 0.1 * full_amount;
      const amount_to_receive = full_amount - transaction_charges;
      const prepareData = {
        payout: payout.id,
        opportunity: id,
        partner: partner.id,
        user_recipient: partner.user.id,
        payment_status: "CREATED",
        full_amount: full_amount,
        transaction_charges: transaction_charges,
        amount_to_receive: amount_to_receive,
      };
      try {
        const json = await ApiCallHandler.send(
          URLS.CREATE_NEW_OPPORTUNITY_SETTLEMENT,
          ApiCallHandler.POST,
          prepareData
        );

        if (json.success) {
          this.props.getInvestments();
        }
        await this.props.editRecord(this.props.investments, json.data);
      } catch (error) {}
    });

  
  };

  viewAllSetllementsUnderASinglePayout = (item) => {
    localStorage.selectedOpprtunity = this.props.currentData.id;
    this.props.history.push(`/opportunity-payout-settlements/${item.id}`);
  };

  renderSettlements = (item) => {
    if (item.settlements && item.settlements.length > 0) {
      return (
        <div
          id="view-settlement-btn-container"
          className="elevate-float"
          onClick={() => this.viewAllSetllementsUnderASinglePayout(item)}
        >
          <p>View Settlements</p>
        </div>
      );
    } else {
      return (
        <div
          id="view-settlement-btn-container"
          className="elevate-float"
          onClick={() => this.createBulkSettlements(item)}
        >
          <p>Trigger Settlements</p>
        </div>
      );
    }
  };

  prepareTableData = (currentData) => {
     let { currentEnterprise } = this.props;
     let { currency } = getMyCountryDetails(
       "currency",
       currentEnterprise?.currency
     );
    let tableData = [];
    currentData.forEach((item) => {
      tableData.push([
        <p>{item?.next_payout_date}</p>,
        <p>{currency?.code} {item?.amount}</p>,
        <p>{item?.next_payout_date}</p>,
        <div className="actions-container">{this.renderSettlements(item)}</div>,
      ]);
    });
    return tableData;
  };

  showAllIncomeConfig = () => {
    let { currentData } = this.props;
    currentData = currentData?.payouts;
    return (
      <div>
        <div style={{ borderRadius: 10 }}>
          <Title>Opportunity Payouts</Title>
          <br />
          {currentData && currentData.length ? (
            <Table
              columns={[
                "Last Payout Date",
                "Amount",
                "Next Payout Date",
                "Settlement Actions",
              ]}
              data={this.prepareTableData(currentData)}
              hoverAnimation={false}
            />
          ) : (
            <NoItems text="You have no Payouts yet..." />
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <br />
        <Title
          className="text-toggler"
          onClick={() =>
            this.props.toggleModal({
              show: true,
              props: {
                title: `CREATE PAYOUT`,
                children: this.showCreateForm(),
                themeColor: "var(--app-theme)",
              },
            })
          }
        >
          Add New Payout <FontAwesomeIcon icon={faPlus} />
        </Title>
        <br />
        {this.showAllIncomeConfig()}
        <br />
        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state, ownerProps) => {
  const currentInvestmentId =ownerProps?.match?.params?.id;
    let currentEnterprise = state.enterprises?.find(
      (item) => item?.id === getLocalEnterprise()?.id
    );
  return {
    investments: state.investments,
    currentInvestmentId: currentInvestmentId,
    currentEnterprise,
    currentData: state.investments?.filter(
      (x) => x.id === currentInvestmentId
    )[0],
    editStatus:
      state.editStatusPayload && state.editStatusPayload.investmentEditStatus
        ? state.editStatusPayload.investmentEditStatus
        : false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSidePane,
      setAssetFormStatus: (val) =>
        dispatch(setAssetCreateOrEditTypeAction(val)),
      setEditPayload: (val) => dispatch(setEditSetterAction(val)),
      toggleModal: toggleModalAction,
      editRecord: (current_data, new_data) =>
        dispatch(editMutationAction("INVESTMENT", current_data, new_data)),
      toggleToast: toggleToastAction,
      getInvestments:getAllInvestmentsAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvestmentsPayout)
);
