import React, { Component } from "react";
import Table from "tools/components/v1/widgets/Table/Table";
import Title from "components/title/Title";
import { connect } from "react-redux";
import NoItems from '../../../tools/components/v1/widgets/NoItems/NoItems';

class EnterpriseUsersCreateAndList extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  prepareTableData = () => {
    let { invitations } = this.props;
    invitations = invitations?.length > 0 ? invitations.filter(e => e.has_responded === true) : [];
    let tableData = [];
    if (invitations?.length > 0) {
      // TODO: Tweak table component to give access to manipulation of an entire row, instead of cells
      invitations.forEach((item) => {
        tableData.push([
          <p>{item.name}</p>,
          <p>{item.email}</p>,
          <p>{item.role}</p>,
        ]);
      });
    }
    return tableData;
  };

  renderEnterpeiseUsers = () => {
    let { invitations } = this.props;
    invitations = invitations?.length > 0 ? invitations.filter(e => e.has_responded === true) : [];
    return (
      <div>
        <div style={{ borderRadius: 10 }}>
          <Title>All Users</Title>

          {invitations && invitations.length ? (
            <Table
              columns={["Name", "Email", "Role"]}
              data={this.prepareTableData()}
              hoverAnimation={false}
            />
          ) : (
            <div id="no-items-container">
              <NoItems text="You have no invited users." />
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div id="create-asset-type-container">
        {this.renderEnterpeiseUsers()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invitations: state.enterpriseUserInvitations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterpriseUsersCreateAndList);
