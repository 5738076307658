import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from "../../../../../tools/components/v1/widgets/Spinner/Spinner";
import "./AssetDetails.css";
import ApiCallHandler from "../../../../../shared/js/ApiCallHandler";
import URLS from "../../../../../shared/js/urls";
import {
  toggleToastAction,
  toggleModalAction,
  editMutationAction
} from "../../../../../store/actions/actions";
import { bindActionCreators } from "redux";
import NoItems from "../../../../../tools/components/v1/widgets/NoItems/NoItems";
import Table from "../../../../../tools/components/v1/widgets/Table/Table";
import { getFileType, getMeaningfulDate, parseMe } from "../../../../../shared/js/utils";
import Title from "../../../../../components/title/Title";
import AssetDocumentCreation from "./AssetDocumentCreation";
import ShowActions from "../../../../../shared/components/showActions/ShowActions";
import CreateAndEditAssetManager from '../asset managers/create and edit/CreateAndEditAssetManager';
import './AssetDetails.css'
class OpportunityAssetDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      showActions: false,
      id: "",
      uploadingImg:false
    };
  }

  handleOpenDocOrImage = ({ document: doc }) => {
    window.open(doc, "_blank");
  };

  toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      this.setState({
        showActions: false,
        id: "",
      });
    } else {
      this.setState({
        showActions: true,
        id: item?.id,
      });
    }
  };

  prepareTableData = (doc) => {
    let tableData = (doc || []).map((item) => {
      return [
        <p>{getMeaningfulDate(item?.date)}</p>,
        <p onClick={() => this.handleOpenDocOrImage(item)} id="doc-name-txt">
          {item?.name}
        </p>,
        <p id="doc-type-container">
          {getFileType(item?.document).toUpperCase()}
        </p>,
        <p>{item?.visibility ? "Public" : "Private"}</p>,
        <div>
          <ShowActions
            onEdit={this.handleAddDocument}
            onDelete={this.handleToggleDeleteModal}
            onView={this.handleOpenDocOrImage}
            item={item}
            id={this.state.id}
            toggleShowMoreOptions={this.toggleShowMoreOptions}
            show={this.state.showActions}
          />
        </div>,
      ];
    });
    return tableData;
  };

  toggleShowManagerInfo = (manager) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `${manager?.user?.first_name + " " + manager?.user?.last_name}`,
        children: this.renderManagerFullDetails(manager),
        themeColor: "var(--app-theme)",
        size: "medium",
      },
    });
  };
  onManagerImageClick = (manager) => {
    let fileInput = document.getElementById("manager-image");
    fileInput.click();
    let {currentAsset, currentInvestment, investments} = this.props;

    fileInput.onchange = async (e) => {
      this.setState({ uploadingImg: true });

      let res = await ApiCallHandler.send(
        URLS.UPDATE_USER_IMGAE,
        "POST",
        {
          image: e.target.files[0],
          user: manager?.user?.id,
        }
      );
      if (res?.success) {
        manager.user = res.data;
        let managers = currentAsset?.managers?.filter(item=>item.id!==manager.id);
        managers.push(manager);
        currentAsset.managers = managers;
        let assets = currentInvestment?.assets?.filter(item=>item.id!==currentAsset.id);
        assets.push(currentAsset);
        currentInvestment.assets = assets;
        this.props.updateItemInredux(investments, currentInvestment)
        this.setState({ uploadingImg: false });
    };
  };
}

  renderManagerFullDetails = (manager) => {
    return (
      <div className="manager-detail-container">
        <div style={{ display: "flex" }}>
          <input
            type="file"
            accept="image/*"
            id="manager-image"
            style={{ display: "none" }}
          />
          <div className="asset-manager-img-container">
            <img src={manager?.user?.image} alt=" " className="asset-manager-img" />

            <div
              className="middle"
              onClick={() => this.onManagerImageClick(manager)}
            >
              <p>Change</p>
            </div>
          </div>
          <div>
            <h2 style={{ marginBottom: 10 }}>
              {manager?.user?.first_name + " " + manager?.user?.last_name}
            </h2>
            <p>{manager?.user?.email}</p>
            <p>{manager?.user?.phone_number}</p>
            <p>{parseMe(manager?.user?.info)?.status}</p>
          </div>
        </div>

        <div className="manager-more-info">
          <div
            style={{ textAlign: "justify" }}
            dangerouslySetInnerHTML={{
              __html: parseMe(manager?.user?.info)?.more_info,
            }}
          ></div>
        </div>
      </div>
    );
  };

  updateAssetInRedux = (asset) => {
    let { currentInvestment, investments } = this.props;
    let fielteredAssets = currentInvestment?.assets?.filter(
      (item) => item?.id !== asset?.id
    );
    fielteredAssets.push(asset);
    currentInvestment.assets = fielteredAssets;
    this.props.updateItemInredux(investments, currentInvestment);
  };

  handleSelectImage = () => {
    let fileInput = document.getElementById("asset-img-select");
    fileInput.click();

    const { currentAsset } = this.props;
    fileInput.onchange = async (e) => {
      this.setState({ uploading: true });

      let res = await ApiCallHandler.send(
        URLS.UPDATE_OPPORTUNITY_ASSET_IMAGE,
        "POST",
        {
          image: e.target.files[0],
          id: currentAsset?.id,
        }
      );

      if (res && res.success) {
        this.updateAssetInRedux(res.data);
        this.setState({ uploading: false });
      } else {
        this.setState({ uploading: false });
        this.props.toggleToast({
          showToast: true,
          message: "An error occured. Try again",
          type: "failure",
        });
      }
    };
  };

  close = () => {
    this.props.toggleModal({
      show: false,
    });
  };

  deleteItemFromRedux = (id) => {
    let { investments, currentInvestment, currentAsset } = this.props;
    let updatedDocs = currentAsset?.documents?.filter(
      (item) => item?.id !== id
    );
    currentAsset.documents = updatedDocs;
    let updatedAssets = currentInvestment?.assets?.filter(
      (item) => item?.id !== currentAsset?.id
    );
    updatedAssets.push(currentAsset);
    currentInvestment.assets = updatedAssets;
    this.props.updateItemInredux(investments, currentInvestment);
  };
  handleDeleteDocument = async (item) => {
    let res = await ApiCallHandler.send(
      URLS.ARCHIVE_OPPORTUNITY_ASSET_DOCUMENT,
      "POST",
      {
        id: item?.id,
      }
    );

    if (res && res.success) {
      this.deleteItemFromRedux(item?.id);
      this.props.toggleToast({
        show: true,
        message: "Opportunity Asset Document deleted successfully",
        type: "success",
      });
    } else {
      this.props.toggleToast({
        showToast: true,
        message: "An error occurred. Try again",
        type: "failure",
      });
    }
  };

  handleToggleDeleteModal = (item) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.name}"`,
        children: (
          <div style={{ padding: "10px 20px" }}>
            Are you sure you want to delete <strong>{item?.name}</strong>
          </div>
        ),
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => {
            this.handleDeleteDocument(item);
          },
        },
      },
    });
  };
  handleToggleDeleteManager = (item) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.user?.first_name}"`,
        children: (
          <div style={{ padding: "10px 20px" }}>
            Are you sure you want to delete{" "}
            <strong>{item?.user?.first_name}</strong>
          </div>
        ),
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => {
            this.handleDeleteManager(item);
          },
        },
      },
    });
  };

  updateRedux = (item) => {
    const { currentAsset: asset, currentInvestment, investments } = this.props;
    let newManagers = asset?.managers?.filter(
      (manager) => manager?.id !== item?.id
    );
    asset.managers = newManagers;
    let newAssets = currentInvestment?.assets?.filter(
      (item) => item?.id !== asset?.id
    );
    newAssets.push(asset);
    currentInvestment.assets = newAssets;

    this.props.updateItemInredux(investments, currentInvestment);
  };
  handleDeleteManager = async (item) => {
    let res = await ApiCallHandler.send(
      URLS.ARCHIVE_OPPORTUNITY_ASSET_MANAGER,
      "POST",
      {
        id: item?.id,
      }
    );

    if (res && res.success) {
      this.updateRedux(item);
      this.props.toggleToast({
        show: true,
        message: "Opportunity Asset Manager deleted successfully",
        type: "success",
      });
    } else {
      this.props.toggleToast({
        show: true,
        message: "An error occurred. Try again",
        type: "failure",
      });
    }
  };

  handleAddDocument = (item = null) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `${item?.id ? "Update" : "Add New "} document`,
        children: (
          <AssetDocumentCreation
            close={this.close}
            doc={item}
            opportunity_asset={this.props.match?.params?.id}
          />
        ),
        themeColor: "var(--app-theme)",
        cancel: false,
        size: "large",
      },
    });
  };

  closeModal = () => {
    this.props.toggleModal({
      show: false,
    });
  };

  handleShowCreateAndEditManagerForm = (item = {}) => {
    let { currentAsset } = this.props;
    this.props.toggleModal({
      show: true,
      props: {
        title: `${item?.id ? "Update Manager Info" : "Add New Manager"}`,
        children: (
          <CreateAndEditAssetManager
            item={item}
            close={this.closeModal}
            asset={currentAsset}
          />
        ),
        themeColor: "var(--app-theme",
        size: "large",
      },
    });
  };

  prepareManagerData = (managers) => {
    let tableData = (managers || [])?.map((item) => {
      return [
        <p onClick={() => this.toggleShowManagerInfo(item)}>
          {item?.user?.first_name + " " + item?.user?.last_name}
        </p>,
        <p onClick={() => this.toggleShowManagerInfo(item)}>
          {item?.user?.email}
        </p>,
        <p onClick={() => this.toggleShowManagerInfo(item)}>
          {item?.user?.phone_number}
        </p>,
        <p>{parseMe(item?.user?.info)?.status}</p>,
        <p>{parseMe(item?.user?.info)?.sales_day}</p>,
        <div>
          <ShowActions
            onEdit={this.handleShowCreateAndEditManagerForm}
            onDelete={this.handleToggleDeleteManager}
            // onView={this.handleShowCreateAndEditManagerForm}
            item={item}
            id={this.state.id}
            toggleShowMoreOptions={this.toggleShowMoreOptions}
            show={this.state.showActions}
          />
        </div>,
      ];
    });
    return tableData;
  };

  render() {
    const { currentAsset: asset } = this.props;
    return (
      <div id="asset-detail-wrapper">
        <div id="img-and-title-container">
          <div style={{ position: "relative" }}>
            <img src={asset?.image} alt="logo" id="asset-img" />
            {this.state.uploading ? (
              <div className="loading-overlay-container">
                <Spinner className="my-spinner" />
              </div>
            ) : null}
            <input
              type="file"
              style={{ display: "none" }}
              id="asset-img-select"
            />
            <div id="change-asset-image" onClick={this.handleSelectImage}>
              <FontAwesomeIcon icon={faPen} color="white" />
            </div>
          </div>

          <div id="asset-info-txts-container">
            <div>
              <h2>
                {asset?.name}
                {/* <span>({asset?.asset_type?.name})</span> */}
              </h2>
              <p className="asset-id-type">
                {asset?.identification_number_name}
              </p>
              <p className="asset-id-number">{asset?.identification_number}</p>
            </div>
          </div>
        </div>
        {/* ============== About section ================================ */}

        <div id="asset-detail-container">
          <Title>About Asset</Title>
          <p>{asset?.description || "Something about your asset.."}</p>
        </div>
        {/* ============== Manager section ================================ */}
        <div id="asset-detail-container">
          <Title>Asset Manager</Title>
          <div>
            <Title
              className="text-toggler"
              onClick={this.handleShowCreateAndEditManagerForm}
            >
              <FontAwesomeIcon icon={faPlus} /> Add Manager
            </Title>
          </div>

          <div style={{ marginTop: 10, marginBottom: "4rem" }}>
            {asset?.managers ? (
              <Table
                columns={[
                  "Name",
                  "Email",
                  "Phone",
                  "Status",
                  "Sales Day",
                  "actions",
                ]}
                data={this.prepareManagerData(asset?.managers)}
                hoverAnimation={false}
              />
            ) : (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "70hv",
                }}
              >
                <NoItems text="You have Not Added managers yet. Click 'Add Manager' to add. " />
              </div>
            )}
          </div>
        </div>

        {/* ============== Docs section ================================ */}
        <div id="asset-doc-container">
          <Title style={{ marginBottom: "2rem" }}>DOCUMENTS</Title>

          <div style={{ marginBottom: "1.5rem" }}>
            <Title
              className="text-toggler"
              onClick={() => this.handleAddDocument()}
            >
              <FontAwesomeIcon icon={faPlus} /> Add Document
            </Title>
          </div>
          {asset?.documents?.length > 0 ? (
            <div>
              <Table
                columns={["Created At", "Name", "Type", "Status", "\t"]}
                data={this.prepareTableData(asset?.documents)}
                hoverAnimation={false}
              />
            </div>
          ) : (
            <NoItems text="This asset has no documents yet!" />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const currentInvestment =
    state.investments?.filter(
      (item) => item.id === localStorage.currentInvestmentId
    )[0] || {};
  const assetID = window.location.pathname.split("/")[3];

  const currentAsset =
    currentInvestment?.assets?.filter((item) => item.id === assetID)[0] || "";
  return {
    currentAsset,
    currentInvestment,
    investments: state.investments
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      toggleModal: toggleModalAction,
      updateItemInredux: (current_data, new_data) =>
        dispatch(editMutationAction("INVESTMENT", current_data, new_data)),
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpportunityAssetDetail);
