import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import './InvestorDetail.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import Verified from '../../../shared/components/verified/Verified';
import { faFacebookSquare, faInstagramSquare, faLinkedin, faTwitterSquare } from "@fortawesome/free-brands-svg-icons";


class InvestorDetail extends Component {
  showProfileInfo = () => {
    let { partner } = this.props;
    return (
      <div id="profile-info-container">
        <div id="img-info-container">
          <div id="profile-img-container" style={{ position: "relative" }}>
            <img src={partner?.user?.image} id="profile-img" alt="  " />
          </div>
          <div id="info-container">
            <h3>
              {partner?.user?.first_name} {partner?.user?.last_name}
              {partner?.user?.is_verified ? (
                <Verified bg={"green"} height={"25px"} width={"25px"} />
              ) : null}
            </h3>
            <p>
              <FontAwesomeIcon icon={faMailBulk} /> {partner?.user?.email || ""}
            </p>
            {this.renderSocialMedia(partner?.user?.contacts)}
          </div>
        </div>
      </div>
    );
  };

  renderInterests = (items) => {
    if(!items) return;
    const interests = JSON.parse(items)
    return (interests || []).map((interest, index) => {
      return (
        <div
          key={index.toString()}
          style={{ marginBottom: 8 }}
          className="tag-container"
        >
          <div className="tag-item elevate-float" style={{ marginRight: 8 }}>
            {interest}
          </div>
        </div>
      );
    });
  }

openLink = (link)=>{
  window.open(link, "_blank");
}
  renderSocialMedia = (contacts) => {
    if (contacts !== null) {
      return (
        <>
          <div id="social-media-container">
            {contacts?.website && (
              <FontAwesomeIcon
                icon={faGlobe}
                onClick={() => this.openLink(contacts?.website)}
              />
            )}
            {contacts?.twiter && (
              <FontAwesomeIcon
                icon={faTwitterSquare}
                onClick={() => this.openLink(contacts?.twitter)}
              />
            )}
            {contacts?.facebook && (
              <FontAwesomeIcon
                icon={faFacebookSquare}
                onClick={() => this.openLink(contacts?.facebook)}
              />
            )}
            {contacts?.instagram && (
              <FontAwesomeIcon
                icon={faInstagramSquare}
                onClick={() => this.openLink(contacts?.instagram)}
              />
            )}
            {contacts?.linkedin && (
              <FontAwesomeIcon
                icon={faLinkedin}
                onClick={() => this.openLink(contacts?.linkedin)}
              />
            )}
          </div>
        </>
      );
    }
  }

  render() {
    let { partner } = this.props;
    return (
      <div style={{ width: "90%", margin: "auto", marginTop: "1rem" }}>
        {this.showProfileInfo()}

        <div style={{ marginTop: "2rem" }}>
          <div id="biography-conatiner">
            <h3>About</h3>

            <div
              dangerouslySetInnerHTML={{
                __html: partner?.user?.bio
                  ? partner?.user?.bio
                  : "No biography added",
              }}
            ></div>
          </div>
        </div>

        {partner?.user?.interests !== null ? (
          <div style={{ marginTop: "2rem" }}>
            <div id="biography-conatiner">
              <h3>Interests</h3>

              {this.renderInterests(partner?.user?.interests?.interests)}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
    const partner = state.enterprisePartners?.find(
      (item) => item?.id === localStorage.partner?.split("*")[0]
    );
  return {
    partner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestorDetail);
