import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setEditSetterAction,
  toggleModalAction,
  toggleToastAction,
  editMutationAction,
} from "store/actions/actions";
import Title from "components/title/Title";
import Table from "tools/components/v1/widgets/Table/Table";
import NoItems from "tools/components/v1/widgets/NoItems/NoItems";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import FormGenerator from "tools/components/v1/FormGenerator/FormGenerator";
import Fields from "tools/components/v1/FormGenerator/Fields";
import "./style.css";
import { convertNumberToShortForm, getMeaningfulDate } from "shared/js/utils";
import { getMyCountryDetails } from '../../../../../shared/js/utils';

class AllSettlementsForSinglePayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToast: false,
      msg: "",
      toast_type: "",
      routeName: "",
      currentData: {
        asset: "",
        type: "",
        id: "",
        period_start: "",
        period_end: "",
        amount: "",
      },
    };
  }




  renderEditSettlementForm = (item) => {

    const handleCreateeee = async (data) => {
      const toSend = {
        id: item.id,
        payment_status: data?.status?.id,
        opportunity: this.props.currentInvestment.id,
        user_sender: JSON.parse(localStorage.userDeta).id,
      };
      try {
        const res = await ApiCallHandler.send(
          URLS.UPDATE_NEW_OPPORTUNITY_SETTLEMENT,
          "POST",
          toSend
        );
        if (res && res.success) {
          this.props.toggleToast({
            show: true,
            message: "Success",
            type: "success",
          });
          await this.props.editRecord(this.props.investments, res.data);
          this.props.setEditPayload({});
          this.props.toggleModal({ show: false });
        } else {
          this.props.toggleToast({
            showToast: true,
            message: "An Error Occured Please try again later",
            type: "failure",
          });
          this.props.toggleModal({ show: false });
          this.props.setEditPayload({});
        }
      } catch (error) {
      }
    };

    return (
      <FormGenerator
        elevation={0}
        fields={[
          {
            data: [
              { name: "CREATED", id: "CREATED" },
              { name: "PROCESSING", id: "PROCESSING" },
              { name: "MONEY-IN-TRANSIT", id: "MONEY-IN-TRANSIT" },
              { name: "TRANSACTION-FAILED", id: "TRANSACTION-FAILED" },
              {
                name: "MONEY-RECEIVED-BY-PARTNER",
                id: "MONEY-RECEIVED-BY-PARTNER",
              },
              { name: "COMPLETE", id: "COMPLETE" },
            ],
            fieldType: Fields.DROPDOWN,
            label: "Set Status",
            name: "status",
            placeholder: "Select Status",
            required: true,
            value: { name: item?.payment_status, id: item?.payment_status },
            type: "full",
            labelExtractor: (item) => item.name,
            valueExtractor: (item) => item.id,
          },
        ]}
        onSubmit={handleCreateeee}
        formSubmitBtnText="UPDATE STATUS"
        subtitle=""
        title=""
      />
    );
  };


  prepareTableData = () => {
    let { currentPayout } = this.props;
    const allPayoutSettlements = currentPayout?.settlements || [];
    let tableData = [];
    if (allPayoutSettlements && allPayoutSettlements?.length > 0) {
      allPayoutSettlements.forEach((item) => {
        tableData.push([
          <p
            onClick={() => {
              localStorage.selectedPayout = currentPayout.id;
              this.props.history.push(
                `/opportunity-payout-settlement-detail/${item.id}`
              );
            }}
          >
            {item?.partner?.user?.first_name}
          </p>,
          <p>{item?.payment_status}</p>,
          <p>{item.full_amount?.toFixed(2)}</p>,
          <p>{item.transaction_charges?.toFixed(2)}</p>,
          <p>{item?.partner?.user?.payment_election?.length ? "YES" : "NO"}</p>,
        ]);
      });
    }
    return tableData;
  };



  renderSettlementsInfo = () => {
    let { currentPayout, currentEnterprise } = this.props;
      let country = getMyCountryDetails(
        "currency",
        currentEnterprise?.currency
      );

    const allPayoutSettlements = currentPayout?.settlements || [];
    return (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between", width:"95%"}}>
          <div id="next-payout-date">
            <p>Next Payout Date</p>
            <div>
              <h2>{getMeaningfulDate(currentPayout?.next_payout_date)}</h2>
            </div>
          </div>
          <div>
            <p>Total Amount for Payout</p>
            <div className="payout-avalable available">
              <span>{country?.currency?.symbol}</span>
              <p>
                {convertNumberToShortForm(currentPayout?.amount?.toFixed(2))}
              </p>
            </div>
          </div>
        </div>

        <div style={{ borderRadius: 10, marginTop: 20 }}>
          <Title>Settlements</Title>

          {allPayoutSettlements && allPayoutSettlements.length > 0 ? (
            <Table
              columns={[
                "NAME",
                "PAYMENT STATUS",
                "FULL AMOUNT",
                "CHARGES",
                "HAS SET PAYMENT PREFERENCE",

              ]}
              data={this.prepareTableData()}
              hoverAnimation={false}
            />
          ) : (
            <NoItems text="No Asset Expenses Yet..." />
          )}
        </div>
      </div>
    );
  };


  render() {
    return (
      <>
      <div className="settlement-detail-root">
        {this.renderSettlementsInfo()}
      </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const currentInvestment = state?.investments?.filter(
    (x) => x.id === localStorage.selectedOpprtunity
  )[0];

  
  const payoutId = window.location.href.split(
    "opportunity-payout-settlements/"
  )[1];
  const currentPayout =
    currentInvestment?.payouts?.filter((x) => x.id === payoutId)[0] || {};


    const localEnt = localStorage.getItem("currentEnterprise")
      ? JSON.parse(localStorage.getItem("currentEnterprise"))
      : {};
    const currentEnterprise = state?.enterprises?.find(
      (item) => item?.id === localEnt?.id)
  return {
    allPayoutSettlements: currentPayout?.settlements || [],
    currentPayout: currentPayout,
    currentInvestment: currentInvestment,
    investments: state.investments,
    currentEnterprise,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      setEditPayload: (val) => dispatch(setEditSetterAction(val)),
      toggleModal: toggleModalAction,
      editRecord: (current_data, new_data) =>
        dispatch(editMutationAction("INVESTMENT", current_data, new_data)),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllSettlementsForSinglePayout);
