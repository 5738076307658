import React, { Component } from "react";
import "./Stepper.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import StepperHeaders from "./StepperHeaders";

export default class Stepper extends Component {
  constructor() {
    super();
    this.state = {
      activeStepIndex: 0,
      steps: [],
    };
  }

  componentDidMount() {
    this.setState({ steps: this.reConstructSteps() });
  }

  nextStep = () => {
    let {onNext} = this.props
    let { activeStepIndex, steps } = this.state;
    if (onNext) {
      onNext(activeStepIndex);
    }
    activeStepIndex =
      activeStepIndex < steps.length - 1
        ? activeStepIndex + 1
        : steps.length - 1;
    this.setState({
      activeStepIndex,
    });
  };

  previousStep = () => {
    let { activeStepIndex } = this.state;
    activeStepIndex = activeStepIndex > 0 ? activeStepIndex - 1 : 0;
    this.setState({
      activeStepIndex,
    });
  };

  updateStepsOnHeaderClick = (index) => {
    this.setState({ activeStepIndex: index });
  };

  goBack = () => {
    if (this.state.activeStepIndex === 0) {
      return <></>;
    }
    return (
      <div
        className="navigation-btn elevate-float left"
        onClick={() => this.previousStep()}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        <p>Back</p>
      </div>
    );
  };
  goForward = () => {
      if (this.state.activeStepIndex === this.state.steps.length - 1) {
        return (
          <div
            className="navigation-btn elevate-float right"
            onClick={() => {
              if (!this.props.onNext) return;
              this.props.onNext(this.state.activeStepIndex);
            }}
          >
            <p>Submit</p>
          </div>
        );
      }

    return (
      <div
        className="navigation-btn elevate-float right"
        onClick={() => this.nextStep()}
      >
        <p style={{marginRight:10}}>Next</p>
        <FontAwesomeIcon icon={faArrowRight} />
      </div>
    );
  };

  renderStepperComponenet = () => {
    let { activeStepIndex } = this.state;
    let updatedSteps = this.reConstructSteps();
    const step = updatedSteps[activeStepIndex];
    return step?.component;
  };

  reConstructSteps = () => {
      let { steps } = this.props;

    let freshSteps = [];
    if (steps && steps.length >= 0) {
      // eslint-disable-next-line
      steps.map((step, index) => {
        let newStep = {
          ...step,
          completed: false,
          selected: true,
        };
        freshSteps.push(newStep);
      });
    }
    return freshSteps;
  };

  render() {
    let {variant}= this.props

    return (
      <div
        className={`${
          variant === "horizontal" ? "stepper-root" : "stepper-root-vertical"
        }`}
      >
        <StepperHeaders
          steps={this.reConstructSteps()}
          updateStepsOnHeaderClick={this.updateStepsOnHeaderClick}
          activeStepIndex={this.state.activeStepIndex}
          variant={variant}
        />

        <div
          className={`${
            variant === "horizontal"
              ? "components-container"
              : "components-container-vertical"
          }`}
        >
          {this.renderStepperComponenet()}

          <div className="stepper-navigation-btns">
            {this.goBack()}
            {this.goForward()}
          </div>
        </div>
      </div>
    );
  }
}
