import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  toggleSidePane,
  toggleModalAction,
  toggleToastAction,
  toggleImagePreviewAction,
  editMutationAction,
} from "store/actions/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlus,
  faEllipsisV,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Title from "components/title/Title";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import FormGenerator from "tools/components/v1/FormGenerator/FormGenerator";
import Fields from "tools/components/v1/FormGenerator/Fields";
import "./TweetStyle/TweetCard.css";
import "./TweetStyle/Main.css";
import { getFileType } from "shared/js/utils";
import { setEditSetterAction } from '../../../../store/actions/actions';
import { withRouter } from 'react-router-dom';
import { isEmpty } from '../../../../shared/js/utils';
import NoItems from '../../../../tools/components/v1/widgets/NoItems/NoItems';

class CreateAndListReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToast: false,
      showActions: false,
      toShowActionId: "",
      viewMore: false,
      moreId: "",
    };
  }

  updateReduxState = (isEdit, res) => {
    const { currentInvestment, updateItemInredux, investments } = this.props;
    let finalInvestment = currentInvestment;
    let newReports;
    if (!isEdit) {
      newReports = (finalInvestment?.reports || [])?.filter(
        (item) => item.id !== res?.data?.id
      );
    } else {
      newReports = finalInvestment?.reports?.filter(
        (item) => item.id !== res?.data[0]?.id
      );
    }
    newReports.push(isEdit ? res?.data[0] : res?.data);
    finalInvestment.reports = newReports;
    updateItemInredux(investments, finalInvestment);
  };

  handleCreate = async (data, editData, reset) => {
    let res;
    let toSend = {
      ...data,
      opportunity: this.props.currentInvestment.id,
    };

    if (data?.file && data.file.data) {
      toSend = { ...toSend, file: data.file.data?.file?.data };
    } else {
      delete toSend.file;
    }

    try {
      if (!editData?.id) {
        res = await ApiCallHandler.send(
          URLS.CREATE_OPPORTUNITY_REPORT,
          "POST",
          toSend
        );
      } else {
        res = await ApiCallHandler.send(
          URLS.UPDATE_OPPORTUNITY_REPORT,
          "POST",
          { ...toSend, id: editData?.id }
        );
      }

      if (res && res.success) {
        this.setState({ showActions: false, toShowActionId: null });
        this.props.toggleModal({ show: false });
        this.updateReduxState(editData?.id, res);
        this.props.toggleToast({
          show: true,
          message: `Report ${editData?.id ? "updated" : "added"} successfully`,
          type: "success",
        });

        reset();
      } else {
        this.props.toggleToast({
          showToast: true,
          message: "Error creating a report. Try again",
          type: "failure",
        });
        this.props.toggleModal({ show: false });
      }
    } catch (err) {}
  };

  renderCreateForm = (editData) => {
    let period = editData?.period?.split(" ");
    const formDataInit = [
      {
        fieldType: Fields.INPUT,
        label: "Title",
        name: "title",
        placeholder: "Title Here...",
        required: true,
        value: editData?.id ? editData?.title : "",
      },
      {
        fieldType: Fields.DATE,
        label: "Period Start",
        name: "period_start",
        placeholder: "Select Date",
        required: true,
        value: editData?.id ? period[0] : "",
      },
      {
        fieldType: Fields.DATE,
        label: "Period End",
        name: "period_end",
        placeholder: "Select Date",
        required: true,
        value: editData?.id ? period[2] : "",
      },
      {
        fieldType: Fields.RICHTEXT,
        label: "Body",
        name: "body",
        placeholder: "Body Here...",
        // required: true,
        value: editData?.id ? editData?.body : "",
      },
    ];

    const file = {
      fieldType: Fields.FILE,
      label: "Upload Attachment",
      name: "file",
      placeholder: "Upload File",
    };

    const formData = editData?.id ? formDataInit : [...formDataInit, file];

    return (
      <>
        <FormGenerator
          elevation={0}
          fields={formData}
          onSubmit={(data, reset) => this.handleCreate(data, editData, reset)}
          formSubmitBtnText={!editData?.id ? "create report" : "Save Changes"}
          subtitle=""
          title=""
        />
        <br />
        <br />
        <br />
      </>
    );
  };

  showMoreActions = (id = null) => {
    let { showActions } = this.state;
    if (showActions) {
      this.setState({ showActions: false, toShowActionId: null });
    } else {
      this.setState({ showActions: true, toShowActionId: id });
    }
  };

  removeItemFromRedux =(data)=>{
    const { currentInvestment, updateItemInredux, investments } = this.props;
    let finalInvestment = currentInvestment;
    let newReports = finalInvestment?.reports?.filter(
      (item) => item.id !== data.id
    );
    finalInvestment.reports = newReports;
    updateItemInredux(investments, finalInvestment);
  }

  handleDelete = (item) => {
    ApiCallHandler.send(URLS.ARCHIVE_OPPORTUNITY_REPORT, "POST", {
      id: item?.id,
    }).then((res) => {
      if (res && res.success) {
        this.removeItemFromRedux(res?.data);
        this.setState({ showActions: false, toShowActionId: null });
        this.props.toggleToast({
          show: true,
          message: "Opportunity report deleted successfully",
          type: "success",
        });
      } else {
        this.props.toggleToast({
          showToast: true,
          message: "An error occurred. Try again",
          type: "failure",
        });
      }
    });
  };

  handleShowDeleteModal = (item) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.title}"`,
        children: `Are you sure you want to delete "${item?.title}"`,
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => this.handleDelete(item),
        },
      },
    });
  };

  handleToggleAddOrEditReport = (item = {}) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `${item?.id ? "Edit" : "Add"} Report`,
        size: "large",
        children: this.renderCreateForm(item),
        themeColor: "var(--app-theme)",
      },
    });
  };

  toggleImagePreview = (file) => {
    this.props.toggleImagePreview({
      show: true,
      image: file,
    });
  };

  showFiles = (file) => {
    const IMAGE_TYPES = ["png", "jpeg", "jpg"];
    const VIDEO_TYPES = ["mp4", "mov", "avi", "wmv", "flv", "mpeg", "mpg"];
    if (IMAGE_TYPES.includes(getFileType(file)))
      return (
        <img src={file} alt="" onClick={() => this.toggleImagePreview(file)} />
      );
    else if (VIDEO_TYPES.includes(getFileType(file)))
      return (
        <video
          src={file}
          controls
          onClick={() => this.toggleImagePreview(file)}
        />
      );
    else
      return (
        <p
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => window.open(file, "_blank")}
        >
          View Attached file
        </p>
      );
  };

  trancateTextBody = (text) => {
    let toShow = text?.split("</p>")[0];
    if (toShow.length > 300) return toShow?.substr(0, 300) + "...";
    return toShow;
  };

  viewDetails = (tweet) => {
    localStorage.ReportID = tweet?.id;
    this.props.history.push(`/opportunities/report/${tweet?.id}`);
  };
  tweetCard = (tweet) => {
    return (
      <div className="tweet-card">
        <div className="rirght ">
          <div
            style={{ cursor: "pointer" }}
            className="tweet-card-head optimum-padding-right-left"
            onClick={() => this.viewDetails(tweet)}
          >
            <span className="tweet-card-handle">{tweet.title}</span>
            <p id="period">{tweet.period}</p>
          </div>
          <div className="tweet-card-body ">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => this.viewDetails(tweet)}
              className="tweet-card-content optimum-padding-right-left"
              dangerouslySetInnerHTML={{
                __html: this.trancateTextBody(tweet?.body),
              }}
            ></div>
            <div className="tweet-card-image optimum-padding-right-left ">
              {tweet?.file ? this.showFiles(tweet?.file) : <></>}
            </div>

            {/* <div className="divider"></div>
            <div className="tweet-card-footer">
              <div className="comment-report">
                <span>Comment</span>
              </div>
            </div> */}
          </div>
        </div>

        {this.state.showActions && tweet?.id === this.state.toShowActionId ? (
          <div className="more-popup-container elevate-float report-action">
            <div onClick={() => this.handleToggleAddOrEditReport(tweet)}>
              <FontAwesomeIcon icon={faPen} /> Edit info
            </div>
            <div onClick={() => this.handleShowDeleteModal(tweet)}>
              <FontAwesomeIcon icon={faTrashAlt} /> Delete
            </div>
          </div>
        ) : null}

        <div
          className="show-more-container"
          onClick={() => this.showMoreActions(tweet?.id)}
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
      </div>
    );
  };

  prepareTweetCards = () => {
    let { currentInvestment } = this.props;
    const reports = currentInvestment?.reports;

    let tableData = [];
    if (reports && reports.length > 0) {
      reports.forEach((item) => {
        const tweet = {
          title: item.title,
          body: item.body,
          period: `${item?.period_start?.split("T")[0]} - ${
            item?.period_end?.split("T")[0]
          }`,
          logo: JSON.parse(localStorage.currentEnterprise)?.logo,
          enterprise: JSON.parse(localStorage.currentEnterprise),
          file: item?.file,
          created_at: item?.created_at,
          id: item.id,
        };
        tableData.push(this.tweetCard(tweet));
      });
    }

    return tableData.sort((a, b) => a?.created_a - b?.created_at);
  };

  render() {
        let { currentInvestment } = this.props;
        const reports = currentInvestment?.reports;
    return (
      <div style={{ position: "relative" }}>
        <Title
          className="text-toggler"
          onClick={() => this.handleToggleAddOrEditReport()}
        >
          Add Report <FontAwesomeIcon icon={faPlus} />
        </Title>

        <div className="report-list-root">
          {isEmpty(reports) ? (
            <NoItems text="No Reports" />
          ):this.prepareTweetCards()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    toastProps: state.toastProps,
    editData: state.editStatusPayload,
    currentInvestment: state.investments.filter(
      (x) => x.id === localStorage.currentInvestmentId
    )[0],
    investments: state.investments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      toggleSidePane,
      toggleImagePreview: toggleImagePreviewAction,
      toggleModal: toggleModalAction,
      setViewData: setEditSetterAction,
      updateItemInredux: (current_data, new_data) =>
           dispatch(editMutationAction("INVESTMENT", current_data, new_data)),
    },
    dispatch
  );
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAndListReport));
