import React,{Component} from "react";
import ListPartnerships from "./listings/ListPartnerships";

export default class PartnershipsWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      // showToast: false, 
      // changer: "list-all-investments", 
      // mode: "create"
    };
  }

  render() {
    return (
      <div style={{ paddingTop: 10 }}>
        <div style={{ width: "90%", margin: "auto" }}>
          <ListPartnerships />
        </div>
      </div>
    );
  }


}