import React, { Component } from 'react'
import { connect } from 'react-redux'
import FormGenerator from 'tools/components/v1/FormGenerator/FormGenerator'
import { getLocalEnterprise } from 'shared/js/utils';
import { getMyCountryDetails } from '../../../../shared/js/utils';


class SharesInfo extends Component {
    render() {
        const {currentInvestment:editData, currentEnterprise} = this.props
         let { currency } = getMyCountryDetails(
           "currency",
           currentEnterprise?.currency
         );
        const formData = [
          {
            fieldType: FormGenerator.Fields.INCREMENT,
            label: "Available shares?",
            name: "shares",
            value: editData?.id ? editData?.shares : 1,
            required: true,
            elevation: 0,
            width: "100%",
          },
          {
            fieldType: FormGenerator.Fields.INCREMENT,
            label: `Price per share in (${currency?.code})?`,
            value: editData?.id ? editData?.price_per_share : 1,
            defaultValue: 0.0,
            name: "price_per_share",
            elevation: 0,
            required: true,
          },
          {
            fieldType: FormGenerator.Fields.INCREMENT,
            label: "Carry in (%)",
            placeholder: "Example  1.5",
            name: "carry",
            value: editData?.id ? editData?.carry : 0.0,
            required: false,
            elevation: 0,
            helpText: "Carry is the percentage in profit that is allocated to the management team of the opportunity for their work in managing the opportunity.",
          },
          {
            fieldType: FormGenerator.Fields.INCREMENT,
            label: `Management Fee in (${currency?.code}).`,
            placeholder: "Example  19.0",
            name: "mgmt_fee",
            value: editData?.id ? editData?.mgmt_fee : 0.0,
            required: false,
            elevation: 0,
            helpText: "Management fee is the fee charged by the company to manage the opportunity.",
          },
        ];
        return (
            <div>
                <FormGenerator
                    elevation={0}
                    subtitle=""
                    title=""
                    fields={formData}
                    hideSubmitBtn={true}
                    onStateChange={this.props.onStateChange ? this.props.onStateChange : null}
                />
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    const currentInvestment = state.investments.find(item => item?.id === localStorage.invEDT) || {}
   const currentEnterprise = state.enterprises.find(item => item?.id === getLocalEnterprise()?.id)
    return {
        currentInvestment,
        currentEnterprise
    }
}

export default connect(mapStateToProps, null)(SharesInfo)