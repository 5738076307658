import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  editMutationAction,
  toggleModalAction,
  toggleToastAction,
} from "store/actions/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Title from "components/title/Title";
import Table from "tools/components/v1/widgets/Table/Table";
import NoItems from "tools/components/v1/widgets/NoItems/NoItems";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import ShowActions from "../../../../shared/components/showActions/ShowActions";
import {
  getLocalEnterprise,
  getMeaningfulDate,
  getMyCountryDetails,
  isEmpty,
} from "../../../../shared/js/utils";
import CreateOpportunityIncome from "./CreateOpportunityIncome";
import "./opportunityIncome.css";
import { withRouter } from 'react-router';
class ListSingleOpportunityIncomes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToast: false,
      assets: [],
      incomeTypes: [],
      msg: "",
      toast_type: "",
      routeName: "",
      currentData: {
        asset: "",
        type: "",
        id: "",
        period_start: "",
        period_end: "",
        amount: "",
      },

      id: "",
      showActions: false,
      newlyCreated: {},
    };
  }

  close = () => {
    this.props.toggleModal({ show: false });
  };

  DeleteItemfromRedux = (id) => {
    let { currentInvestment, investments } = this.props;
    let filteredIncome = currentInvestment?.income?.filter(
      (item) => item.id !== id
    );
    currentInvestment.income = filteredIncome;
    this.props.updateItemInredux(investments, currentInvestment);
  };

  handleDeleteOpportunityIncome = async (item) => {
    let res = await ApiCallHandler.send(
      URLS.ARCHIVE_OPPORTUNITY_INCOME,
      "POST",
      {
        id: item?.id,
      }
    );
    if (res && res.success) {
      this.DeleteItemfromRedux(item?.id);
      this.props.toggleToast({
        show: true,
        message: "Income deleted successfully",
        type: "success",
      });
    } else {
      this.props.toggleToast({
        showToast: true,
        message: "An error occurred. Try again",
        type: "failure",
      });
    }
  };
  toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      this.setState({
        showActions: false,
        id: "",
      });
    } else {
      this.setState({
        showActions: true,
        id: item?.id,
      });
    }
  };

  toggleShowAllIncomeInfo = (income) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Income Details`,
        children: this.renderIncomeFullDetails(income),
        themeColor: "var(--app-theme)",
        size: "medium",
      },
    });
  };

  renderIncomeFullDetails = (income) => {
    let { currentEnterprise } = this.props;
    let { currency } = getMyCountryDetails(
      "currency",
      currentEnterprise?.currency
    );
    return (
      <div className="detail-main-container">
        <div>
          <p>Type</p>
          <span>{income?.income_type?.name}</span>
        </div>
        <div>
          <p>Amount</p>
          <span>
            {currency?.symbol} {parseFloat(income?.amount)?.toFixed(2)}
          </span>
        </div>
        <div>
          <p>Payment Mode</p>
          <span>{income?.payment_mode}</span>
        </div>
        <div>
          <p>Reciept</p>
          {income?.receipt ? (
            <span onClick={() => window.open(income?.receipt, "_blank")}>
              View Receipt
            </span>
          ) : (
            <span>N/A</span>
          )}
        </div>
        <div>
          <p>Transaction Date</p>
          <span>
            {getMeaningfulDate(income?.transaction_date?.split("T")[0])}
          </span>
        </div>
        <div>
          <p>Reference ID</p>
          <span>{income?.reference_id}</span>
        </div>
        <div>
          <p>Notes</p>
          {income?.notes ? (
            <p
              style={{
                fontWeight: "normal",
                fontSize: "1rem",
                textAlign: "justify",
                color: "black",
              }}
            >
              {income?.notes}
            </p>
          ) : (
            <span>N/A</span>
          )}
        </div>
      </div>
    );
  };

  prepareTableData = () => {
    let { allSingleOpportunityIncomes } = this.props;
    let tableData = [];

    if (!isEmpty(allSingleOpportunityIncomes) ) {
      allSingleOpportunityIncomes.forEach((item) => {
        tableData.push([
          <p onClick={() => this.toggleShowAllIncomeInfo(item)}>
            {item?.income_type?.name}
          </p>,
          <p onClick={() => this.toggleShowAllIncomeInfo(item)}>
            {parseFloat(item?.amount)?.toFixed(2)}
          </p>,
          <p onClick={() => this.toggleShowAllIncomeInfo(item)}>
            {getMeaningfulDate(item?.transaction_date?.split("T")[0])}{" "}
          </p>,
          item?.receipt ? (
            <p onClick={() => window.open(item?.receipt, "_blank")}>
              View Receipt
            </p>
          ) : (
            <p>N/A</p>
          ),
          <p>
            {item?.reference_id
              ? item?.reference_id?.substring(0, 20) + " ...."
              : "No Ref. Id"}{" "}
          </p>,
          <p>{item?.payment_mode}</p>,
          <ShowActions
            onEdit={this.handleAddOrEditIncome}
            onDelete={this.showOppoIncomeDeleteModal}
            item={item}
            id={this.state.id}
            toggleShowMoreOptions={this.toggleShowMoreOptions}
            show={this.state.showActions}
          />,
        ]);
      });
    }
    return tableData;
  };

  renderIncomeItems = () => {
    let { allSingleOpportunityIncomes, currentEnterprise } = this.props;
    let { currency } = getMyCountryDetails(
      "currency",
      currentEnterprise?.currency
    );
    return (
      <div>
        <Title
          className="text-toggler"
          onClick={() => this.handleAddOrEditIncome()}
        >
          Add Income <FontAwesomeIcon icon={faPlus} />
        </Title>
        <div style={{ borderRadius: 10 }}>
          <Title>Income List</Title>

          {!isEmpty(allSingleOpportunityIncomes) ? (
            <Table
              columns={[
                "Income Type",
                `Amount (${currency?.symbol})`,
                "Transaction Date",
                "Reciept",
                "Ref. ID",
                "Payment Mode",
                "Actions",
              ]}
              data={this.prepareTableData()}
              hoverAnimation={false}
            />
          ) : (
            <NoItems text="To be able to record an income, please first create an income type" />
          )}
        </div>
      </div>
    );
  };

  handleAddOrEditIncome = (item = {}) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `${item?.id ? "Update " : "Add"} Income`,
        children: (
          <CreateOpportunityIncome
            editData={item}
            close={this.close}
            currentInvestment = {this.props.currentInvestment?.id}
          />
        ),
        themeColor: "var(--app-theme)",
        size: "medium",
      },
    });
  };

  showOppoIncomeDeleteModal = (item) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.income_type?.name}"`,
        children: (
          <div style={{ padding: "10px 20px" }}>
            Are you sure you want to delete{" "}
            <strong>{item?.income_type?.name}</strong>
          </div>
        ),
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => {
            this.handleDeleteOpportunityIncome(item);
          },
        },
      },
    });
  };

  render() {
    return <div>{this.renderIncomeItems()}</div>;
  }
}

const mapStateToProps = (state, ownerProps) => {
  const currentInvestment = state?.investments?.filter(
    (x) => x.id ===  ownerProps?.match?.params?.id
  )[0];


  const currentEnterprise = state.enterprises?.find(
    (item) => item.id === getLocalEnterprise()?.id
  );
  return {
    allSingleOpportunityIncomes: currentInvestment?.income,
    incomeTypes: currentInvestment?.income_types,
    currentInvestment,
    investments: state.investments,
    currentEnterprise,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      toggleModal: toggleModalAction,
      updateItemInredux: (current_data, new_data) =>
        dispatch(editMutationAction("INVESTMENT", current_data, new_data)),
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListSingleOpportunityIncomes)
);
