import store from "../../store/store";
import { isEmpty } from "./utils";
import {
  editMutationAction,
  loadFormDataAction,
} from "../../store/actions/actions";
import { PUSH } from "./typeConstants";

export const updateTypeDataInRedux = (res, name) => {
  let investments = store.getState().investments;
  let currentInvestment = investments.filter(
    (x) => x.id === window.location.href.split("/")[5]
  )[0];
  let types = currentInvestment[name];
  types = isEmpty(types) ? [res] : [res, ...types];
  currentInvestment[name] = types;
  store.dispatch(
    editMutationAction("INVESTMENT", investments, currentInvestment)
  );
};

export const createDraftWithParentFormData = (formData, idName) => {
  store.dispatch(
    loadFormDataAction({
      idName: idName,
      type: PUSH,
      draft: {
        ...formData,
      },
    })
  );

  return idName;
};

export const findAndReturnDraft = (idName) => {
  if (!idName) return null;
  let formData = store.getState().formData;
  let draft = formData.filter((x) => x.idName === idName)[0];
  return draft;
};
