import React, { Component } from "react";
import FormGenerator from "tools/components/v1/FormGenerator/FormGenerator";
import Fields from "tools/components/v1/FormGenerator/Fields";
import Header from "components/header/Header";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import Toast from "tools/components/v1/widgets/Toast/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Table from "tools/components/v1/widgets/Table/Table";
import Title from "components/title/Title";
import { connect } from "react-redux";
import {
  toggleToastAction,
  createMutationAction,
  archiveMutationAction,
  editMutationAction,
} from "store/actions/actions";
import { toggleModalAction } from "../../../store/actions/actions";
import NoItems from "tools/components/v1/widgets/NoItems/NoItems";
import ShowActions from "../../../shared/components/showActions/ShowActions";
import { ROLES } from "shared/js/typeConstants";

class EnterpriseUsersInvitationsCreateAndList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToast: false,
      msg: "",
      toast_type: "",
      editMode: false,
      toEdit: {},
      show: false,
      resending: false,
      id: "",
      showActions: "",
    };
    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow = () => this.setState({ show: !this.state.show });

  handleDelete = (item) => {
    ApiCallHandler.roamAndFind(
      URLS.ARCHIVE_ENTERPRISE_USER_INVITATIONS,
      "POST",
      { id: item.id }
    ).then((res) => {
      if (res && res.success) {
        this.props.archiveInvitation(this.props.invitations, item.id);
        this.props.toggleToast({
          show: true,
          message: "Invitation successfully deleted",
          type: "success",
        });
      } else {
        this.props.toggleToast({
          showToast: true,
          message: "An error occurred. Try again",
          type: "failure",
        });
      }
    });
  };

  handleShowDeleteModal = (item) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.name}"`,
        children: `Are you sure you want to delete "${item?.name}"`,
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => this.handleDelete(item),
        },
      },
    });
  };
  toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      this.setState({
        showActions: false,
        id: "",
      });
    } else {
      this.setState({
        showActions: true,
        id: item?.id,
      });
    }
  };

  handleEditEnterpriseUser = (item) => {
    this.setState({ editMode: true, toEdit: item, show: true });
    let topOfPage = document.getElementById("create-asset-type-container");
    topOfPage.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  prepareTableData = () => {
    let { invitations } = this.props;
    let tableData = [];
    if (invitations?.length > 0) {
      // TODO: Tweak table component to give access to manipulation of an entire row, instead of cells
      invitations.forEach((item) => {
        tableData.push([
          <p>{item.name}</p>,
          <p>{item.email}</p>,
          <p>{item?.role}</p>,
          <p>{item.has_responded ? "Yes" : "No"}</p>,
          <p>{item.status ? "Yes" : "No"}</p>,
          <p></p>,
          <ShowActions
            onEdit={this.handleEditEnterpriseUser}
            onDelete={this.handleShowDeleteModal}
            item={item}
            id={this.state.id}
            toggleShowMoreOptions={this.toggleShowMoreOptions}
            show={this.state.showActions}
            loading={this.state.resending}
          />,
        ]);
      });
    }
    return tableData;
  };

  showAllEnterpriseUsers = () => {
    let { invitations } = this.props;
    return (
      <div>
        <div style={{ borderRadius: 10 }}>
          <Title>Invited Users</Title>

          {invitations && invitations.length ? (
            <Table
              columns={[
                "Name",
                "Email",
                "Role",
                "Has Responded",
                "Has Accepted",
                "\t",
                "actions",
              ]}
              data={this.prepareTableData()}
              hoverAnimation={false}
            />
          ) : (
            <div id="no-items-container">
              <NoItems text="You have not invited anyone yet." />
            </div>
          )}
        </div>
      </div>
    );
  };

  handleCreate = async (data, reset) => {
    let { toEdit, editMode, showToast } = this.state;
    let ent = localStorage.currentEnterprise
      ? JSON.parse(localStorage.currentEnterprise)
      : {};
    if (showToast) {
      this.setState({ showToast: false });
    }
    let res;
    if (toEdit?.id) {
      res = await ApiCallHandler.send(
        URLS.UPDATE_ENTERPRISE_USER_INVITATION,
        "POST",
        {
          id: toEdit?.id,
          name: data.name,
          email: data.email,
          enterprise: ent?.id,
          url: window.location.protocol + "//" + window.location.host + "/",
        }
      );
    } else {
      const toSend = {
        enterprise: ent?.id,
        url: window.location.protocol + "//" + window.location.host + "/",
        ...data,
        has_responded: false,
        status: false,
      };
      res = await ApiCallHandler.send(
        URLS.CREATE_ENTERPRISE_USER_INVITATION,
        "POST",
        toSend
      );
    }
   this.showResponse(res, editMode, reset);
  };
  showFieldValue = () => {
    let { editMode, toEdit } = this.state;
    let value = editMode ? toEdit.name : "";
    return value;
  };

  renderForm = (editMode, status) => {
    if (status)
      return (
        <div className="scale-and-fade-in">
          <div style={{ marginLeft: 50 }}></div>
          <Header
            title={
              !editMode ? "Invite Users" : `Edit "${this.state.toEdit.name}" `
            }
            subtitle="Fill the Form Below To Invite A New User"
          />
          <FormGenerator
            elevation={0}
            fields={[
              {
                fieldType: Fields.INPUT,
                label: "User Name",
                name: "name",
                placeholder: "User Name Here....",
                required: true,
                defaultValue: this.showFieldValue(),
                value: this.state?.toEdit?.name,
              },
              {
                data: ROLES,
                fieldType: Fields.DROPDOWN,
                label: "What Role will this user play?",
                name: "role",
                placeholder: "Select Role",
                required: true,
                value: this.state?.toEdit?.role,
              },
              {
                fieldType: Fields.INPUT,
                label: "Email",
                name: "email",
                placeholder: "User Email Here...",
                required: true,
                defaultValue: this.showFieldValue(),
                value: this.state?.toEdit?.email,
              },
            ]}
            onSubmit={this.handleCreate}
            formSubmitBtnText={!editMode ? "Invite" : "Update Invitation"}
            subtitle=""
            title=""
            hasCancel
            cancel={(reset) => {
              reset();
              this.toggleShow();
            }}
          />
        </div>
      );

    return (
      <div>
        <Title className="text-toggler" onClick={this.toggleShow}>
          Invite Users <FontAwesomeIcon icon={faPlus} />
        </Title>
      </div>
    );
  };

  showResponse = (res, editMode, reset) => {
    if (res && res.success) {
      const msg = `User Invitation ${
        editMode ? "updated" : "created"
      } successfully`;

      if (editMode) {
        this.props.updateInvitation(this.props.invitations, res.data[0]);
      } else {
        this.props.addInvitation(this.props.invitations, res.data);
      }

      this.setState({
        msg: msg,
        toast_type: "success",
        toEdit: {},
        editMode: false,
      });
      this.props.createToastMessage({
        show: true,
        message: msg,
        type: "success",
      });
      reset();
      this.toggleShow();
    } else {
      const msg = `Error ${
        editMode ? "updating" : "creating"
      } the user invitation. Try again`;

      this.setState({
        msg: msg,
        toast_type: "failure",
      });
      this.props.createToastMessage({
        show: true,
        message: msg,
        type: "failure",
      });
    }
  };

  render() {
    let { msg, showToast, toast_type, editMode } = this.state;
    return (
      <div id="create-asset-type-container">
        {this.renderForm(editMode, this.state.show)}
        {this.showAllEnterpriseUsers()}
        {showToast ? (
          <Toast
            type={toast_type}
            position="bottom"
            message={msg}
            show={showToast}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invitations: state.enterpriseUserInvitations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModal: (obj) => dispatch(toggleModalAction(obj)),
    toggleToast: (obj) => dispatch(toggleToastAction(obj)),
    createToastMessage: (obj) => dispatch(toggleToastAction(obj)),
    addInvitation: (current_data, new_data) =>
      dispatch(
        createMutationAction("ENTERPRISE_INVITATIONS", current_data, new_data)
      ),
    updateInvitation: (current_data, new_data) =>
      dispatch(
        editMutationAction("ENTERPRISE_INVITATIONS", current_data, new_data)
      ),
    archiveInvitation: (current_data, new_data) =>
      dispatch(
        archiveMutationAction("ENTERPRISE_INVITATIONS", current_data, new_data)
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnterpriseUsersInvitationsCreateAndList);
