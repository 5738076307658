import React,{Component} from "react";
import TabView from "tools/components/v1/widgets/TabView/TabView";
import SingleInvestment from "./SingleInvestment";
import InvestorsWrapper from "./investors/InvestorsWrapper";
import AllOpportunityDocuments from "./documents/list/AllOpportunityDocuments";
import AllAssets from "./OpportunityAssets/listings/AllAssets";
import { withRouter } from 'react-router-dom';
import Title from '../../../components/title/Title';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

class InvestmentsShowWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToast: false,
      changer:  "view-single-investment-details",
    };
  }

  TAB_DATA = [
    {
      name: "Details",
      id: "view-single-investment-details",
      component: (
        <SingleInvestment
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Assets",
      id: "view-single-investment-assets",
      component: (
        <AllAssets
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },

    {
      name: "Documents",
      id: "opportunity-docs",
      component: (
        <AllOpportunityDocuments
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Parnters",
      id: "view-single-investment-partnerships",
      component: (
        <InvestorsWrapper
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
  ];

  render() {
    return (
      <>
        <div style={{ paddingTop: 40 }}>
          <div style={{ width: "90%", margin: "auto" }}>
            <div style={{ marginBottom: 15 }}>
              <Title
                style={{
                  color: "#3498DB",
                  fontSize: "1rem",
                  cursor: "pointer",
                  marginLeft: 15,
                }}
                onClick={() => {
                  localStorage.invEDT = this.props.investment?.id || null;
                  this.props.history.push("/opportunity/create");
                }}
              >
                <FontAwesomeIcon icon={faPen} /> Update Opportunity
              </Title>
            </div>
            <TabView
              data={this.TAB_DATA}
              contentAreaStyle={{ paddingTop: 30 }}
              defaultTab={this.state.changer}
              onMount={(changer) => this.setState({ changer })}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let currentInvestment = state.investments?.find((x) => x.id === localStorage.currentInvestmentId)
  return {
    investment: currentInvestment,
  }
}

export default withRouter(connect(mapStateToProps, null)(InvestmentsShowWrapper));