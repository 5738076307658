import React from "react";
import PropTypes from "prop-types";
import "./Toast.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
const TYPES = {
  SUCCESS: "success",
  FAILURE: "failure",
};
const THEMES = {
  ERROR: {
    background: "var(--toast-error-color)",
    textColor: "#bb7373",
  },
  SUCCESS: {
    background: "var(--toast-success-color)",
    textColor: "white",
    borderColor: "#35af60",
  },
  DEFAULT: {
    background: "white",
    textColor: "var(--app-theme-lighter)",
  },
};

function Toast({ message, type, timeout, close, allowTimeout, key }) {
  const getTheme = (type) => {
    if (type === TYPES.FAILURE) return THEMES.ERROR;
    if (type === TYPES.SUCCESS) return THEMES.SUCCESS;
    return THEMES.DEFAULT;
  };

  const theme = getTheme(type);

  if (allowTimeout)
    setTimeout(() => {
      if (close) close();
    }, timeout);

  return (
    <div
      className={`toast-wrapper show-from-bottom`}
      key={key || "notification-key"}
    >
      <div
        className="toast-container elevate-float"
        style={{
          "--toast-back-color": theme.background,
          "--toast-border-color": theme.borderColor || theme.textColor,
        }}
      >
        <div className="message-container">
          <div style={{ flex: "8", "--toast-text-color": theme.textColor }}>
            {message}
          </div>
          {close && (
            <i
              className={`everyday-flex`}
              style={{
                marginLeft: "auto",
                flex: 2,
                justifyContent: "flex-end",
                color: theme.textColor,
                cursor: "pointer",
              }}
              onClick={() => close()}
            >
              <FontAwesomeIcon icon={faTimesCircle} />{" "}
            </i>
          )}
        </div>
      </div>
    </div>
  );
}

Toast.propTypes = {
  /**
   * Message to display on the toast
   */
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * whether to show positive, negative or a neutral theme for the toast
   */
  type: PropTypes.oneOf(["success", "failure", "default"]),

  /**
   * Time in milliseconds to show toast before closing
   */
  timeout: PropTypes.number,

  /**
   * Specifies whether the toast should close on its own after some time. If set to true, a close function is required
   */
  allowTimeout: PropTypes.bool,
  /**
   * An external function that manipulates the toggle value of the toast
   */
  close: PropTypes.func,
};
Toast.defaultProps = {
  message: "The notification message bruh",
  type: TYPES.SUCCESS,
  timeout: 2000,
};
export default Toast;
