import React, { Component } from "react";
import "./error.css";
import ErrorSvg from "./ErrorSvg";
export default class ErrorPage extends Component {
  render() {


    
    return (
      <div className="error-page-container">
        <center>
          <ErrorSvg />
          <h3 style={{ fontWeight: "500" }}>
            Oops! This page is not available
          </h3>
        </center>
      </div>
    );
  }
}
