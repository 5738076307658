import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  toggleToastAction,
  toggleModalAction,
  editMutationAction,
} from "store/actions/actions";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import Table from "tools/components/v1/widgets/Table/Table";
import Title from "components/title/Title";
import { withRouter } from "react-router-dom";

import "./Investors.css";
import ShowActions from '../../../../shared/components/showActions/ShowActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import InvitationForm from "./InvitationForm";
import NoItems from '../../../../tools/components/v1/widgets/NoItems/NoItems';
import FormGenerator from '../../../../tools/components/v1/FormGenerator/FormGenerator';
import Fields from '../../../../tools/components/v1/FormGenerator/Fields';
class SingleInvestmentPartnerships extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      showActions: false,
    };
  }
  calculatesAvailableShares = () => {
    let { currentInvestment: investment } = this.props;
    let totalShares = 0;
    if (investment?.partnerships && investment?.partnerships?.length) {
      investment.partnerships.forEach((partnership) => {
        totalShares += partnership.shares;
      });
    }
    if (investment?.invitations?.length) {
      investment.invitations
        ?.filter((item) => item?.has_responded === null)
        ?.forEach((invitation) => {
          totalShares += invitation.shares;
        });
    }

    return Number(totalShares);
  };

  approvePartnership = async (item) => {
    const res = await ApiCallHandler.send(URLS.APPROVE_PARTNERSHIP, "POST", {
      id: item.id,
    });
    if (res?.success) {
      window.location.reload();
      this.props.toggleToast({
        show: true,
        message: "Partnership Has Been Successfully Approved",
        type: "success",
      });
    } else {
      this.props.toggleToast({
        show: true,
        message: "Approval Failed. Please Try again Later",
        type: "failure",
      });
    }
  };
  toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      this.setState({
        showActions: false,
        id: "",
      });
    } else {
      this.setState({
        showActions: true,
        id: item?.id,
      });
    }
  };

  showReciept = (item) => {
    if (item?.payment_receipt) {
      return (
        <p
          style={{ color: "blue" }}
          onClick={() => window.open(item?.payment_receipt, "_blank")}
        >
          View Reciept
        </p>
      );
    } else {
      return <p>No Reciept</p>;
    }
  };



  isEnterprisePartner = (item) => {
    if (item?.partner_type === "ENTERPRISE") {
      return true
    } else return false
  }

  prepareTableData = () => {
    let { currentInvestment: opportunity } = this.props;
    let partnerships = opportunity?.partnerships;

    if (partnerships?.length > 0) {
      return partnerships.map((item) => [
        <p onClick={() => this.showEditModal(item)}>{this.isEnterprisePartner(item) ?item?.enterprise?.name : item?.user?.first_name}</p>,
        <p>{item?.shares}</p>,
        <p>{this.isEnterprisePartner(item) ?item?.enterprise?.email :  item?.user?.email}</p>,
        this.showReciept(item),
        <p>
          {item?.is_approved ? (
            "YES"
          ) : (
            <ShowActions
              onApproved={this.handleApproveInvestor}
              onDeclined={this.handleRejectInvestor}
              item={item}
              id={this.state.id}
              toggleShowMoreOptions={this.toggleShowMoreOptions}
              show={this.state.showActions}
            />
          )}
        </p>,
      ]);
    }
    return [];
  };

  renderTable = () => {
    return (
      <>
        <Title>Investors</Title>
        {this.props.currentInvestment?.partnerships?.length > 0 ? (
          <Table
            columns={["Name", "Shares", "Email", "Payment Reciept", "status"]}
            data={this.prepareTableData()}
          />
        ) : (
          <div>
            <NoItems text="No Investors Yet" />
          </div>
        )}
      </>
    );
  };


  updatePartnershipInReduxState = (data)=>{
    let {currentInvestment, investments} = this.props;
    let filteredParnerships = currentInvestment?.partnerships?.filter((item)=>item?.id !== data?.id);
    filteredParnerships.push(data);
    currentInvestment.partnerships = filteredParnerships;
    this.props.updateRedux(investments, currentInvestment);
  }

  handleApproveInvestor = async (item) => {
    const res = await ApiCallHandler.send(URLS.APPROVE_PARTNERSHIP, "POST", {
      id: item.id,
    });
    if (res.success) {
      this.updatePartnershipInReduxState(res?.data);
      this.props.toggleToast({
        show: true,
        message: "Partnership Has Been Successfully Approved",
        type: "success",
      });
    } else {
      this.props.toggleToast({
        show: true,
        message: "Approval Failed. Please Try again Later",
        type: "failure",
      });
    }
  };

  handleRejectInvestor = async (item) => {
    const res = await ApiCallHandler.send(URLS.REJECT_PARTNERSHIP, "POST", {
      id: item.id,
    });

    if (res?.success) {
      this.updatePartnershipInReduxState(res?.data);
      this.props.toggleToast({
        show: true,
        message: "Partnership Has Been Successfully DisApproved",
        type: "success",
      });
    } else {
      this.props.toggleToast({
        show: true,
        message: "DisApproval Failed. Please Try again Later",
        type: "failure",
      });
    }
  };
  // update-array

  handleUpdatePartnershipInfo = (data, reset, item)=>{
    const toSend = {
      payment_date: data?.payment_date,
      has_received_payment:
        data?.has_received_payment === "Yes" ? "True" : "False",
      id: item?.id,
    };
   ApiCallHandler.send(URLS.UPDATE_PARTNERSHIPS, "POST", toSend).then(res=>{
      if (res.success) {
        this.updatePartnershipInReduxState(res?.data[0]);
        this.props.toggleModal({show:false})
        this.props.toggleToast({
          show: true,
          message: "Partnership Has Been Successfully Updated",
          type: "success",
        });
      } else {
        this.props.toggleToast({
          show: true,
          message: "Approval Failed. Please Try again Later",
          type: "failure",
        });
      }
   })
  }
  partnershipInfoForm = (item) => {
    return (
      <FormGenerator
        elevation={1}
        fields={[
          {
            fieldType: Fields.RADIOGROUP,
            label: "Have you recieved Payment?",
            name: "has_received_payment",
            placeholder: "Name",
            data: ["Yes", "No"],
            value: item?.has_received_payment ? "Yes" : "No" || "No",
          },
          {
            fieldType: Fields.DATE,
            label: "Date Payment Received",
            name: "payment_date",
            placeholder: "When was payment received",
            value: item?.id ? item?.payment_date?.split("T")[0] : "",
          },
        ]}
        onSubmit={(data, reset) =>
          this.handleUpdatePartnershipInfo(data, reset, item)
        }
        formSubmitBtnText={"Update Info"}
        title=""
        subtitle=""
      />
    );
  };

  showEditModal = (item) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Update Partnership Information`,
        children: this.partnershipInfoForm(item),
        themeColor: "var(--app-theme)",
        size: "medium",
      },
    });
  };

  render() {
    return (
      <>
        <div>
          <Title
            className="text-toggler"
            onClick={() =>
              this.props.toggleModal({
                show: true,
                props: {
                  title: `Send Invitations`,
                  children: (
                    <InvitationForm
                      opportunity={this.props.currentInvestment}
                      totalShares={this.calculatesAvailableShares}
                    />
                  ),
                  themeColor: "var(--app-theme)",
                },
              })
            }
          >
            Invite Partners <FontAwesomeIcon icon={faPlus} />
          </Title>
        </div>
        <br />
        {this.renderTable()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    investments: state.investments,
    currentInvestment: state.investments.filter(
      (x) => x.id === localStorage.currentInvestmentId
    )[0],
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleModal: toggleModalAction,
      toggleToast: toggleToastAction,
      updateRedux: (current_data, new_data) =>
        dispatch(editMutationAction("INVESTMENT", current_data, new_data)),
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleInvestmentPartnerships)
);
