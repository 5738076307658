/* eslint-disable import/no-anonymous-default-export */
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import { toggleModalAction, toggleToastAction } from "store/actions/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "tools/components/v1/widgets/Button/Button";
import "./opportunityResponse.css"
import Title from "components/title/Title";

export default () => {
  const [dataGotten, setDataGotten] = useState({
    name: "",
    opportunity: {
      shares: "",
      price_per_share: "",
      asset: {
        name: "Loading",
        identification_number_name: "",
        identification_number: "",
        asset_type: {
          name: "",
        },
      },
      enterprise: {
        name: "",
      },
    },
  });

  // const [hasEnterprises, setHasEnterprises] = useState(false)

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const params = window.location.href.toString().split("||");
    if (params) {
      // eslint-disable-next-line no-unused-vars
      const id = params[1];
      const currentUserDetails = JSON.parse(atob(params[2].split("entry=")[1]))

      ApiCallHandler.send(URLS.GET_USERS, "POST", {
        id: "123e4567-e89b-12d3-a456-426614174000", 
        email: currentUserDetails.email,
        action: "FIND_OR_CREATE"
      })
      .then(({data}) => {
        localStorage.userDeta = JSON.stringify(data)
        // data.enterprises.length > 0 ? setHasEnterprises(true) : setHasEnterprises(false)
        ApiCallHandler.send(URLS.GET_OPPORTUNITY_INVITATION_INFO, "POST", {
          id,
        }).then(({ success, data }) => {
          setDataGotten(data);
          localStorage.currentNonAdminUserDetails = JSON.stringify(data)
          if (data?.has_accepted) {
            history.push("/user-dashboard");
          }
        });

      });

  
    }
  }, [history]);

  const handleAcceptOffer = async () => {
    const dataToSend = {
      shares: dataGotten?.shares,
      opportunity: dataGotten?.opportunity.id,
      opportunity_invitation_id: dataGotten?.id,
      action: "CREATE_USER_AND_PARTNERSHIP",
    };
    const res = await ApiCallHandler.send(
      URLS.ACCEPT_INVITATION_PATH,
      "POST",
      dataToSend
    );

    if (res.success) {
      dispatch(toggleModalAction({ show: false }));
      dispatch(
        toggleToastAction({
          show: true,
          message: "Success",
          type: "success",
        })
      );
      history.push("/user-dashboard?mixin=" + dataGotten.email);
    } else {
      dispatch(toggleModalAction({ show: false }));
      dispatch(
        toggleToastAction({
          show: true,
          message: "Acceptance Failed Please Try Again",
          type: "failure",
        })
      );
    }
  };


  // const titilify = title=>{
  //   return title.toUpperCase()
  // }

  const showConfirmationMassage = ()=>{

    return (
      <div className="elevate-1 response-wrapper ">
        <center id="res-name-section">
          <Title>Hi {dataGotten?.name},</Title>
        </center>
        <div id="response-intro-container">
          <p>
            You have been invited by{" "}
            <strong>{dataGotten?.opportunity.enterprise.name}</strong> and
            offered <strong>{dataGotten?.shares} shares</strong> which will cost
            you{" "}
            <strong style={{ color: "green" }}>
              GHC {dataGotten?.shares * dataGotten?.opportunity.price_per_share}{" "}
            </strong>
            In the opportunity with the details below
          </p>
        </div>

        <div id="res-about-container">
          <Title>{dataGotten?.opportunity.name}</Title>
          <p>{dataGotten?.opportunity.about}</p>
        </div>

        <div id="response-btns-container">
          <Button
            theme="danger"
            onClick={() => {
            }}
          >
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{ fontSize: "2rem", color: "red" }}
            />
            Reject
          </Button>
          <Button
            theme="success"
            onClick={() => {
              dispatch(
                toggleModalAction({
                  show: true,
                  props: {
                    title: "Accept Invitation",
                    children: (
                      <div>
                        <p>Click on "Yes I Accept" to accept the invitation</p>
                        <br />
                        <Button theme="success" onClick={handleAcceptOffer}>
                          Yes I Accept
                        </Button>
                      </div>
                    ),
                    themeColor: "var(--app-theme)",
                  },
                })
              );
            }}
          >
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ fontSize: "2rem", color: "green" }}
            />
            Accept
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        width: "50%",
        margin: "auto",
        paddingTop: "3%",
      }}
    >
      {showConfirmationMassage()}
    </div>
  );
};
