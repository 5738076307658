import React, { useState } from "react";
import PropTypes from "prop-types";
import "./AutoComplete.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const NONE = "------";
const ENTER_KEY_CODE = 13;
/**
 * Does not support array of objects yet, only array of strings
 * @param {*} props 
 * @returns 
 */
function AutoComplete(props) {
  const { data, placeholder, allowNew, onItemSelected, value, defaultValue } =
    props;
  const [textValue, setTextValue] = useState(value || defaultValue);
  const [drop, toggleDropdown] = useState(false);

  const handleTextChange = (e) => {
    setTextValue(e.target.value);
    toggleDropdown(true);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY_CODE) handleSelection(textValue);
  };

  const filterData = () => {
    const out = (data || []).filter((item) =>
      item.toLowerCase().includes(textValue?.toLowerCase())
    );
    return out.length > 0 ? out : [NONE];
  };

  const handleSelection = (item, added = false) => {
    setTextValue(item);
    toggleDropdown(false);
    if (added && onItemSelected) return onItemSelected(textValue);
    if (onItemSelected) {
      if (item === NONE) return onItemSelected(null);
      onItemSelected(item);
    }
  };
  const ejectDropdown = (data = []) => {
    if (!drop) return;
    return (
      <>
        {drop && (
          <div
            className="ghost-curtain"
            onClick={() => toggleDropdown(false)}
          ></div>
        )}
        <div className={`auto-dropdown-children-container-full elevate-float `}>
          {data.map((item, index) => {
            const isSelected = textValue?.toLowerCase() === item?.toLowerCase();
            return (
              <div
                key={index.toString()}
                className={`auto-dropdown-item ${
                  isSelected && "selected-item"
                }`}
                onClick={() => handleSelection(item)}
              >
                {item}
              </div>
            );
          })}
          {allowNew && (
            <div
              className="auto-dropdown-item"
              onClick={() => handleSelection(null, true)}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />
              Add New
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <input
        className="input textbox"
        value={textValue}
        onChange={handleTextChange}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        onFocus={() => toggleDropdown(true)}
      />
      {ejectDropdown(filterData())}
    </div>
  );
}

AutoComplete.propTypes = {
  placeholder: PropTypes.string,
  /**
   * Array of string items to search from when typing
   */
  data: PropTypes.arrayOf(PropTypes.string),
  /**
   * @param value : value of selected item
   */
  onItemSelected: PropTypes.func,
  /**
   * default on start
   */
  value: PropTypes.string,
  /**
   * default on start
   */
  defaultValue: PropTypes.string,

  /**
   * Determines whether the "add new" option should show on dropdown if typed content does not exist
   * in the dropdown
   */
  allowNew: PropTypes.bool,
};
AutoComplete.defaultProps = {
  placeholder: "Enter any text...",
  data: ["orange", "mango", "banana", "grapes"],
  allowNew: true,
  defaultValue: "",
  value: "",
};

export default AutoComplete;
