import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import AssetDetail from './AssetDetail';
import { connect } from 'react-redux';
import Header from '../../../components/header/Header';


class AssetDetailWrapper extends Component {
   constructor(props) {
    super(props);
    this.state = { changer: "asset-detail" };
  }
  render() {
    return (
      <div style={{ width: "90%", margin: "auto", marginTop: "2rem" }}>
        <Header title="Asset Detail"  subtitle=" "/>
        <AssetDetail />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modalProps: state.modalProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
    //   toggleModal: toggleModalAction,
    },
    dispatch
  );
};

export default connect( mapStateToProps, mapDispatchToProps)(AssetDetailWrapper);

