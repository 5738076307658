
import {
  DO_NOTHING,
  SHOW_SIDE_PANE,
  GET_ASSETS,
  GET_ASSET_TYPES,
  GET_ASSET_EXPENSE_TYPES,
  GET_ASSET_EXPENSES,
  GET_ASSET_INCOMES,
  GET_ASSET_INCOME_TYPES,
  SET_ASSET_CREATE_EDIT_TYPE,
  SET_MANAGE_ASSET_TYPES_ID,
  TOGGLE_TOAST,
  TOGGLE_MODAL,
  LOADING_BLANKET,
  SET_CURRENT_ENTERPRISE,
  LOAD_INVESTMENTS,
  LOAD_ENTERPRISES,
  EDIT_SETTER,
  SET_CURRENT_ENTERPRISE_DASHBOARD_DETAILS,
  TOGGLE_IMAGE_PREVIEW,
  SET_ALL_ENTERPRISES,
  LOAD_ENTERPRISE_INVITATIONS,
  LOAD_COUNTRY_ENTERPRISE_REQUIREMENTS,
  LOAD_ENTERPRISE_PARTNERS,
  LOAD_EXCHANGE_RATES,
  LOAD_FORM_DATA,
  SET_TYPE_DATA,
} from "../actions/Constants/Constants";
const INIT = ["Hello", "There"];
export const doNothingReducer = (state = INIT, action) => {
  if (action.type === DO_NOTHING) {
    return action.payload;
  }
  return state;
};

export const setAssetCreateOrEditTypeReducer = (state = false, action) => {
  if (action.type === SET_ASSET_CREATE_EDIT_TYPE) {
    return action.payload;
  }
  return state;
};

export const setEditSetterReducer = (state = {}, action) => {
  if (action.type === EDIT_SETTER) {
    return action.payload;
  }
  return state;
};


export const setCurrentEnterpriseReducer = (state = false, action) => {
  if (action.type === SET_CURRENT_ENTERPRISE) {
    return action.payload;
  }
  return state;
};


export const setCurrentEnterpriseDashboardDetailsReducer = (state = false, action) => {
  if (action.type === SET_CURRENT_ENTERPRISE_DASHBOARD_DETAILS) {
    return action.payload;
  }
  return state;
};

export const setManageAssetTabReducer = (state = "list-all-assets", action) => {
  if (action.type ===  SET_MANAGE_ASSET_TYPES_ID) {
    return action.payload;
  }
  return state;
};

export const toggleSidePaneReducer = (
  state = { on: false, component: null },
  action
) => {
  if (action.type === SHOW_SIDE_PANE) return action.payload || state;
  return state;
};

export const getAssetTypesReducer = (state = [], action) => {
  if (action.type === GET_ASSET_TYPES) {
    return action.payload;
  }
  return state;
};
export const getAssetsReducer = (state = [], action) => {
  if (action.type === GET_ASSETS) {
    return action.payload;
  }
  return state;
};

export const getAssetExpenseTypesReducer = (state = [], action) => {
  if (action.type === GET_ASSET_EXPENSE_TYPES) {
    return action.payload;
  }
  return state;
};

export const getAssetExpensesReducer = (state = [], action) => {
  if (action.type === GET_ASSET_EXPENSES) {
    return action.payload;
  }
  return state;
};

export const getAssetIncomeTypesReducer = (state = [], action) => {
  if (action.type === GET_ASSET_INCOME_TYPES) {
    return action.payload;
  }
  return state;
};

export const getAssetIncomesReducer = (state = [], action) => {
  if (action.type === GET_ASSET_INCOMES) {
    return action.payload;
  }
  return state;
};

export const getAllEnterprisesReducer = (state = {}, action) => {
  if (action.type === SET_ALL_ENTERPRISES) {
    return action.payload;
  }
  return state;
};


export const getEnterpriseUserInvitationsReducer = (state = [], action) => {
  if (action.type === LOAD_ENTERPRISE_INVITATIONS) {
    return action.payload;
  }
  return state;
};


export const toggleToastReducer = (
  state = { show: false, message: "Default Message" },
  action
) => {
  if (action.type === TOGGLE_TOAST) return action.payload;
  return state;
};

export const toggleModalReducer = (state = [], action) => {
  if (action.type === TOGGLE_MODAL){
   return action.payload;
  }
  return state;
};
export const toggleLoadingBlanketReducer = (
  state = { show: false, props: {} },
  action
) => {
  if (action.type === LOADING_BLANKET) return action.payload;
  return state;
};
export const loadInvestmentsReducer = (state = [], action) => {
  if (action.type === LOAD_INVESTMENTS) return action.payload;
  return state;
};
export const loadEnterprisesReducer = (state = [], action) => {
  if (action.type === LOAD_ENTERPRISES) return action.payload;
  return state;
};

export const toggleImagePreviewReducer = (
  state = { show: false, image: null },
  action
) => {
  if (action.type === TOGGLE_IMAGE_PREVIEW) return action.payload;
  return state;
};

export const getEnterprisesReducer = (state = [], action) => {
  if (action.type === SET_ALL_ENTERPRISES) return action.payload;
  return state;
};
export const getAllCountryEnterpriseRequirementsReducer = (state = [], action) => {
  if (action.type === LOAD_COUNTRY_ENTERPRISE_REQUIREMENTS) return action.payload;
  return state;
};
export const getAllPartnersReducer = (state = [], action) => {
  if (action.type === LOAD_ENTERPRISE_PARTNERS) return action.payload;
  return state;
};
export const getExchangeRatesReducer = (state = [], action) => {
  if (action.type === LOAD_EXCHANGE_RATES) return action.payload;
  return state;
};
export const loadFormDataReducer = (state = [], action) => {
  if (action.type === LOAD_FORM_DATA) return action.payload;
  return state;
};

export const setTypeDataReducer = (state = [], action) => {
  if(action.type === SET_TYPE_DATA) return action.payload;
  return state;

}
