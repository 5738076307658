import {
  SHOW_SIDE_PANE,
  DO_NOTHING,
  GET_ASSET_EXPENSES,
  GET_ASSETS,
  GET_ASSET_TYPES,
  GET_ASSET_EXPENSE_TYPES,
  GET_ASSET_INCOMES,
  GET_ASSET_INCOME_TYPES,
  SET_ASSET_CREATE_EDIT_TYPE,
  SET_MANAGE_ASSET_TYPES_ID,
  TOGGLE_TOAST,
  TOGGLE_MODAL,
  LOADING_BLANKET,
  SET_CURRENT_ENTERPRISE,
  LOAD_INVESTMENTS,
  LOAD_ENTERPRISES,
  EDIT_SETTER,
  SET_CURRENT_ENTERPRISE_DASHBOARD_DETAILS,
  TOGGLE_IMAGE_PREVIEW,
  LOAD_ENTERPRISE_INVITATIONS,
  SET_ALL_ENTERPRISES,
  LOAD_COUNTRY_ENTERPRISE_REQUIREMENTS,
  LOAD_ENTERPRISE_PARTNERS,
  LOAD_EXCHANGE_RATES,
  LOAD_FORM_DATA,
  SET_TYPE_DATA,
} from "./Constants/Constants";
import URLS from "../../shared/js/urls";
import ApiCallHandler from "../../shared/js/ApiCallHandler";
import { getRandomStringKey } from "tools/components/v1/shared/js/utils";
import store from "../store";
import { PUSH } from "shared/js/typeConstants";
import { REMOVE } from "../../shared/js/typeConstants";
import { isEmpty } from "../../shared/js/utils";

const currentUserProfile = localStorage.userDeta
  ? JSON.parse(localStorage.userDeta)
  : {};

const currentEnterprise = localStorage.currentEnterprise
  ? JSON.parse(localStorage.currentEnterprise)
  : {};
const MUTATION_FILTERS = {
  ASSET: GET_ASSETS,
  ASSET_INCOME: GET_ASSET_INCOMES,
  ASSET_EXPENSE: GET_ASSET_EXPENSES,
  INVESTMENT: LOAD_INVESTMENTS,
  ENTERPRISE_MUTATION: SET_ALL_ENTERPRISES,
  ASSETS_MUTATION: GET_ASSETS,
  ENTERPRISE_INVITATIONS: LOAD_ENTERPRISE_INVITATIONS,
};

export const doNothingFunction = (someDataToBePassed) => {
  return { type: DO_NOTHING, payload: someDataToBePassed };
};

export const setAssetCreateOrEditTypeAction = (val) => {
  return { type: SET_ASSET_CREATE_EDIT_TYPE, payload: val };
};

export const setEditSetterAction = (val) => {
  return { type: EDIT_SETTER, payload: val };
};

export const setManageAssetTabIdAction = (val = "list-all-assets") => {
  return { type: SET_MANAGE_ASSET_TYPES_ID, payload: val };
};

export const setCurrentEnterpriseAction = (val) => {
  return { type: SET_CURRENT_ENTERPRISE, payload: val };
};

export const toggleSidePane = (load) => {
  return { type: SHOW_SIDE_PANE, payload: load };
};

export const getAssetTypesAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(
      URLS.LIST_ASSETS_TYPES,
      "POST",
      { enterprise_id: currentEnterprise?.id }
    );
    return dispatch({ type: GET_ASSET_TYPES, payload: res?.data });
  };
};

export const getCurrentEnterpriseDashboardContentsAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(
      URLS.GET_ADMIN_DASHBOARD_COMPANY,
      "POST",
      { enterprise_id: currentEnterprise?.id }
    );
    return dispatch({
      type: SET_CURRENT_ENTERPRISE_DASHBOARD_DETAILS,
      payload: res?.data,
    });
  };
};

export const getAssetsAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(URLS.LIST_ASSETS, "POST", {
      enterprise_id: currentEnterprise?.id,
    });
    return dispatch({ type: GET_ASSETS, payload: res?.data });
  };
};

export const setAllEnterprisesAction = (data = []) => {
  return function (dispatch) {
    return dispatch({ type: SET_ALL_ENTERPRISES, payload: data });
  };
};

export const getAssetExpenseTypesAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(
      URLS.LIST_ASSETS_EXPENSES_TYPES,
      "POST",
      { enterprise_id: currentEnterprise?.id }
    );
    return dispatch({ type: GET_ASSET_EXPENSE_TYPES, payload: res?.data });
  };
};

export const getAssetExpensesAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(
      URLS.LIST_OPPORTUNITY_EXPENSE,
      "POST",
      { enterprise_id: currentEnterprise?.id }
    );
    return dispatch({ type: GET_ASSET_EXPENSES, payload: res?.data });
  };
};

export const getAssetIncomeTypesAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(
      URLS.LIST_ASSETS_INCOME_TYPES,
      "POST",
      { enterprise_id: currentEnterprise?.id }
    );
    return dispatch({ type: GET_ASSET_INCOME_TYPES, payload: res?.data });
  };
};

export const getEnterpriseUserInvitationAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(
      URLS.LIST_ENTERPRISE_USER_INVITATIONS,
      "POST",
      { enterprise_id: currentEnterprise?.id }
    );
    return dispatch({ type: LOAD_ENTERPRISE_INVITATIONS, payload: res?.data });
  };
};

export const getAssetIncomesAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(
      URLS.LIST_OPPORTUNITY_INCOME,
      "POST",
      { enterprise_id: currentEnterprise?.id }
    );
    return dispatch({ type: GET_ASSET_INCOMES, payload: res?.data });
  };
};

export const toggleToastAction = (
  payload = { show: false, messsage: "Default Message" }
) => {
  return {
    type: TOGGLE_TOAST,
    payload: { key: getRandomStringKey(), ...payload },
  };
};

export const toggleModalAction = (payload = { show: false, props: {} }) => {
  let state = store.getState();
  let toAdd = [];
  if (!payload?.show) {
    state.modalProps?.shift();
    toAdd = [...state.modalProps];
  } else {
    toAdd = [{ key: getRandomStringKey(), ...payload }, ...state.modalProps];
  }

  return {
    type: TOGGLE_MODAL,
    payload: toAdd,
  };
};

export const toggleLoadingBlanket = (payload = { show: false, props: {} }) => {
  return {
    type: LOADING_BLANKET,
    payload: payload,
  };
};

export const getAllInvestmentsAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(
      URLS.LIST_OPPORTUNITIES,
      "POST",
      { enterprise_id: currentEnterprise?.id }
    );
    return dispatch({ type: LOAD_INVESTMENTS, payload: res?.data || [] });
  };
};
export const getAllEnterprisesAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(
      URLS.LIST_ENTERPRISES,
      "POST",
      { user_id: currentUserProfile.id }
    );
    return dispatch({ type: LOAD_ENTERPRISES, payload: res?.data || [] });
  };
};

///////////////////////// ASSET CRUD ACTIONS BEGIN /////////////////////////////

export const createMutationAction = (filter, current_data, new_data) => {
  return function (dispatch) {
    if (
      current_data !== null &&
      current_data !== undefined &&
      current_data !== false
    ) {
      return dispatch({
        type: MUTATION_FILTERS[filter],
        payload: [new_data, ...current_data],
      });
    } else {
      return dispatch({ type: MUTATION_FILTERS[filter], payload: [new_data] });
    }
  };
};

export const archiveMutationAction = (
  filter,
  current_data,
  item_to_delete_id
) => {
  return function (dispatch) {
    return dispatch({
      type: MUTATION_FILTERS[filter],
      payload: current_data.filter((data) => data.id !== item_to_delete_id),
    });
  };
};

export const editMutationAction = (filter, current_data, updated_item) => {
  let updated_data = current_data.filter((data) => data.id !== updated_item.id);
  updated_data = [updated_item, ...updated_data];
  return function (dispatch) {
    return dispatch({
      type: MUTATION_FILTERS[filter],
      payload: updated_data,
    });
  };
};

///////////////////////// ASSET CRUD ACTIONS END ///////////////////////////////

export const toggleImagePreviewAction = (
  payload = { show: false, props: {} }
) => {
  return {
    type: TOGGLE_IMAGE_PREVIEW,
    payload: { key: getRandomStringKey(), ...payload },
  };
};

export const getEnterprisesActions = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.send(URLS.GET_USERS, "POST", {
      id: "123e4567-e89b-12d3-a456-426614174000",
      email: currentUserProfile?.email,
      action: "FIND_OR_CREATE",
    });
    const myEnterprises =
      res?.data?.enterprises && res?.data?.enterprises.length
        ? res?.data.enterprises
        : [];
    const myOtherEnterprises =
      res?.data?.my_other_enterprises && res?.data?.my_other_enterprises.length
        ? res?.data?.my_other_enterprises?.map((e) => e.enterprise)
        : [];
    return dispatch({
      type: SET_ALL_ENTERPRISES,
      payload: [...myEnterprises, ...myOtherEnterprises] || [],
    });
  };
};

export const collectVerificationDataAction = (payload) => {
  return {
    type: LOAD_COUNTRY_ENTERPRISE_REQUIREMENTS,
    payload: payload,
  };
};

export const getAllCountryEnterpriseRequirementsAcion = () => {

  console.log("========== ENT COUNTRY =======", currentEnterprise?.country);

  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(
      URLS.LIST_COUNTRY_ENTERPRISE_REQUIREMENTS,
      "POST",
      {country: currentEnterprise?.country}
    );
    return dispatch({
      type: LOAD_COUNTRY_ENTERPRISE_REQUIREMENTS,
      payload: res?.data || [],
    });
  };
};

export const getAllPartnersAction = () => {
  return async function (dispatch) {
    const res = await ApiCallHandler.roamAndFind(
      URLS.LIST_ENTERPRISE_PARTNERS,
      "POST",
      { id: currentEnterprise?.id }
    );
    return dispatch({
      type: LOAD_ENTERPRISE_PARTNERS,
      payload: res?.data || [],
    });
  };
};

export const getExchangeRatesAction = (code) => {
  return async function (dispatch) {
    const res = await ApiCallHandler.getWithoutBody(
      `${URLS.GET_EXCHANGE_RATES}${code || "GHS"}`
    );
    return dispatch({ type: LOAD_EXCHANGE_RATES, payload: res?.rates || {} });
  };
};

export const loadFormDataAction = (payload = { type: PUSH }) => {
  let state = store.getState();
  let toAdd = [];
  if (payload?.type === PUSH) {
    let { type, draft, ...rest } = payload;
    const isItemAlreadyIn = state?.formData?.some((item) => item.idName === rest.idName);
    if (!isItemAlreadyIn) {
      toAdd = [{ ...draft, idName: rest.idName }, ...state.formData];
    } else {
      if (!isEmpty(draft)) {
        let items = state?.formData?.filter((item) => item.idName !== rest.idName);
        toAdd = [{ ...draft, idName: rest.idName }, ...items];
      } else {
        toAdd = [...state.formData];
      }
    }
  } else if (payload?.type === REMOVE) {
    toAdd = state.formData.filter((data) => data.idName !== payload.idName);
    toAdd = [...toAdd];
  } else {
    toAdd = [...state.formData];
  }
  return {
    type: LOAD_FORM_DATA,
    payload: toAdd,
  };
};

export const setTypeDataAction = (payload = {}) => {
  let state = store.getState();
  let toAdd = [];
  if (payload?.type === PUSH) {
    let { type, data, ...rest } = payload;
    // Checking if the id is already in the state
    const isItemAlreadyIn = state?.newlyCreatedTypeData?.some(
      (item) => item.idName === rest.idName
    );
    if (!isItemAlreadyIn) {
      // If not, add it
      toAdd = [{ ...data, idName: rest.idName }, ...state.newlyCreatedTypeData];
    } else {
      // if it is check if the data property is empty.Being empty means that the user hasn't changed the data
      if (!isEmpty(data)) {
        // if it is not empty, then replace the data
        let items = state?.newlyCreatedTypeData?.filter(
          (item) => item.idName !== rest.idName
        );
        toAdd = [{ ...data, idName: rest.idName }, ...items];
      } else {
        // if it is empty, then just return the state
        toAdd = [...state.newlyCreatedTypeData];
      }
    }
  } else if (payload?.type === REMOVE) {
    toAdd = state.newlyCreatedTypeData.filter(
      (data) => data.idName !== payload.idName
    );
    toAdd = [...toAdd];
  } else {
    toAdd = [...state.newlyCreatedTypeData];
  }
  return {
    type: SET_TYPE_DATA,
    payload: toAdd,
  };
};
