import React, { Component } from "react";
import Sidebar from "tools/components/v1/widgets/Sidebar/Sidebar";
import "./PageWrapper.css";
// import Navbar from "./../navbar/Navbar";
import { MENU } from "./values";
import { bindActionCreators } from "redux";
import { toggleSidePane } from "store/actions/actions";
import { connect } from "react-redux";
const SHRINK_SIDE_RATIO = 71;
const EXPANDED_SIDE_RATIO = 271;


class PageWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { isUntouched: true };
    this.handleSidebarStateChange = this.handleSidebarStateChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      state.isUntouched &&
      (state.sideRatio !== props.sideRatio ||
        state.contentRatio !== props.contentRatio)
    ) {
      return {
        sideRatio: props.sideRatio,
        contentRatio: props.contentRatio,
        isUntouched: false,
      };
    }

    return null;
  }
  getContentRatio() {
    var { sideRatio } = this.state;
    sideRatio = sideRatio || 0;
    var contentRatio = 10 - sideRatio;
    return [sideRatio, contentRatio];
  }

  handleSidebarStateChange(state) {
    if (!state.shrink && this.state.sideRatio !== EXPANDED_SIDE_RATIO)
      this.setState({ sideRatio: EXPANDED_SIDE_RATIO });
    else if (state.shrink && this.state.sideRatio !== SHRINK_SIDE_RATIO)
      this.setState({ sideRatio: SHRINK_SIDE_RATIO });
  }

  render() {
    const { sideRatio } = this.state;
    const { sidePane } = this.props;
    return (
      <div
        className="root-wrapper"
        style={{
          "--side-ratio": `${sideRatio}px`,
        }}
      >
        <div>
          <Sidebar
            menu={MENU}
            onShrinkBtnClick={() =>
              this.setState({ sideRatio: SHRINK_SIDE_RATIO })
            }
            onStateChange={this.handleSidebarStateChange}
          />
        </div>
        <div className="children-container">
          {sidePane?.on && (
            <div className="side-pane elevate-float animate-side-pane"></div>
          )}
          {/* <div>
            <Navbar /> 
          </div> */}
          <div style={{marginTop:'2rem'}}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

PageWrapper.defaultProps = {
  sideRatio: SHRINK_SIDE_RATIO,
};

const mapStateToProps = (store) => {
  return {
    sidePane: store.sidePane,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSidePane,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(PageWrapper);
