import React, { Component } from "react";
import "./CreateAssetSetupAndConfig.css";
import TabView from "../../../tools/components/v1/widgets/TabView/TabView";
import CreateAssetIncomeType from "../income type/CreateAssetIncomeType";
import CreateAssetExpenseType from "../expense type/CreateAssetExpenseType";
import CreateAssetType from "../asset type/CreateAssetType";



export default class AssetSetupAndConfig extends Component {
  constructor(props) {
    super(props);
    this.state = { showToast: false, changer: null};
    this.handleOnMount = this.handleOnMount.bind(this)
  }

  that = this

  

  static getDerivedStateFromProps(props, state) {
    return { defaultTab: props.match.params?.page };
  }

  activeTabFromURL() {
    const { params } = this.props.match;
    return params?.page;
  }

  handleOnMount(event){
  }


  TAB_DATA = [
    {
      name: "Asset Type",
      id: "create-asset-type",
      component: <CreateAssetType />,
    },
    {
      name: "Asset Income Type",
      id: "create-income-type",
      component: <CreateAssetIncomeType switchTabs={(changer) =>{this.state.changer(changer)}} />,
    },
    {
      name: "Asset Expense Type",
      id: "create-expense-type",
      component: <CreateAssetExpenseType />,
    }
  ];

  render() {

    return (
      <div style={{ padding: 40 }}>
        <div style={{ width: "90%", margin: "auto" }}>
          <TabView
            data={this.TAB_DATA}
            contentAreaStyle={{ paddingTop: 30 }}
            defaultTab={this.activeTabFromURL()}
            onMount={(changer) => this.setState({ changer })}
          />
        </div>
      </div>
    );

  }
}
