import React, { Component } from "react";
import "./dashboard.css";
import Table from "tools/components/v1/widgets/Table/Table";
import Title from "components/title/Title";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCurrentEnterpriseDashboardContentsAction } from "../../store/actions/actions";
import { withRouter } from "react-router";
import {
  convertAmountWithCurrencyRate,
  convertNumberToShortForm,
} from "shared/js/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import NoItems from "../../tools/components/v1/widgets/NoItems/NoItems";
import {
  faMoneyBillAlt,
  faUsers,
  faHandshake,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import Verified from "../../shared/components/verified/Verified";
import {
  getMyCountryDetails,
  capitalizeMe,
  isEmpty,
} from "../../shared/js/utils";
import { VERIFCATION_STATUSES } from "shared/js/typeConstants";

class Dashboard extends Component {
  renderThumContent(arr = []) {
    return arr.map(() => {
      return {};
    });
  }

  async componentDidMount() {
    await this.props.getDashboardDetails();
  }

  isEnterprisePartner(partnership) {
    if (partnership?.partner_type === "ENTERPRISE") {
      return true;
    } else return false;
  }
  prepareTableData = (data) => {
    if (data?.length > 0) {
      return data
        ?.slice(0, 5)
        ?.map((item) => [
          <p>
            {this.isEnterprisePartner(item)
              ? item?.enterprise?.name
              : item?.user?.first_name}
          </p>,
          <p>{item?.opportunity?.name}</p>,
          <p>
            {this.isEnterprisePartner(item)
              ? item?.enterprise?.email
              : item?.user?.email}
          </p>,
          <p>{item?.partner_type || "INDIVIDUAL"}</p>,
          <p>{item?.shares}</p>,
        ]);
    }
    return [];
  };

  dashbaordStats = () => {
    let { enterpriseDashboardContents: info } = this.props;
    let data = {
      assets:
        info && info?.assets && info?.assets[0] ? info.assets[0].value : 0,
      opportunities:
        info && info?.investments && info?.investments[0]
          ? info.investments[0].value
          : 0,
      investors:
        info && info?.investments && info?.investments[1]
          ? info.investments[1].value
          : 0,
      expenses:
        info && info?.total_expenses && info?.total_expenses[0]
          ? info.total_expenses[0].value
          : 0,
    };

    return data;
  };

  showNewCard = () => {
    let data = this.dashbaordStats();
    let profits = parseFloat(this.getProfit() || 0);

    return (
      <div id="figures-main-container">
        <div
          id="figure-container"
          className="elevate-float card-me"
          style={{ backgroundColor: "#E67D21" }}
          onClick={() => this.props.history.push("/opportunities")}
        >
          <div id="avatar" style={{ backgroundColor: "#D35400" }}>
            <FontAwesomeIcon
              icon={faHandshake}
              style={{ fontSize: 30, color: "white" }}
            />
          </div>
          <div id="figures-details">
            <span>Opportunities</span>
            <span>{data?.opportunities}</span>
          </div>
        </div>
        <div
          id="figure-container"
          className="elevate-float card-me"
          style={{ backgroundColor: "#7D3C98" }}
          onClick={() => this.props.history.push("/partners")}
        >
          <div id="avatar" style={{ backgroundColor: "#5B2C6F" }}>
            <FontAwesomeIcon
              icon={faUsers}
              style={{ fontSize: 30, color: "white" }}
            />
          </div>
          <div id="figures-details">
            <span>Partners</span>
            <span>{data?.investors}</span>
          </div>
        </div>
        <div
          id="figure-container"
          className="elevate-float card-me"
          style={{ backgroundColor: `${profits < 0 ? "#CB4335" : "#28a745"}` }}
        >
          <div
            id="avatar"
            style={{ backgroundColor: `${profits < 0 ? "maroon" : "#1E8449"}` }}
          >
            <FontAwesomeIcon
              icon={faMoneyBillAlt}
              style={{ fontSize: 30, color: "white" }}
            />
          </div>
          <div id="figures-details">
            <span>Profit</span>
            <span>
              {convertNumberToShortForm(profits?.toFixed(2)) ||
                parseFloat(0.0)?.toFixed(2)}
            </span>
          </div>
        </div>
      </div>
    );
  };

  showVerificationNotification = () => {
    let { currentEnterprise } = this.props;

    console.log("currentEnterprise", currentEnterprise);

    if (currentEnterprise?.compliance_documents?.status === VERIFCATION_STATUSES.VERIFIED) {
      return null;
    } else {
      return (
        <div className="elevate-float set-payment-pref-container dashboard-payment-card">
          <div style={{ display: "flex" }}>
            <div id="info-icon-container">
              <FontAwesomeIcon icon={faInfoCircle} />
            </div>

            <div id="notification-body-container">
              <span>Enterprise Verification</span>
              {isEmpty(currentEnterprise?.compliance_documents) ? (
                <p>
                  Dear Admin, your enterprise has not being verified and hence
                  investor will no be able to see your opportunities. Please{" "}
                  <Link to="/enterprise/verification"> click</Link> to to start
                  the verification process.{" "}
                </p>
              ) : currentEnterprise?.compliance_documents?.status ===
                VERIFCATION_STATUSES.REJECTED ? (
                <p>
                  Dear Admin, your enterprise verification has been rejected.
                  Here is message from our verification team:{" "}
                  <br />
                  {
                    currentEnterprise?.compliance_documents?.failure_message
                  } <br />
                  <br />
                  Please <Link to="/enterprise/verification"> click</Link> to
                  update your verification information. <br />
                  Thank you.
                </p>
              ) : (
                <p>
                  Keep calm, we are verifying your enterprise. Once the process
                  is complete we will notify you. If you wish to edit your
                  verification info,
                  <Link to="/enterprise/verification"> Click here</Link> .{" "}
                  <br />
                  Thank you.
                </p>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  getProfit = () => {
    let total = this.props.enterpriseDashboardContents?.all_incomes;
    let { enterpriseDashboardContents: info } = this.props;
    const expenses =
      info && info?.total_expenses && info?.total_expenses[0]
        ? info.total_expenses[0].value
        : 0;
    let profit = parseFloat(total) - parseFloat(expenses);
    return profit;
  };

  convertMoneyWithCurrency = (amount) => {
    let data = {
      amount: amount,
      code: this.props.pref?.currency || "GHS",
      rates: this.props.exchangeRates,
    };
    let converted = convertAmountWithCurrencyRate(data);
    return converted;
  };

  render() {
    let { currentEnterprise, enterpriseDashboardContents } = this.props;
    let totalIncome = parseFloat(enterpriseDashboardContents?.all_incomes || 0);
    let country = getMyCountryDetails("currency", currentEnterprise?.currency);
    return (
      <>
        <div>
          <div className="title-area">
            <h1 id="greeting-msg-h1">
              <p>
                Welcome, {capitalizeMe(currentEnterprise?.name) || ""}
                {currentEnterprise?.is_verified ? (
                  <Verified bg={"green"} height={"21px"} width={"21px"} />
                ) : null}
              </p>

              {/* {currentEnterprise?.compliance_documents?.status ===
              "Approved" ? (
                <FontAwesomeIcon icon={faCheckCircle} />
              ) : (
                <></>
              )} */}
            </h1>

            <div className="everyday-flex right-of-title-box dashboard-stats-container">
              <h6>Total Revenue</h6>
              <h1>
                {country?.currency?.symbol}{" "}
                {convertNumberToShortForm(totalIncome?.toFixed(2)) ||
                  parseFloat(0.0)?.toFixed(2)}
                <span>{country?.currency?.code} </span>
              </h1>

              <h6 style={{ color: "maroon" }}>Expenses</h6>
              <h2 style={{ color: "maroon" }}>
                {country?.currency?.symbol}{" "}
                {convertNumberToShortForm(
                  parseFloat(this.dashbaordStats()?.expenses)?.toFixed(2)
                ) || parseFloat(0.0)?.toFixed(2)}
                <span>{country?.currency?.code} </span>
              </h2>
            </div>
          </div>

          {/* ----------------- CARDS AREA ------------- */}
          {this.showVerificationNotification()}
          {this.showNewCard()}

          <div
            className="dashboard-team-box elevate-float"
            style={{ padding: 40, borderRadius: 10 }}
          >
            <Title>Top Partners</Title>
            {this.props.enterpriseDashboardContents?.top_ten_investors ===
            null ? (
              <div>
                <NoItems text="You Do Not Have Partners or Opportunities " />
              </div>
            ) : (
              <Table
                columns={[
                  "Name",
                  "Opportunity",
                  "Partner Email",
                  "Type",
                  "Shares",
                ]}
                data={this.prepareTableData(
                  this.props.enterpriseDashboardContents?.top_ten_investors
                )}
                theme={Table.CHECKERED}
                hoverAnimation={false}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const allEnterprises = state.enterprises;

  const enterpriseLoc = localStorage.currentEnterprise
    ? JSON.parse(localStorage.currentEnterprise)
    : { name: "" };

  const currentEnterprise = allEnterprises?.find(
    (item) => item.id === enterpriseLoc?.id
  );
  return {
    currentEnterprise,
    enterpriseDashboardContents: state.enterpriseDashboardContents,
    investments: state?.investments,
    pref: currentEnterprise?.preferences,
    exchangeRates: state.exchangeRates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDashboardDetails: () => getCurrentEnterpriseDashboardContentsAction(),
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
