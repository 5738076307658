import React from "react";
import { useDispatch } from "react-redux";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import { toggleToastAction } from "store/actions/actions";
import './T&C.css'
import { encodeUserDataForCreateEnterprise } from '../../shared/js/utils';



export default function TermAndConditions(props) {

    const dispatch = useDispatch(null)

    const acceptTerms = ()=>{
        const user = localStorage.userDeta ?  JSON.parse(localStorage.getItem('userDeta')) : {};
          ApiCallHandler.send(URLS.UPDATE_USERS, "POST", {
            id: user?.id,
            has_accepted_tcs:"True"
            
        }).then(
            (data) => {
                if (data.success) {
                  localStorage.userDeta= JSON.stringify(data?.data[0]);
                    encodeUserDataForCreateEnterprise({
                      email: user.email,
                      signIn: true,
                    });
                }
                else{
                    dispatch(toggleToastAction({
                        showToast: true,
                        message: "An error occurred. Try again later.",
                        type: "failure",
                      }))
                }

            }
         )
    }
    const rejectTerms = ()=>{
        window.confirm("Are you sure you want to reject the terms and conditions?") && props.history.push("/");
    }
  return (
    <div className="tc-root">
      <div className="elevate-floa content-container">
        <div className="header-container">
          <span>AGREEMENT</span>
          <p>Terms Of Service</p>
        </div>

        <div className="tc-body">
          <p>
            Read our Terms of Service <a href="https://docs.google.com/document/d/1SyROLHdxjGokwS5AIRemEUtca0GOHylzOidWwz5d9SU/edit?usp=sharing">here</a> 
          </p>
        </div>

        <div className="tc-btn-container">
            <div className="elevate-float reject btn-item" onClick={rejectTerms}>
                <p>Decline</p>
            </div>
            <div className="elevate-float accept-item btn-item" onClick={acceptTerms}>
                <p>Accept </p>
            </div>
        </div>
      </div>
    </div>
  );
}
