import React, {Component} from "react";
import TabView from "tools/components/v1/widgets/TabView/TabView";
import SingleInvestmentInvitations from "./SingleInvestmentInvitations";
import SingleInvestmentPartnerships from "./SingleInvestmentPartnerships";


export default class InvestorsWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToast: false,
      changer: "investors-for-opportunity",
      mode: "create",
    };
  }

  TAB_DATA = [
    {
      name: "All Parnters",
      id: "investors-for-opportunity",
      component: (
        <SingleInvestmentPartnerships
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Parnter Invitations",
      id: "invited-investors-for-opporunity",
      component: (
        <SingleInvestmentInvitations
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
  ];

  render() {
    return (
      <div id="asset-setup-and-config-container" style={{ paddingTop: 40 }}>
        <div style={{ width: "90%", margin: "auto" }}>
          <TabView
            data={this.TAB_DATA}
            contentAreaStyle={{ paddingTop: 30 }}
            defaultTab={this.state.changer}
            onMount={(changer) => this.setState({ changer })}
          />
        </div>
      </div>
    );
  }
}
