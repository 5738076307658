import React, { Component } from "react";
import "./Stepper.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default class StepperHeaders extends Component {
  constructor() {
    super();
    this.state = {
      activeStepIndex: 1,
      steps: [],

      updateSteps: this.updateSteps.bind(this),
    };
  }

  componentDidMount() {
    this.setState({ steps: this.props?.steps });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.steps !== prevState.steps) {
      const steps = prevState.updateSteps(
        nextProps.steps,
        nextProps.activeStepIndex
      );

      return { steps };
    } else if (nextProps.activeStepIndex !== prevState.activeStepIndex) {
      const steps = prevState.updateSteps(
        nextProps.steps,
        nextProps.activeStepIndex
      );

      return { steps };
    }
    return [];
  }

  renderStepperHeaders = () => {
    let headers = this.state.steps;
    return headers.map((header, index) => {
      return (
        <div
          style={{ position: "relative", height: "80px" }}
          onClick={() => this.props.updateStepsOnHeaderClick(index)}
        >
          {index === 0 ? null : (
            <div
              className={`divider-line ${header.selected ? "active" : ""} ${
                header.completed ? "completed" : ""
              }`}
            ></div>
          )}
          <div className="stepper-header" key={index}>
            <div
              className={`step-status ${header.selected ? "active" : ""} ${
                header.completed ? "completed" : ""
              }`}
            ></div>
            <div className={`stepper-header-text`}>{header?.name}</div>
          </div>
        </div>
      );
    });
  };

  renderHorizontalHeader = () => {
    let headers = this.state.steps;
    return headers.map((step, index) => {
      return (
        <div className="step-wrapper" key={index}>
          <div
            className={`center-me step-number elevate-float ${
              step.selected ? "step-number-active" : null
            } 
            ${step.completed ? "step-number-completed" : null}`}
            onClick={() => this.props.updateStepsOnHeaderClick(index)}
          >
            {step.completed ? (
              <span>
                <FontAwesomeIcon icon={faCheck} />
              </span>
            ) : step?.icon ?(
              <FontAwesomeIcon icon={step.icon} size="lg" />
            ): null}
          </div>
          <div
            className={`step-title ${
              step.selected ? "step-title-active" : null
            }`}
          >
            {step.name}
          </div>
          {index + 1 === headers.length ? null : (
            <div className="divider-line-horizontal"></div>
          )}
        </div>
      );
    });
  };

  updateSteps = (steps, activeStepIndex) => {
    const newStep = [...steps];
    let stepCounter = 0;

    while (stepCounter < newStep.length) {
      if (activeStepIndex === stepCounter ) {
        newStep[stepCounter] = {
          ...newStep[stepCounter],
          completed: false,
          selected: true,
        };
        stepCounter++;
      } else if (stepCounter < activeStepIndex) {
        newStep[stepCounter] = {
          ...newStep[stepCounter],
          completed: true,
          selected: false,
        };
        stepCounter++;
      } else {
        newStep[stepCounter] = {
          ...newStep[stepCounter],
          completed: false,
          selected: false,
        };
        stepCounter++;
      }
    }
    return newStep;
  };
  render() {
    let {variant} = this.props
    return (
      <div
        className={`${
          variant === "horizontal"
            ? "stepper-headers-container"
            : "stepper-headers-container-vertical"
        }`}
      >
        {variant === "horizontal"
          ? this.renderHorizontalHeader()
          : this.renderStepperHeaders()}
      </div>
    );
  }
}
