import React, { Component } from "react";
import FormGenerator from "../../../../tools/components/v1/FormGenerator/FormGenerator";
import Fields from "../../../../tools/components/v1/FormGenerator/Fields";
import URLS from "../../../../shared/js/urls";
import ApiCallHandler from "../../../../shared/js/ApiCallHandler";

import { bindActionCreators } from "redux";
import {
  toggleModalAction,
  toggleToastAction,
  editMutationAction,
} from "store/actions/actions";
import { connect } from "react-redux";
import { updateTypeDataInRedux, createDraftWithParentFormData, findAndReturnDraft } from '../../../../shared/js/globalReduxInteractions';
import { hasEmptyFields } from '../../../../shared/js/utils';
import { getRandomStringKey } from 'tools/components/v1/shared/js/utils';

class CreateOpportunityExpense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentData: {
        expense_type: "",
        amount: "",
        transaction_date: "",
        reference_id: "",
        receipt: "",
        notes: "",
      },
      formData: {},
      key: Math.random(),
    };
  }

  componentDidMount() {
    let { editData } = this.props;
    if (editData) {
      this.setState({ currentData: editData });
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.editData) {
      return {
        currentData: hasEmptyFields(state.currentData)
          ? { ...props.editData, ...{} }
          : { ...props.editData, ...state.currentData },
      };
    }
    return null;
  }
  handleCreate = async (data, editData, reset) => {
    let { currentInvestment } = this.props;
    let res;
    let toSend = {
      ...data,
      amount: parseFloat(data.amount),
      expense_type: data && data.expense_type ? data.expense_type.id : "",
      opportunity: currentInvestment.id,
    };
    if (data?.receipt?.data?.file?.data) {
      toSend.receipt = data.receipt.data.file.data;
    } else {
      delete toSend.receipt;
    }

    if (!editData?.id) {
      res = await ApiCallHandler.send(URLS.CREATE_OPPORTUNITY_EXPENSE, "POST", {
        ...toSend,
      });
    } else {
      let newToSend = { ...toSend, id: editData?.id };
      res = await ApiCallHandler.send(
        URLS.EDIT_OPPORTUNITY_EXPENSE,
        "POST",
        newToSend
      );
    }
    if (res && res.success) {
      this.updateReduxStore(editData?.id, res);
      this.props.toggleToast({
        show: true,
        message: !editData?.id
          ? "Expense created successfully"
          : "Expense updated successfully",
        type: "success",
      });
      reset();
      this.props.toggleModal({ show: false });
    } else {
      this.props.toggleToast({
        showToast: true,
        message: "An error occurred. Try again",
        type: "failure",
      });
      this.props.toggleModal({ show: false });
    }
  };

  updateReduxStore = (is_edit, res) => {
    let { currentInvestment } = this.props;
    let newExpenses;

    if (!is_edit) {
      newExpenses =
        currentInvestment?.expenses?.length ||
        currentInvestment?.expenses !== null
          ? [...currentInvestment?.expenses, res?.data]
          : [res?.data];
    } else {
      newExpenses = currentInvestment?.expenses?.filter(
        (item) => item?.id !== res?.data[0].id
      );
      newExpenses?.push(res?.data[0]);
    }
    currentInvestment.expenses = newExpenses;
    this.props.updateItemInredux(this.props.investments, currentInvestment);
  };

  close = () => {
    this.props.toggleModal({ show: false });
  };
  updateFields = (res) => {
    let { currentData } = this.state;
    let data = currentData;
    let draft = findAndReturnDraft(res?.draftId) || {};
    data = { ...data, ...draft };
    data.expense_type = res?.data;
    this.setState({ currentData: data, key: getRandomStringKey() });
  };

  render() {
    let {
      expenseTypes,
      editData,
    } = this.props;
    let getFieldValue = (field) => {
      let value = this.state.currentData[field] || "";
      return value;
    };
    return (
      <div key={this.state.key}>
        <FormGenerator
          elevation={0}
          fields={[
            {
              data: expenseTypes || [],
              fieldType: Fields.DROPDOWN,
              label: "Choose The Expense Type",
              name: "expense_type",
              placeholder: "Select Expense Type",
              required: true,
              type: "full",
              value: getFieldValue("expense_type"),
              labelExtractor: (item) => item.name,
              valueExtractor: (item) => item.id,
              child: {
                fields: [
                  {
                    fieldType: Fields.INPUT,
                    label: "Expense Type Name",
                    name: "name",
                    placeholder: "Enter Expense Type Name",
                    required: true,
                    value: "",
                  },
                ],
                metaData: {
                  endpoint: URLS.CREATE_NEW_OPPORTUNITY_EXPENSE_TYPE,
                  additionalData: {
                    opportunity: this.props.currentInvestment?.id,
                  },
                  onSuccess: (res) => {
                    updateTypeDataInRedux(res?.data, "expense_types");
                    this.updateFields(res);
                  },
                  createDraft: (id) =>
                    createDraftWithParentFormData(this.state.formData, id),
                },
              },
            },
            {
              fieldType: Fields.INPUT,
              label: "Set Amount",
              name: "amount",
              placeholder: "How Much Was The Income ? Eg. 400",
              required: true,
              value: getFieldValue("amount") ||0,
            },
            {
              fieldType: Fields.DATE,
              label: "Transaction date",
              name: "transaction_date",
              placeholder: "Select Date",
              required: true,
              value: getFieldValue("transaction_date"),
            },
            {
              fieldType: Fields.INPUT,
              label: "Enter Ref. Id",
              name: "reference_id",
              placeholder: "Enter Ref. Id",
              required: true,
              value: getFieldValue("reference_id"),
            },
            {
              fieldType: Fields.FILE,
              label: "Receipt",
              name: "receipt",
              placeholder: "Upload Receipt",
              required: false,
              value: getFieldValue("receipt"),
            },
            {
              fieldType: Fields.TEXTAREA,
              label: "Notes",
              name: "description",
              placeholder: "Short Notes Here...",
              value: getFieldValue("description"),
            },
          ]}
          onSubmit={(data, reset) => this.handleCreate(data, editData, reset)}
          formSubmitBtnText={!editData?.id ? "create Expense" : "Save Changes"}
          subtitle=""
          title=""
          onStateChange={(data) => this.setState({ formData: data })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownerProps) => {
  const currentInvestment = state.investments?.find(
    (x) => x.id === ownerProps?.currentInvestment
  );
  return {
    expenseTypes: currentInvestment?.expense_types,
    currentInvestment,
    investments: state.investments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      toggleModal: toggleModalAction,
      updateItemInredux: (current_data, new_data) =>
        dispatch(editMutationAction("INVESTMENT", current_data, new_data)),
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOpportunityExpense);
