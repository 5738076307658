import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import './ImagePreview.css'
import { toggleImagePreviewAction } from "store/actions/actions";
class ImagePreview extends Component {
  close = () => {
    this.props.toggleImagePreview({
      show: false,
      image: null,
    });
  };

  render() {
    return (
      <div id="image-preview-root" onClick={() => this.close()}>
        <img src={this.props?.image} alt="" />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    imagePreviewProps: state.imagePreviewProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleImagePreview: toggleImagePreviewAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ImagePreview);
