import React, { Component } from 'react'
import { connect } from 'react-redux'
import FormGenerator from 'tools/components/v1/FormGenerator/FormGenerator'

class BasicInfo extends Component {
    
    render() {
        const { editData } = this.props
        const formData = [
          {
            fieldType: FormGenerator.Fields.INPUT,
            label: "What would you like to call this opportunity?",
            name: "name",
            placeholder: "Investment name...",
            required: true,
            value: editData?.id ? editData?.name : "",
          },
          {
            fieldType: FormGenerator.Fields.DATE,
            label: "When does this opportunity close?",
            name: "close_date",
            placeholder: "Enter close date...",
            required: true,
            value: editData?.id ? editData?.close_date?.split("T")[0] : "",
          },
          {
            fieldType: FormGenerator.Fields.INCREMENT,
            label: "Period This Opportunity Will Run In Months",
            value:
              editData?.opportunity_running_period_in_months ||
              12,
            name: "opportunity_running_period_in_months",
            elevation: 0,
            helpText: "How many months will this opportunity run for?",
          },
          {
            fieldType: FormGenerator.Fields.RICHTEXT,
            label: "What can you say about this opportunity?",
            name: "about",
            placeholder:
              "Brief description of what this investment is about...",
            required: true,
            value: editData?.id ? editData?.about : "",
          },
        ];
        return (
            <div>
                <FormGenerator
                    elevation={0}
                    subtitle=""
                    title=""
                    fields={formData}
                    hideSubmitBtn={true}
                    onStateChange={this.props.onStateChange ? this.props.onStateChange : null}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const currentInvestment = state.investments.find(item=>item?.id === localStorage.invEDT)|| {}
    return {
     editData:currentInvestment
    }
}

export default connect(mapStateToProps, null)(BasicInfo)