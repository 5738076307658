import React, { Component } from "react";
import FormGenerator from "../../../tools/components/v1/FormGenerator/FormGenerator";
import Fields from "../../../tools/components/v1/FormGenerator/Fields";
import Header from "components/header/Header";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import URLS from "../../../shared/js/urls";
import Toast from "../../../tools/components/v1/widgets/Toast/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faArchive, faPlus } from "@fortawesome/free-solid-svg-icons";
import Table from "tools/components/v1/widgets/Table/Table";
import Title from "components/title/Title";
import { connect } from "react-redux";
import {
  getAssetTypesAction,
  toggleToastAction,
} from "../../../store/actions/actions";
import "./CreateAssetType.css";

class CreateAssetType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToast: false,
      msg: "",
      toast_type: "",
      editMode: false,
      toEdit: {},
      show: false,
    };
    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow = () => this.setState({ show: !this.state.show });

  handleDeleteAssetType = async (item) => {
    if (this.state.showToast) {
      this.setState({ showToast: false });
    }
    let res = await ApiCallHandler.roamAndFind(
      URLS.ARCHIVE_ASSETS_TYPES,
      "POST",
      { id: item.id }
    );
    if (res && res.success) {
      this.setState({
        showToast: true,
        msg: "Asset Type successfully deleted",
        toast_type: "success",
      });
      this.props.getAllAssetTypes();
    } else {
      this.setState({
        showToast: true,
        msg: "Error deleting the asset type. Try again",
        toast_type: "failure",
      });
    }
  };

  handleEditAssetType = (item) => {
    this.setState({ editMode: true, toEdit: item, show: true });
    let topOfPage = document.getElementById("create-asset-type-container");
    topOfPage.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  prepareTableData = () => {
    let { assetTypes } = this.props;
    let tableData = [];
    if (assetTypes?.length > 0) {
      // TODO: Tweak table component to give access to manipulation of an entire row, instead of cells
      assetTypes.forEach((item) => {
        tableData.push([
          <p>{item.name}</p>,
          <p></p>,
          <p></p>,
          <p></p>,
          <p></p>,
          <div className="actions-container">
            <div id="edit" onClick={() => this.handleEditAssetType(item)}>
              <FontAwesomeIcon icon={faPen} color={"white"} size={"sm"} />
            </div>
            <div id="delete" onClick={() => this.handleDeleteAssetType(item)}>
              <FontAwesomeIcon icon={faArchive} color={"white"} size={"sm"} />
            </div>
          </div>,
        ]);
      });
    }
    return tableData;
  };

  showAllAssetTypes = () => {
    let { assetTypes } = this.props;
    return (
      <div>
        <div style={{ borderRadius: 10 }}>
          <Title>Asset Types</Title>

          {assetTypes && assetTypes.length ? (
            <Table
              columns={["Name", " ", "  ", "  ", "\t", "Actions"]}
              data={this.prepareTableData()}
              hoverAnimation={false}
            />
          ) : (
            <div id="no-items-container">
              <h3>No Asset Types</h3>
            </div>
          )}
        </div>
      </div>
    );
  };

  handleCreate = async (data, reset) => {
    let { toEdit, editMode, showToast } = this.state;
    let ent = localStorage.currentEnterprise ? JSON.parse(localStorage.currentEnterprise) : {}
    if (showToast) {
      this.setState({ showToast: false });
    }
    let res;
    if (editMode) {
      res = await ApiCallHandler.send(URLS.UPDATE_ASSETS_TYPES, "POST", {
        id: toEdit?.id,
        name: data.name
      });
    } else {
      
      res = await ApiCallHandler.send(URLS.CREATE_ASSETS_TYPES, "POST", {
        name: data.name,
        enterprise: ent?.id
      });
    }

    if (res && res.success) {
      const msg = `Asset Type ${editMode ? "updated" : "created"} successfully`;
      this.setState({
        msg: msg,
        toast_type: "success",
        toEdit: {},
        editMode: false,
      });
      this.props.createToastMessage({
        show: true,
        message: msg,
        type: "success",
      });
      reset();
      this.toggleShow();
      this.props.getAllAssetTypes();
    } else {
      const msg = `Error ${
        editMode ? "updating" : "creating"
      } the asset type. Try again`;

      this.setState({
        msg: msg,
        toast_type: "failure",
      });
      this.props.createToastMessage({
        show: true,
        message: msg,
        type: "failure",
      });
    }
  };
  showFieldValue = () => {
    let { editMode, toEdit } = this.state;
    let value = editMode ? toEdit.name : "";
    return value;
  };

  renderForm = (editMode, status) => {
    if (status)
      return (
        <div className="scale-and-fade-in">
          <div style={{ marginLeft: 50 }}></div>
          <Header
            title={
              !editMode
                ? "Create Asset Type"
                : `Edit "${this.state.toEdit.name}" `
            }
            subtitle="Create all your asset types here"
          />
          <FormGenerator
            elevation={0}
            fields={[
              {
                fieldType: Fields.INPUT,
                label: "Enter asset type name",
                name: "name",
                placeholder: "Asset Asset Type Name",
                required: true,
                defaultValue: this.showFieldValue(),
              },
            ]}
            onSubmit={this.handleCreate}
            formSubmitBtnText={!editMode ? "Create" : "Save Changes"}
            subtitle=""
            title=""
            hasCancel
            cancel={(reset) => {
              reset();
              this.toggleShow();
            }}
          />
        </div>
      );

    return (
      <div>
        <Title className="text-toggler" onClick={this.toggleShow}>
          Create new asset type <FontAwesomeIcon icon={faPlus} />
        </Title>
      </div>
    );
  };

  render() {
    let { msg, showToast, toast_type, editMode } = this.state;
    return (
      <div id="create-asset-type-container">
        {this.renderForm(editMode, this.state.show)}
        {this.showAllAssetTypes()}
        {showToast ? (
          <Toast
            type={toast_type}
            position="bottom"
            message={msg}
            show={showToast}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assetTypes: state.assetTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllAssetTypes: () => dispatch(getAssetTypesAction()),
    createToastMessage: (obj) => dispatch(toggleToastAction(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAssetType);
