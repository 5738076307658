import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button/Button";

export default class Modal extends Component {
  static SMALL = "small";
  static MEDIUM = "medium";
  static LARGE = "large";

  close() {
    const { close } = this.props;
    if (close) close();
  }
  render() {
    const {
      modalRootStyle,
      modalContainerStyle,
      className,
      size,
      showClose,
      showOverlay,
      title,
      okay,
      cancel,
      themeColor,
      closeAfterOkay,
    } = this.props;
    return (
      <div style={{ position: "relative" }}>
        <div
          className={`modal-root ${className ?? ""}`}
          style={{
            ...modalRootStyle,
            backgroundColor: `${
              !showOverlay ? "transparent" : "rgb(13 12 14 / 58%)"
            }`,
            display: `flex`,
          }}
        >
          <div
            className={`modal-container modal-container-${size} elevate-1`}
            style={{ ...modalContainerStyle }}
          >
            <div
              className="modal-header"
              style={themeColor ? { background: themeColor } : {}}
            >
              <div className="model-title">{title ?? "Modal Title"}</div>
              <div
                className="modal-close-btn"
                style={{ display: `${!showClose ? "none" : "inline-flex"}` }}
                onClick={(e) => this.close()}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
            <div className="modal-content">
              {this.props.children || "Modal Content"}
            </div>

            {(okay || cancel) && (
              <div className="modal-btns">
                {cancel && (
                  <Button
                    size={Button.SMALL}
                    theme={Button.DANGER}
                    style={{ marginRight: 6 }}
                    onClick={() => {
                      if (cancel?.function) return cancel.function();
                      this.close();
                    }}
                  >
                    {cancel?.text || "Cancel"}
                  </Button>
                )}

                {okay && (
                  <Button
                    size={Button.SMALL}
                    theme={Button.SUCCESS}
                    onClick={() => {
                      if (okay?.function) okay.function();
                      if (closeAfterOkay) this.close();
                    }}
                  >
                    {okay?.text || "Okay"}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  /** additional custom class name */
  className: PropTypes.string,

  /** modal size */
  size: PropTypes.string,
  /** hide or show close button */
  showClose: PropTypes.bool,
  /** show or hide overlay */
  showOverlay: PropTypes.bool,
  /** title of modal */
  title: PropTypes.string,
  /** modal content */
  content: PropTypes.string,

  /** additional custom styling for root of modal */
  modalRootStyle: PropTypes.object,
  /** additional custom styling for modal container */
  modalContainerStyle: PropTypes.object,

  /**
   * An object with
   * @param text custom name if "okay" is not preferred
   * @param function function that should be fired on click
   */
  okay: PropTypes.object,
  /**
   * An object with
   * @param text custom name if "okay" is not preferred
   * @param function function that should be fired on click. Defaults to closing the modal if no function is
   * provided
   */
  cancel: PropTypes.oneOfType(PropTypes.object, PropTypes.bool),

  /**
   * External function to close the modal
   */
  close: PropTypes.func,

  /**
   * Sets whether the modal shoudl be dismissed after the okay button is clicked.
   */
  closeAfterOkay: PropTypes.bool,
};

Modal.defaultProps = {
  size: Modal.SMALL,
  showClose: true,
  showOverlay: true,
  title: "Title this via props",
  content: "Put your content here via props",
  modalRootStyle: {},
  modalContainerStyle: {},
  closeAfterOkay: true,
};
