export const TAB_DATA = [
  {
    name: "Page 1",
    id: "page1",
    component: <h1>This is page 1</h1>,
  },
  {
    name: "Page 2",
    id: "page2",
    component: <h1>This is page 2</h1>,
  },
  {
    name: "Page 3",
    id: "page3",
    component: <h1>This is page 3</h1>,
  },
];
