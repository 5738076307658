import reduxThunk from "redux-thunk";
import logger from "redux-logger";
import reducers from "./reducers/combined";
import { createStore, applyMiddleware, compose } from "redux";

const store = createStore(
  reducers,

  compose(
    applyMiddleware(reduxThunk, logger)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;
