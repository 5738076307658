import React, { Component } from "react";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import FormGenerator from "tools/components/v1/FormGenerator/FormGenerator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  toggleToastAction,
  setEditSetterAction,
  editMutationAction,
} from "store/actions/actions";
import Stepper from "tools/components/v1/widgets/Stepper/Stepper";
import { faCubes, faEye, faInfo } from "@fortawesome/free-solid-svg-icons";
import BasicInfo from "./creation steps/BasicInfo";
import SharesInfo from "./creation steps/SharesInfo";
import LiveInfo from "./creation steps/LiveInfo";

class CreateInvestments extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      data: {},
    };
  }

  updateReduxState = (isEdit, json) => {
    let { updateRedux, investments } = this.props;
    if (!isEdit) {
      updateRedux(investments || [], json?.data);
    } else {
      updateRedux(investments, json?.data[0]);
    }
  };

  async createBackendInv(data) {
    const json = await ApiCallHandler.send(
      URLS.CREATE_OPPORTUNITIES,
      ApiCallHandler.POST,
      data
    );
    return json;
  }

  async editBackendInv(data) {
    const json = await ApiCallHandler.send(
      URLS.UPDATE_OPPORTUNITIES,
      ApiCallHandler.POST,
      data
    );
    return json;
  }

  async onSubmit(data) {
    if (!data) return;
    let { currentInvestment } = this.props;
    data = {
      ...data,
      enterprise: JSON.parse(localStorage.currentEnterprise).id,
    };
    if (data?.has_started) {
      data.has_started = "True";
    }
    data.is_archived = FormGenerator.getBoolRepresentation(data?.is_archived);
    data.is_published = FormGenerator.getBoolRepresentation(data?.is_published);
    let json = {};
    if (!currentInvestment?.id) {
      json = await this.createBackendInv(data);
    } else {
      data = { ...data, id: currentInvestment?.id };
      json = await this.editBackendInv(data);
    }
    if (json?.success) {
      this.updateReduxState(currentInvestment?.id, json);
      this.props.history.goBack();
    }

    if (json?.success === false) {
      return this.props.toggleToast({
        show: true,
        message: json?.error,
        type: "failure",
      });
    }
  }

  collectFormData = (data) => {
    this.setState({
      data: {
        ...this.state.data,
        ...data,
      },
    });
  };

  onNext = (step) => {
    if (step === this.steps.length - 1) {
      this.onSubmit(this.state.data);
    }
  };

  steps = [
    {
      name: "Basic Info",
      component: <BasicInfo onStateChange={this.collectFormData} />,
      icon: faInfo,
    },
    {
      name: "Shares & Fees ",
      component: <SharesInfo onStateChange={this.collectFormData} />,
      icon: faCubes,
    },
    {
      name: "Live",
      component: <LiveInfo onStateChange={this.collectFormData} />,
      icon: faEye,
    },
  ];

  render() {
    return (
      <>
        <Stepper
          variant={"horizontal"}
          steps={this.steps}
          onNext={this.onNext}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const currentInvestment =
    state.investments.find((item) => item?.id === localStorage.invEDT) || {};
  return {
    investments: state.investments,
    enterprises: state.enterprises,
    toastProps: state.toastProps,
    currentInvestment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      setEditPayload: (val) => dispatch(setEditSetterAction(val)),
      updateRedux: (current_data, new_data) =>
        dispatch(editMutationAction("INVESTMENT", current_data, new_data)),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvestments);
