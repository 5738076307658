/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {
  setEditSetterAction,
  toggleModalAction,
  archiveMutationAction,
} from "store/actions/actions";
import "./listings.css";
import Header from "../../../components/header/Header";
import NoItems from "../../../tools/components/v1/widgets/NoItems/NoItems";
import InvestmentCard from "../investment card/InvestmentCard";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import URLS from "../../../shared/js/urls";
import Title from "../../../components/title/Title";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class ListInvestments extends Component {

  handleDeleteRecord = (item) => {
    let { investments } = this.props;
    if (investments?.length < 1) return;

    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.name}"`,
        children: `Are you sure you want to delete "${item?.name}"`,
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => {
            ApiCallHandler.send(URLS.ARCHIVE_OPPORTUNITIES, "POST", {
              id: item?.id,
            }).then(() => {
              this.props.archiveRecord(investments, item?.id);
            });
          },
        },
      },
    });
  };

  handleViewDetails = (item) => {
    this.props.setEditPayload({
      action: "VIEW_SINGLE_INVESTMENT",
      payload: item,
    });
    localStorage.currentInvestmentId = item.id;
    this.props.history.push("/opportunities/show/" + item.id);
  };

  handleToggleAddOrEditModal = async(item={}) => {
    localStorage.invEDT = item?.id ? item.id : null;
    this.props.history.push('/opportunity/create');
  }

  closeModal = () => {
    this.props.toggleModal({ show: false });
  }
  render() {
    let { investments } = this.props;
    return (
      <div className="">
        <Header
          title="Opportunities"
          subtitle="A list of all Opportunities that have not started yet"
          style={{ paddingLeft: 0, marginBottom: "1rem" }}
        ></Header>

        <div>
          <Title
            className="text-toggler"
            onClick={() => this.handleToggleAddOrEditModal()}
          >
            <FontAwesomeIcon icon={faPlus} /> Add New Investment
          </Title>
        </div>

        <div className="user-investment-items-wrapper">
          {investments?.length > 0 ? (
            investments.map((investment, index) => {
              return (
                <InvestmentCard
                  key={index.toString()}
                  investment={investment}
                  handleViewDetails={this.handleViewDetails}
                  handleEdit={this.handleToggleAddOrEditModal}
                  handleDeleteRecord={this.handleDeleteRecord}
                  close={this.closeModal}
                />
              );
            })
          ) : (
            <div id="no-investments-container">
              <NoItems text={`You do not have any opportunity. Click "Add New Investment" to create one.`} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    investments: state?.investments?.filter((item) => item.has_started === null),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setEditPayload: (val) => dispatch(setEditSetterAction(val)),
      toggleModal: toggleModalAction,
      archiveRecord: (current_data, data_to_delete_id) =>
        dispatch(
          archiveMutationAction("INVESTMENT", current_data, data_to_delete_id)
        ),
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListInvestments)
);

