import React from "react";
import "./ShowActions.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt, faCheck, faTimes, faUndo, faEllipsisV, faInfo } from "@fortawesome/free-solid-svg-icons";
export default function ShowActions({
  onEdit,
  onDelete,
  onView,
  toggleShowMoreOptions,
  item,
  id,
  show,
  onResend,
  onApproved, onDeclined,
  loading
}) {

  return (
    <div id={`${show ? "more-options-root" : ""}`}>
      {item.id === id && show ? (
        <div id="more-options-btn-container">
          {onEdit ? (
            <div
              id="more-option-item"
              className="elevate-float"
              onClick={() => onEdit(item)}
            >
              <FontAwesomeIcon icon={faPen} />
            </div>
          ) : null}
          {onView ? (
            <div
              id="more-option-item"
              className="elevate-float"
              onClick={() => onView(item)}
            >
              <FontAwesomeIcon icon={faInfo} />
            </div>
          ) : null}
          {onDelete ? (
            <div
              id="more-option-item"
              className="elevate-float"
              onClick={() => onDelete(item)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </div>
          ) : null}
          {onApproved ? (
            <div
              id="more-option-item"
              className="elevate-float"
              onClick={() => onApproved(item)}
            >
              <FontAwesomeIcon icon={faCheck} />
            </div>
          ) : null}
          {onDeclined ? (
            <div
              id="more-option-item"
              className="elevate-float"
              onClick={() => onDeclined(item)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          ) : null}
          {onResend ? (
            <div
              id="more-option-item"
              onClick={() => onResend(item)}
              style={{ "--action-color": "green" }}
              className="elevate-float"
            >
              <FontAwesomeIcon
                icon={faUndo}
                size={"sm"}
                className={`${loading && item?.id === id ? "resend-icon" : ""}`}
              />
            </div>
          ) : null}
        </div>
      ) : null}

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div
          id=""
          className={`elevate-foat view-more-icon-container ${
            item.id === id ? "cancel" : ""
          }`}
          onClick={() => {
            toggleShowMoreOptions(show ? {} : item);
          }}
          style={{ marginRight: 15 }}
        >
          {item?.id === id ? (
            <FontAwesomeIcon icon={faTimes} />
          ) : (
            <FontAwesomeIcon icon={faEllipsisV} />
          )}
        </div>
      </div>
    </div>
  );
}
