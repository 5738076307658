import React, { Component } from "react";
import FormGenerator from "../../../../../tools/components/v1/FormGenerator/FormGenerator";
import Fields from "../../../../../tools/components/v1/FormGenerator/Fields";
import ApiCallHandler from "../../../../../shared/js/ApiCallHandler";
import URLS from "../../../../../shared/js/urls";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editMutationAction, toggleToastAction } from "store/actions/actions";
import Header from "../../../../../components/header/Header";

class AssetDocumentCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateReduxState = (isEdit, res)=>{
    let { currentInvestment, investments, updateRedux, asset } = this.props;
    let updatedAssets; 
    if(!isEdit){
      let docs = asset?.documents?.length ? [...asset?.documents, res?.data] : [res.data]
      asset.documents = docs;
      updatedAssets = currentInvestment?.assets?.filter(item=>item?.id !== asset?.id)
    }
    else{
      let updatedDocs = asset.documents?.filter(item=>item?.id !== res.data[0]?.id)
      updatedDocs.push(res.data[0]);
      asset.documents = updatedDocs;
      updatedAssets = currentInvestment?.assets?.filter(item=>item?.id !== asset?.id)
    }
      updatedAssets.push(asset);
      currentInvestment.assets = updatedAssets;
      updateRedux(investments, currentInvestment);
  }

  handleAddDocument = async (data, reset) => {
    let { opportunity_asset, doc } = this.props;
    let res;
    const { document: file, name, visibility } = data;
    const toSend = {
      opportunity_asset: opportunity_asset,
      name: name,
      document: file?.data?.file?.data,
      visibility: visibility === "Yes" ? "True" : "False",
    };

       if (!file?.data?.file?.data) {
         delete toSend?.document;
       }

    if (doc?.id) {
      toSend.id = doc.id;
      res = await ApiCallHandler.send(URLS.UPDATE_OPPORTUNITY_ASSET_DOCUMENT,"POST",toSend);
    } else {
      res = await ApiCallHandler.send(URLS.CREATE_OPPORTUNITY_ASSET_DOCUMENT,"POST",toSend);
    }
    if (res && res.success) {
      this.updateReduxState(doc?.id, res);
      this.props.toggleToast({
        show: true,
        message: "opportunity asset document operation successful",
        type: "success",
      });
      this.props.close();
      reset();
    } else {
      this.props.toggleToast({
        showToast: true,
        message: "An error occured. Try again",
        type: "failure",
      });
    }
  };

  render() {
    let { doc } = this.props;

    const formObjects = [
      {
        fieldType: Fields.INPUT,
        label: "Enter document name",
        name: "name",
        placeholder: "Document name  (eg. license, reciept)",
        required: true,
        value: doc?.id ? doc?.name : "",
      },
      {
        fieldType: Fields.RADIOGROUP,
        label: "Should this document be visible to investors?",
        name: "visibility",
        data: ["Yes", "No"],
        required: true,
        value: doc?.id ? (doc?.visibility ? "Yes" : "No") : "",
      },
      {
        fieldType: Fields.FILE,
        label: "Upload Document",
        placeholder: "Select a file",
        name: "document",
        required: true,
        value: doc?.id ? doc?.document : "",
      },
    ];
    return (
      <div>
        <Header title={`Add Asset Document`} subtitle=" " />
        <div>
          <FormGenerator
            elevation={0}
            fields={formObjects}
            onSubmit={this.handleAddDocument}
            formSubmitBtnText="Add document"
            subtitle=""
            title=""
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownerProps) => {
  const currentInvestment = state.investments.filter(
    (x) => x.id === localStorage.currentInvestmentId
  )[0];
  let assetId = ownerProps?.opportunity_asset;
  return {
    investments: state.investments,
    currentInvestment,
    asset: currentInvestment?.assets?.find((x) => x.id === assetId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      updateRedux: (current_data, new_data) =>
        dispatch(editMutationAction("INVESTMENT", current_data, new_data)),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetDocumentCreation);
