import React, { useState } from "react";
import FormGenerator from "../../../../tools/components/v1/FormGenerator/FormGenerator";
import Fields from "../../../../tools/components/v1/FormGenerator/Fields";
import ApiCallHandler from "../../../../shared/js/ApiCallHandler";
import URLS from "../../../../shared/js/urls";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleModalAction,
  toggleToastAction,
  editMutationAction,
} from "../../../../store/actions/actions";
import { validateEmail } from "../../../../shared/js/utils";

export default function InvitationForm(props) {
  const dispatch = useDispatch();
  const investments = useSelector((state) => state.investments);
  const [errMsg, setErrMsg] = useState("");

  const { editData } = props;
  const soldShares = props.totalShares();
  const total = props.opportunity?.shares;
  const available = total - soldShares;

  const showResponse = (res, reset) => {
    if (res.success) {
      dispatch(editMutationAction("INVESTMENT", investments, res.data));
      dispatch(
        toggleToastAction({
          show: true,
          message: "Email Invitation Sent Successfully",
          type: "success",
        })
      );
      dispatch(toggleModalAction({ show: false }));
      return reset();
    } else {
      dispatch(
        toggleToastAction({
          show: true,
          message:res?.error,
          type: "failure",
        })
      );
      dispatch(toggleModalAction({ show: false }));
    }
  };

  const sendDataToBackend = async (toSend, reset) => {
    const res = await ApiCallHandler.send(
      URLS.SEND_OPPORTUNITY_INVITATION_EMAIL,
      "POST",
      toSend
    );

    if (res) {
      showResponse(res, reset);
    }
  };

  const emailUnique = (opp, email)=>{
    if (opp?.invitations === null) return true;
    return opp?.invitations?.filter((x) => x.email === email).length ===0;
  }
  
  const handleCreate = async (datum, reset) => {
    let { opportunity } = props;
    const isEmailUnique = emailUnique(opportunity, datum.email);
    let is_valid = validateEmail(datum?.email);
    let current;

    if(editData?.id){
      current = parseFloat(available)+parseFloat(editData.shares);
    }
    else{
      current = parseFloat(available);
    }
    if (current <= 0 || current - datum?.shares < 0) {
      setErrMsg( `You have or about exceeding the maximum allocated shares. Only ${available} is available.`);
      return;
    }


    setErrMsg("");

    const toSend = {
      name: datum.name,
      email: datum.email,
      opportunity: opportunity.id,
      shares: datum.shares,
      action: "SEND",
      url: window.location.protocol + "//" + window.location.host + "/",
      invitation_id:''
    };
    if (is_valid) {
      if (editData?.id) {
        toSend.invitation_id = editData?.id;
        sendDataToBackend(toSend, reset);
      } else { 
        if (isEmailUnique) {
          sendDataToBackend(toSend, reset);
        } else {
          showErrorResponse(
            "ERROR!!. This email already exists. Please Invite a different email"
          );
        }
      }
    } else {
      showErrorResponse(
        "ERROR!!. This email is invalid. Please Invite a different email"
      );
    }
  };

  const showErrorResponse = (err) => {
    dispatch(toggleModalAction({ show: false }));
    dispatch(
      toggleToastAction({
        show: true,
        message: err,
        type: "failure",
      })
    );
  };

  return (
    <>
      {errMsg ? (
        <p id="available-shares-notifier">{errMsg}</p>
      ) : (
        <p id="available-shares-notifier">Available Shares: {available}</p>
      )}
      <FormGenerator
        elevation={1}
        fields={[
          {
            fieldType: Fields.INPUT,
            label: "Name",
            name: "name",
            placeholder: "Name",
            required: true,
            value: editData?.id ? editData?.name : "",
          },
          {
            fieldType: Fields.INPUT,
            label: "Email",
            name: "email",
            placeholder: "Email",
            required: true,
            value: editData?.id ? editData?.email : "",
          },
          {
            fieldType: Fields.INPUT,
            label: "Number Of Shares",
            name: "shares",
            placeholder: "Number Of Shares",
            required: true,
            value: editData?.id ? editData?.shares : "",
          },
        ]}
        onSubmit={handleCreate}
        formSubmitBtnText={"Send Invitation"}
        title=""
        subtitle=""
      />
    </>
  );
}
