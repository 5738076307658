import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {
  setEditSetterAction,
} from "store/actions/actions";
import "./listings.css";
import Header from "../../../components/header/Header";
import NoItems from "../../../tools/components/v1/widgets/NoItems/NoItems";
import InvestmentCard from '../../investments /investment card/InvestmentCard'




class ListPartnerships extends Component {

  handleViewDetails = (item) => {
    this.props.setEditPayload({
      action: "VIEW_SINGLE_INVESTMENT",
      payload: item,
    });
    localStorage.currentInvestmentId = item.id;
    this.props.history.push("/partnerships/show/" + item.id);
  };


  render() {
    let { investments } = this.props;
    return (
      <div className="">
        <Header
          title="Partnerships"
          subtitle="A list of running Opportunities"
          style={{ paddingLeft: 0 }}
        ></Header>

        <div className="user-investment-items-wrapper">
          {investments?.length > 0 ? (
            investments.map((investment, index) => {
              return (
                <InvestmentCard
                  key={index.toString()}
                  investment={investment}
                  handleViewDetails={this.handleViewDetails}
                />
              );
            })
          ) : (
            <div id="no-investments-container">
              <NoItems text="You do not have any opportunity that has started." />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    investments: state.investments?.filter(
      (item) => item.has_started === true
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setEditPayload: (val) => dispatch(setEditSetterAction(val)),
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListPartnerships)
);
