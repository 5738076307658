import React, { useState } from 'react'
import Stepper from '../../tools/components/v1/widgets/Stepper/Stepper'
import EnterpriseInfo from './steps/EnterpriseInfo';
import EnterpriseContactInfo from './steps/EnterpriseContactInfo';
import EnterpriseIncorporationDocuments from './steps/EnterpriseIncorporationDocuments';
import Address from './steps/Address';
import { faInfo, faLocationArrow, faFileContract, faAddressBook } from '@fortawesome/free-solid-svg-icons';



export default function EnterpriseVerification() {
 
  const [submitFuctionForEnterpriseInfo, setSubmiFuctionForEnterpriseInfo] = useState("")
  const [submitFuctionForContactInfo, setSubmiFuctionForContactInfo] = useState("")
  const [submitFuctionForDocuments, setSubmiFuctionForDocuments] = useState("")
  const [submitFuctionForAddress, setSubmiFuctionForAddress] = useState("")

  const onNext = (step)=>{
    if(step ===0){
      submitFuctionForEnterpriseInfo();
    }
    else if(step ===1){
      submitFuctionForAddress();
    }
    else if(step ===2){
      submitFuctionForContactInfo();
    }
    else if(step ===3){
      submitFuctionForDocuments();
    }
  }
   const steps = [
     {
       name: "Enterprise Info",
       component: (
         <EnterpriseInfo
           onMount={(onSubmit) =>
             setSubmiFuctionForEnterpriseInfo(() => onSubmit)
           }
         />
       ),
       icon:faInfo
     },
     {
       name: "Address",
       component: (
         <Address
           onMount={(onSubmit) => setSubmiFuctionForAddress(() => onSubmit)}
         />
       ),
       icon:faLocationArrow
     },
     {
       name: "Contact",
       component: (
         <EnterpriseContactInfo
           onMount={(onSubmit) => setSubmiFuctionForContactInfo(() => onSubmit)}
         />
       ),
       icon:faAddressBook
     },
     {
       name: "Incorporation Documents",
       component: (
         <EnterpriseIncorporationDocuments
           onMount={(onSubmit) => setSubmiFuctionForDocuments(() => onSubmit)}
         />
       ),
       icon:faFileContract
     },
   ];
     return (
       <div>
         <Stepper variant={"horizontal"} steps={steps} onNext={onNext} />
       </div>
     );
}
