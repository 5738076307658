import React, { Component } from "react";
import "./investmentCard.css";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faEllipsisV,
  faPen,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import LinearProgressBar from "tools/components/v1/widgets/LinearProgressBar/LinearProgressBar";
import { getLocalEnterprise } from "shared/js/utils";
import { connect } from 'react-redux';
import { getMyCountryDetails } from '../../../shared/js/utils';
class InvestmentCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showActions: false,
      toShowActionId: "",
    };
  }

  showDeleteItem = (id = null) => {
    let { showActions } = this.state;
    if (showActions) {
      this.setState({ showActions: false, toShowActionId: null });
    } else {
      this.setState({ showActions: true, toShowActionId: id });
    }
  };

  convertDateToNumberOfMonths = (date) => {
    if (!date) return 0
    let today = new Date();
    let future = new Date(date);
    const diffTime = future - today
    if (diffTime <=0) return 0
    const months = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return months;
  };

  calculatesAvailableShares = (partnerships) => {
    let { investment } = this.props;

    if (partnerships && partnerships.length) {
      let totalShares = 0;
      partnerships.forEach((partnership) => {
        totalShares += partnership.shares;
      });
      return investment?.shares - totalShares;
    }
    return investment?.shares;
  };

  showNotableInvestors = (partnerships) => {
    if (partnerships && partnerships.length) {
      let investors = [];
      partnerships?.slice(0, 5)?.forEach((partnership) => {
        if (partnership?.partner_type === "ENTERPRISE"){
          investors.push(partnership?.enterprise?.name);
        }
        investors.push(partnership?.user?.first_name);
      });
      return investors.join(", ");
    }
    return "No partners yet";
  };
  trancateTextBody = (text) => {
    let toShow = text?.split("</p>")[0];
    return toShow?.substring(0, 300) + "...";
  };

  totalSharesBought = (partnerships) => {
    let total = 0;
    if (partnerships && partnerships.length) {
      partnerships.forEach((partnership) => {
        total += partnership.shares;
      });
    }
    return total;
  }

  getSharesPercentage = () => {
    let { investment } = this.props;
    let totalShares = investment?.shares > 0 ? investment?.shares : 1;
    let availableShares = investment?.partnerships?.length
      ? this.totalSharesBought(investment?.partnerships)
      : 0;
    let per = (availableShares / totalShares) * 100;
    return per.toFixed(2);
  };
  render() {
    let { investment, handleDeleteRecord, handleEdit, currentEnterprise } = this.props;
     let { currency } = getMyCountryDetails(
       "currency",
       currentEnterprise?.currency
     );
    return (
      <>
        <div
          id="invest-card-wrapper"
          style={{ position: "relative" }}
          className="invest-card-wrapper"
        >
          {this.state.showActions &&
          investment?.id === this.state.toShowActionId ? (
            <div className="more-popup-container elevate-float">
              <div
                onClick={() => {
                  handleEdit(investment);
                  this.showDeleteItem();
                }}
              >
                <FontAwesomeIcon icon={faPen} /> Edit info
              </div>
              <div onClick={() => handleDeleteRecord(investment)}>
                <FontAwesomeIcon icon={faTrashAlt} /> Delete
              </div>
            </div>
          ) : null}
          {!handleEdit ? null : (
            <div
              className="show-more-container"
              onClick={() => this.showDeleteItem(investment?.id)}
            >
              <FontAwesomeIcon icon={faEllipsisV} />
            </div>
          )}

          <div
            id="invest-card-wrapper"
            onClick={() => this.props.handleViewDetails(investment)}
          >
            <div id="name-and-desc-container">
              <h3>{investment?.name}</h3>
              <div
                style={{ cursor: "pointer" }}
                dangerouslySetInnerHTML={{
                  __html: this.trancateTextBody(investment?.about),
                }}
              ></div>
            </div>
          </div>
          <div
            id="progress-container"
            onClick={() => this.props.handleViewDetails(investment)}
          >
            <p>Shares progress</p>
            <LinearProgressBar
              borderRadius={20}
              containerColor="rgba(173, 216, 230, 1)"
              progressColor="var(--app-theme)"
              progressPercentage={this.getSharesPercentage()}
              progressThickness={10}
              style={{ width: "100%", margin: "auto", position: "relative" }}
            />
          </div>
          <div
            id="inest-money-container"
            onClick={() => this.props.handleViewDetails(investment)}
          >
            <div id="subscriptions-container">
              <div>
                <span>Shares Available</span>
                <p>
                  {this.calculatesAvailableShares(investment?.partnerships)}
                </p>
              </div>
              <div>
                <span>Per Share</span>
                <p>{currency?.symbol} {investment?.price_per_share}</p>
              </div>

              <div id="duration-container">
                <span>Closes In</span>
                <p>
                  {this.convertDateToNumberOfMonths(investment?.close_date)}{" "}
                  Days
                </p>
              </div>
            </div>

            <div id="notable-investors-container">
              <span>Notable Partners</span>
              <p>{this.showNotableInvestors(investment?.partnerships)}</p>
            </div>

            <div id="return-percentages-container">
              <div>
                <span>Carry</span>
                <p>{investment?.carry} %</p>
              </div>
              <div>
                <span>Mgmt. Fee</span>
                <p>{currency?.symbol} {investment?.mgmt_fee}</p>
              </div>
              <div>
                <span>Admin Fee</span>
                <p>{investment?.admin_fee} %</p>
              </div>
            </div>
          </div>

          <div id="footer-btns-container">
            <div
              id="view-more-btn-container"
              onClick={() => this.props.handleViewDetails(investment)}
            >
              <p>View More</p>
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapstateToProps = (state) => {
  const currentEnterprise = state.enterprises?.find(item=>item?.id === getLocalEnterprise()?.id);
  return {
    currentEnterprise,
  }
}

export default withRouter(connect(mapstateToProps, null)(InvestmentCard));
