export const COUNTRIES = ["GHANA", "USA", "NIGERIA", "MAURITIUS", "CANADA"];
export const ACCOUNT_TYPE = ["BANK", "MOBILE"];
export const PAYMENT_TYPE = [
  "Direct deposit",
  "Bank Transfer",
  "Mobile Transfer",
];
export const DISTRIBUTION = ["BALANCE", "PERCENTAGE"];
export const CURRENCIES = {
  USA: "USD",
  GHANA: "GHS",
  NIGERIA: "NGN",
  MAURITIUS: "MUR",
  CANADA: "CAD",
};
