import React,{Component} from "react";
import ListInvestments from "./listings/ListInvestments";

export default class InvestmentsWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {
    return (
      <div style={{ paddingTop: 10 }}>
        <div style={{ width: "90%", margin: "auto" }}>
          <ListInvestments />
        </div>
      </div>
    );
  }

}