import Success from "pages/auth/Success";
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import ErrorPage from "./pages/error/ErrorPage";
import Landing from "./pages/landing/Landing";
import Playground from "./pages/Playground";
import CreateEnterprise from "pages/enterprise/create enterpises/CreateEnterprise";
import AllAssets from "pages/assets/listings/AllAssets";
import PageWrapper from "components/page wrapper/PageWrapper";
import AssetSetupAndConfig from "./pages/assets/setup and config/AssetSetupAndConfig";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CreateAndListWrapper from "pages/assets/create/CreateAndListWrapper";
import {
  toggleToastAction,
  setManageAssetTabIdAction,
  toggleModalAction,
  toggleLoadingBlanket,
  getAllInvestmentsAction,
  toggleImagePreviewAction,
  getEnterpriseUserInvitationAction,
  setAllEnterprisesAction,
  getAllPartnersAction,
  getExchangeRatesAction,
} from "store/actions/actions";
import Toast from "tools/components/v1/widgets/Toast/Toast";
import Modal from "tools/components/v1/widgets/Modal/Modal";
import LoadingPage from "shared/components/loading/LoadingPage";
import InvestmentsWrapper from "pages/investments /InvestmentsWrapper";
import opportunitiesResponsePage from "pages/invitations/opportunitiesResponsePage";
import InvestmentsShowWrapper from "pages/investments /single investment/InvestmentsShowWrapper";
import EnterpriseSettingsWrapper from "./pages/enterprise/EnterpriseSettingWrapper";
import AssetDetailWrapper from "./pages/assets/asset detail/AssetDetailWrapper";
import AllSettlementsForSinglePayout from "pages/partnerships/single investment/payouts/individual payout settlements/AllSettlementsForSinglePayout";
import SingleSettlementView from "pages/partnerships/single investment/payouts/individual payout settlements/SingleSettlementView";
import ImagePreview from "./tools/components/v1/widgets/ImagePreview/ImagePreview";
import EnterpriseUsersWrapper from "pages/enterprise/EnterpriseUsersWrapper";
import {
  getEnterprisesActions,
  getAllCountryEnterpriseRequirementsAcion,
} from "./store/actions/actions";
import ReportDetail from "./pages/partnerships/single investment/Report/ReportDetail";
import Spinner from "tools/components/v1/widgets/Spinner/Spinner";
import OpportunityAssetDetail from "./pages/investments /single investment/OpportunityAssets/asset detail/OpportunityAssetDetail";
import EnterpriseVerification from "./pages/enterprise verification/EnterpriseVerification";
import PartnershipsWrapper from "./pages/partnerships/PartnershipsWrapper";
import PartnershipsShowWrapper from "./pages/partnerships/single investment/PartnershipsShowWrapper";
import CreateInvestments from "pages/investments /create investments/CreateInvestments";
import URLS from "shared/js/urls";
import TermAndConditions from "pages/terms and conditions/TermAndConditions";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import AllInvestorsList from './pages/all-investors/list/AllInvestorsList';
import InvestorDetail from './pages/all-investors/detail/InvestorDetail';
import WalletsWrapper from "./pages/wallets/WalletsWrapper";

class App extends Component {

  titles = ["Kehillah | Partnerships"];

  async componentDidMount() {
    document.title = this.titles[0];
        this.props.getInvestments();
        this.props.getEnterpriseUserInvitations();
        this.props.getCountryEnterpriseRequirements();
        this.props.getEnterprisePartners();
       this.props.getEveryEnterprise();
  }

  render() {
    const { blanketProps } = this.props;
    if (blanketProps.show)
      return (
        <LoadingPage
          {...blanketProps.props}
          close={this.props.toggleLoadingBlanket}
        />
      );

    return (
      <>
        <NotificationManager
          close={this.props.toggleToast}
          {...this.props.toastProps}
        />
        <ModalManager
          close={this.props.toggleModal}
          stack={this.props.modalProps}
        />
        <ImagePreviewManager {...this.props.imagePreviewProps} />
        <Router>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/play" component={Playground} />
            <Route
              exact
              path="/terms-and-conditions"
              component={TermAndConditions}
            />

            <Route exact path="/success" component={Success} />
            <Route
              exact
              path="/opportunity_invtations_action_page"
              component={opportunitiesResponsePage}
            />
            <AuthenticatedRoutes {...this.props} /> 

            {/* ------------------- THIS SHOULD BE THE LAST ROUTE AT ALL TIMES --------------_ */}
            <Route path="*" component={ErrorPage} />
          </Switch>
        </Router>
        <div style={{ position: "fixed", bottom: 5, right: 20 }}>
        <p style={{ color: "grey", fontSize: "0.9rem" }}>v1.0.0</p>
        </div>
      </>
    );
  }
}

const AuthenticatedRoutes = (props) => {
  const params = window.location.href.toString().split("?")[1];
  const user = localStorage.userDeta
    ? JSON.parse(localStorage.getItem("userDeta"))
    : {};


  if (!params) {
    if (!localStorage.getItem("encoded")) {
      window.location.href = URLS.AUTH_URL;
    } else if (!user?.has_accepted_tcs) {
        return <Redirect to="/terms-and-conditions" />;
      }
    }

  if (props?.enterprises?.length > 0)  {
    props.getExchangeRates();

    return (
      <>
        <PageWrapper>
          <Switch>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route
              exact
              path="/enterprise/manage"
              component={EnterpriseSettingsWrapper}
            />
            <Route
              exact
              path="/enterprise/users"
              component={EnterpriseUsersWrapper}
            />
            <Route
              exact
              path="/asset/manage"
              component={CreateAndListWrapper}
            />
            <Route
              exact
              path="/asset/tools/:page"
              component={AssetSetupAndConfig}
            />
            <Route
              exact
              path="/enterprise/create"
              component={CreateEnterprise}
            />
            <Route exact path="/asset/listing" component={AllAssets} />
            <Route
              exact
              path="/enterprise/create"
              component={CreateEnterprise}
            />
            <Route exact path="/opportunities" component={InvestmentsWrapper} />
            <Route exact path="/partnerships" component={PartnershipsWrapper} />
            <Route exact path="/partners" component={AllInvestorsList} />
            <Route exact path="/partners/:id" component={InvestorDetail} />
            <Route
              exact
              path="/opportunity/create"
              component={CreateInvestments}
            />
            <Route
              exact
              path="/opportunities/show/:id"
              component={InvestmentsShowWrapper}
            />
            <Route
              exact
              path="/partnerships/show/:id"
              component={PartnershipsShowWrapper}
            />
            <Route
              exact
              path="/opportunities/report/:id"
              component={ReportDetail}
            />
            <Route
              exact
              path="/opportunities/asset/:id"
              component={OpportunityAssetDetail}
            />

            <Route
              exact
              path="/assets/asset-detail"
              component={AssetDetailWrapper}
            />
            <Route
              exact
              path="/opportunity-payout-settlements/:id"
              component={AllSettlementsForSinglePayout}
            />
            <Route
              exact
              path="/opportunity-payout-settlement-detail/:id"
              component={SingleSettlementView}
            />
            <Route
              exact
              path="/enterprise/verification"
              component={EnterpriseVerification}
            />
            <Route
              exact
              path="/enterprise/wallets"
              component={WalletsWrapper}
            />
            <Route component={ErrorPage} />
          </Switch>
        </PageWrapper>
      </>
    );
  }
  else{
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner variation={"TwoHalfCirclesType"} />
      </div>
    );
  }

};

const ModalManager = ({ stack, close }) => {
  if (stack?.length > 0) {
    return (
      <Modal close={() => close()} {...stack[0]?.props}>
        {stack[0]?.props?.children}
      </Modal>
    );
  }
  return <></>;
};
const NotificationManager = (props) => {
  if (props.show) return <Toast close={() => props.close()} {...props} />;
  return <></>;
};

const ImagePreviewManager = (props) => {
  if (props.show) return <ImagePreview {...props} />;
  return <></>;
};

const mapStateToProps = (state) => {
  return {
    toastProps: state.toastProps,
    modalProps: state.modalProps,
    blanketProps: state.blanketProps,
    imagePreviewProps: state.imagePreviewProps,
    enterprises: state.enterprises,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      getAssetTab: setManageAssetTabIdAction,
      toggleModal: toggleModalAction,
      toggleLoadingBlanket,
      getInvestments: getAllInvestmentsAction,
      toggleImagePreview: toggleImagePreviewAction,
      getEnterpriseUserInvitations: getEnterpriseUserInvitationAction,
      getAllEnterprises: setAllEnterprisesAction,
      getEveryEnterprise: getEnterprisesActions,
      getCountryEnterpriseRequirements: getAllCountryEnterpriseRequirementsAcion,
      getEnterprisePartners:getAllPartnersAction,
      getExchangeRates: getExchangeRatesAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
