import React from "react";
import "./prefs.css";
import Title from "../../../components/title/Title";
import { useSelector } from "react-redux";
import { getMyCountryDetails } from '../../../shared/js/utils';
import { getLocalEnterprise } from 'shared/js/utils';

export default function EnterprisePreferences() {

  const enterprises =
    useSelector((state) =>
      state.enterprises) || [];

  const enterprise  =enterprises?.find((item) => item.id ===getLocalEnterprise()?.id) || {};

  const {currency} = getMyCountryDetails("currency", enterprise?.currency);
  return (
    <div>
      <div>
        <Title>Currency</Title>
      </div>
      <div className=" elevate-float country-and-curency">
        <div className="  currency-container">
            <p className="bold">Currency</p>
              <p>{currency?.code}</p>
              <p>{currency?.symbol}</p>
              <p>{currency?.name}</p>
        </div>
      </div>
    </div>
  );
}
