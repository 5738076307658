import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  toggleModalAction,
  setEditSetterAction,
  editMutationAction,
} from "store/actions/actions";
import Title from "components/title/Title";
import Table from "tools/components/v1/widgets/Table/Table";
import NoItems from "tools/components/v1/widgets/NoItems/NoItems";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import { withRouter } from "react-router-dom";
import ShowActions from '../../../../../shared/components/showActions/ShowActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { getMeaningfulDate, getFileType } from '../../../../../shared/js/utils';
import CreateOpportunityDocument from '../create/CreateOpportunityDocument';

class AllOpportunityDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showActions: false,
      id: "",
    };
  }

  handleShowDetail = (item) => {
    localStorage.assetID = item.id;
    window.open(item?.document, "_blank");
  };


  deleteItemFromRedux = (id) => {
    const {investments, currentInvestment, updateRedux} = this.props;
    let newDocuments = currentInvestment?.documents?.filter(item => item?.id !== id);
    currentInvestment.documents = newDocuments;
    updateRedux(investments, currentInvestment);
    

  }

  handleDeleteDocument = (item) => {
    ApiCallHandler.send(URLS.ARCHIVE_OPPORTUNITIES_DOCUMENTS, "POST", {
      id: item?.id,
    }).then(( res) => {
      this.deleteItemFromRedux(item?.id);
    });
  };

  handleShowDeleteModal = (item) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.title}"`,
        children: `Are you sure you want to delete "${item?.title}"`,
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => this.handleDeleteDocument(item),
        },
      },
    });
  };
  toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      this.setState({
        showActions: false,
        id: "",
      });
    } else {
      this.setState({
        showActions: true,
        id: item?.id,
      });
    }
  };
  prepareTableData = () => {
    let { allOpportunityDocuments } = this.props;
    let tableData = (allOpportunityDocuments || []).map((item) => [
      <p>{getMeaningfulDate(item?.date)}</p>,
      <p onClick={() => this.handleShowDetail(item)} id="doc-name-txt">
        {item?.title}
      </p>,
      <p id="doc-type-container">
        {getFileType(item?.document).toUpperCase()}
      </p>,
      <p>{item?.is_published ? "Public" : "Private"}</p>,
      <ShowActions
        onEdit={this.handleAddDocument}
        onDelete={this.handleShowDeleteModal}
        onView={this.handleShowDetail}
        item={item}
        id={this.state.id}
        toggleShowMoreOptions={this.toggleShowMoreOptions}
        show={this.state.showActions}
      />,
    ]);
    return tableData;
  };
  closeModal = ()=>{
    this.props.toggleModal({show:false})
  }

  handleAddDocument = (item = null) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `${item?.id ? "Update" : "Add New "} document`,
        children: (
          <CreateOpportunityDocument close={this.closeModal} doc={item} currentInvestment={this.props?.currentInvestment?.id}/>
        ),
        themeColor: "var(--app-theme)",
        size: "large",
      },
    });
  };

  renderDocuments = () => {
    let { allOpportunityDocuments } = this.props;
    return (
      <div>
        <div style={{ borderRadius: 10 }}>
          <div style={{ marginBottom: "1.5rem" }}>
            <Title
              className="text-toggler"
              onClick={() => this.handleAddDocument()}
            >
              <FontAwesomeIcon icon={faPlus} /> Add Document
            </Title>
          </div>

          {allOpportunityDocuments && allOpportunityDocuments.length ? (
            <div style={{ width: "90%" }}>
              <Table
                columns={["Created At", "Name", "Type", "Status", "\t"]}
                data={this.prepareTableData()}
                hoverAnimation={false}
              />
            </div>
          ) : (
            <NoItems text="No Assets yet" />
          )}
        </div>
      </div>
    );
  };

  render() {
    return <div>{this.renderDocuments()}</div>;
  }
}

const mapStateToProps = (state) => {
  const currentInvestment = state.investments?.filter(
    (x) => x.id === localStorage.currentInvestmentId
  )[0];
  return {
    allOpportunityDocuments: currentInvestment?.documents,
    currentInvestment,
    investments: state.investments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleModal: toggleModalAction,
      setEditPayload: (val) => dispatch(setEditSetterAction(val)),
      updateRedux: (current_data, new_data) =>
        dispatch(editMutationAction("INVESTMENT", current_data, new_data)),
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllOpportunityDocuments)
);
