import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleModalAction } from "store/actions/actions";
import TabView from "../../tools/components/v1/widgets/TabView/TabView";
import ComplianceInfo from "./compliances/ComplianceInfo";
import EnterpriseSettings from './EnterpriseSettings';
import EnterprisePreferences from './preferences/EnterprisePreferences';

class CreateAndListEntepriseWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { changer: "enterprise-detail" };
  }

  TABS = [
    {
      name: "Enterprise Details",
      id: "enterprise-detail",
      component: (
        <EnterpriseSettings
          switchTabs={(changer) => this.state.changer(changer)}
        />
      ),
    },
    {
      name: "Compliance  Information",
      id: "compliance-information",
      component: (
        <ComplianceInfo
          edit={true}
          switchTabs={(changer) => this.state.changer(changer)}
        />
      ),
    },
    {
      name: "Preferences",
      id: "enterprise-preferences",
      component: (
        <EnterprisePreferences
          edit={true}
          switchTabs={(changer) => this.state.changer(changer)}
        />
      ),
    },
  ];
  render() {
    return (
      <div style={{ width: "90%", margin: "auto", marginTop: "2rem" }}>
        <TabView
          data={this.TABS}
          contentAreaStyle={{ paddingTop: 30 }}
          onMount={(changer) => this.setState({ changer })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modalProps: state.modalProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleModal: toggleModalAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAndListEntepriseWrapper);
