import React, { useEffect, useState } from "react";
import "./ReportDetail.css";
import { getFileType, getMeaningfulDate } from "../../../../shared/js/utils";
import Header from "../../../../components/header/Header";
import { useSelector } from "react-redux";

export default function ReportDetail(props) {
  const [report, setReport] = useState({});
  const currentInvestment = useSelector(
    (state) =>
      state?.investments.filter(
        (investment) => investment?.id === localStorage.currentInvestmentId
      )[0]
  );

  useEffect(() => {
    const report = currentInvestment?.reports?.filter(
      (report) => report?.id === localStorage.ReportID
    )[0];
    setReport(report);
  }, [currentInvestment]);

  const showFiles = (file) => {
    const IMAGE_TYPES = ["png", "jpeg", "jpg"];
    const VIDEO_TYPES = ["mp4", "mov", "avi", "wmv", "flv", "mpeg", "mpg"];
    if (IMAGE_TYPES.includes(getFileType(file)))
      return (
        <img
          src={file}
          alt=""
          onClick={() => this.toggleImagePreview(file)}
          style={{ width: "90%", marginTop: "3rem", height: "70vh" }}
        />
      );
    else if (VIDEO_TYPES.includes(getFileType(file)))
      return (
        <video
          src={file}
          controls
          onClick={() => this.toggleImagePreview(file)}
          style={{ width: "90%", marginTop: "3rem", height: "70vh" }}
        />
      );
    else
      return (
        <div
          className="pdf-container"
          onClick={() => window.open(file, "_blank")}
        >
          <p>Click to View Attachemnt</p>
        </div>
      );
  };

  const period = `${getMeaningfulDate(report?.period_start)} - ${getMeaningfulDate(report?.period_end)}`;
  return (
    <div style={{ width: "95%", margin: "auto" }}>

      <div style={{ marginTop: "2rem" }}>
        <Header title={`${report?.title || ""}`} subtitle={`${period}`} />
      </div>
      <div className="tweet-card-body ">
        <div
          className="optimum-padding-right-left report-detail-body"
          dangerouslySetInnerHTML={{
            __html: report?.body || "",
          }}
        ></div>
        <div className="tweet-card-image optimum-padding-right-left ">
          {report?.file ? showFiles(report?.file) : <></>}
        </div>
      </div>
    </div>
  );
}
