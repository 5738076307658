/* eslint-disable import/no-anonymous-default-export */
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import FormGenerator from "tools/components/v1/FormGenerator/FormGenerator";
import Fields from "tools/components/v1/FormGenerator/Fields";
import Title from "components/title/Title";
import NoItems from "tools/components/v1/widgets/NoItems/NoItems";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentEnterpriseAction,
  setAllEnterprisesAction,
} from "store/actions/actions";
import "./Success.css";
import { capitalizeMe, removeCountriesWithEmptyCurency } from "shared/js/utils";
import { toggleModalAction } from '../../store/actions/actions';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { isEmpty } from '../../shared/js/utils';
import LoadingPage from '../../shared/components/loading/LoadingPage';

export default () => {
  const [dataGotten, setDataGotten] = useState({});
  const [hasEnterprises, setHasEnterprises] = useState(false);
  const [hasPendingEnterpriseInvitations, setHasPendingEnterpriseInvitations] =
    useState(false);
  const dispatch = useDispatch();
  const all = useSelector((state) => state.enterprises) || [];
  let history = useHistory();

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const [url, params] = window.location.href.toString().split("?");
    if (params) {
      // eslint-disable-next-line no-unused-vars
      const [paramName, paramValue] = params.split("=");
      const data_ = JSON.parse(atob(paramValue));
      localStorage.encoded = data_?.signIn;

      ApiCallHandler.send(URLS.GET_USERS, "POST", {
        id: "123e4567-e89b-12d3-a456-426614174000",
        email: data_.email,
        action: "FIND_OR_CREATE",
      }).then(({ data }) => {
        setDataGotten(data);
        localStorage.userDeta = JSON.stringify(data);
        data?.enterprises?.length > 0 || data?.my_other_enterprises?.length > 0
          ? setHasEnterprises(true)
          : setHasEnterprises(false);

        const myEnterprises =
          data?.enterprises && data?.enterprises.length ? data.enterprises : [];

        const myOtherEnterprises =
          data?.my_other_enterprises && data?.my_other_enterprises.length
            ? data?.my_other_enterprises?.map((e) => e.enterprise)
            : [];

        dispatch(
          setAllEnterprisesAction([...myEnterprises, ...myOtherEnterprises])
        );

        const myPendingEnterpriseInvitations =
          data?.my_enterprise_invitations &&
          data?.my_enterprise_invitations.length
            ? data?.my_enterprise_invitations?.filter(
                (e) => e.has_responded === false
              )
            : [];

        setHasPendingEnterpriseInvitations(
          myPendingEnterpriseInvitations.length > 0
        );
      });
    }
  }, [dispatch]);

  const setOxidationState = async (item) => {
    const otherEnterprises =
      dataGotten?.my_other_enterprises &&
      dataGotten?.my_other_enterprises.length
        ? dataGotten?.my_other_enterprises?.map((e) => e.enterprise)
        : [];
    let is_mine = otherEnterprises.filter((e) => e.id === item?.id)?.length < 1;
    localStorage.oxidationState = is_mine;
    dispatch(setCurrentEnterpriseAction(item));
    localStorage.currentEnterprise = JSON.stringify(item);
    window.location.href = "/dashboard";
  };

  const renderAllEnterprises = (data = []) => {
    if (hasEnterprises || hasPendingEnterpriseInvitations) {
      return data?.map((item) => {
        return (
          <div className="all-enterprises-list-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 15,
                paddingTop: 15,
              }}
            >
              <img
                src={item?.logo}
                alt="logo"
                className="enterprise-logo-item"
              />
              <h3 style={{ marginLeft: 15 }}>{item?.name}</h3>
            </div>

            <div
              id="goto-dashboard-container"
              onClick={() => {
                setOxidationState(item);
              }}
            >
              <p>Open Enterprise</p>
            </div>
          </div>
        );
      });
    } else {
      return <></>;
    }
  };

  const handleAcceptInvitation = (id, enterprise) => {
    const dataToSend = {
      id,
      enterprise,
      user: dataGotten.id,
    };
    ApiCallHandler.send(
      URLS.ACCEPT_PENDING_ENTERPRISE_USER_INVITATION,
      "POST",
      dataToSend
    ).then((res) => {
      if (res.success) {
        alert("Accepted Successfully");
        window.location.reload();
      }
    });
  };
  const handleDeclineInvitation = (id) => {
    const dataToSend = {
      id,
    };
    ApiCallHandler.send(
      URLS.ARCHIVE_ENTERPRISE_USER_INVITATIONS,
      "POST",
      dataToSend
    ).then((res) => {
      if (res.success) {
        alert("Declined Successfully");
        window.location.reload();
      }
    });
  };

  const renderEnterpriseInvitations = (data = []) => {
    if (hasPendingEnterpriseInvitations) {
      return data?.map((item) => {
        return (
          <div className="all-enterprises-list-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: 15,
              }}
            >
              <img
                src={item?.enterprise?.logo}
                alt="logo"
                className="enterprise-logo-item"
              />
              <h3 style={{ marginLeft: 15 }}>{item?.enterprise?.name}</h3>
            </div>

            <div id="actions">
              <div
                className="elevate-float btn-item accept"
                onClick={() =>
                  handleAcceptInvitation(item.id, item?.enterprise?.id)
                }
              >
                <p>Accept</p>
              </div>
              <div
                className="elevate-float btn-item reject"
                style={{ marginLeft: "10px" }}
                onClick={() => handleDeclineInvitation(item.id)}
              >
                <p>Decline</p>
              </div>
            </div>
          </div>
        );
      });
    }
  };
  const showAllEnterprises = () => {
    return (
      <div>
        <div style={{ borderRadius: 10 }}>
          <div className="mb" style={{ marginBottom: 20 }}>
            <Title
              className="text-toggler"
              onClick={() => toggleAddEnterpriseModal()}
            >
              <FontAwesomeIcon icon={faPlus} /> Add New Enterprise
            </Title>
          </div>
          {all && all.length ? (
            renderAllEnterprises(all)
          ) : (
            <NoItems text="You Have No Enterprises. Click 'Add New Enterprise' to create." />
          )}
        </div>
      </div>
    );
  };

  const showAllPendingEnterpriseInvitations = () => {
    let { my_enterprise_invitations: allData } = dataGotten;
    allData =
      allData && allData.length
        ? allData?.filter((e) => e.has_responded === false)
        : [];
    return (
      <div>
        <div style={{ borderRadius: 10, marginLeft: "30px" }}>
          <Title>Pending Invitations</Title>
          {allData && allData.length ? (
            renderEnterpriseInvitations(allData)
          ) : (
            <NoItems text="No Pending Invitations" />
          )}
        </div>
      </div>
    );
  };

  // =---------- SEARCH FILTER ------------
    const countrySearch = (value) => {
      const filtered = removeCountriesWithEmptyCurency().filter((item) => {
        const arr = item?.name.toLowerCase().split(value?.toLowerCase());
        if (arr.length > 1) return item;
        return null;
      });
      return filtered;
    };
    const currencySearch = (value) => {
      const filtered = removeCountriesWithEmptyCurency().filter((item) => {
        const arr = item?.currency?.name?.toLowerCase()
          .split(value?.toLowerCase());
        if (arr.length > 1) return item;
        return null;
      });
      return filtered;
    };


  // =---------- SEARCH FILTER END------------

  const renderCreateOrListEnterprise = () => {
    if (hasEnterprises || hasPendingEnterpriseInvitations) {
      return (
        <div className="list-enterprise-wrapper elevate-float">
          <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
            {hasPendingEnterpriseInvitations
              ? showAllPendingEnterpriseInvitations()
              : null}
            <br />
            <br />
            {showAllEnterprises()}
          </div>
        </div>
      );
    } else {
      const message = `Hi ${
        capitalizeMe(dataGotten?.first_name) || ", there"
      } you do not have any enterprise of your own at the moment.You can fill the form below to create one for yourself`;
      if (
        dataGotten?.has_accepted_tcs === false ||
        dataGotten?.has_accepted_tcs === "undefined"
      ) {
        history.push("/terms-and-conditions");
        return;
      }
      return (
        <div className="create-and-list-enterprise-wrapper elevate-float">
          <center>
            <br />
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ fontSize: "5rem", color: "green", marginBottom: 30 }}
            />
          </center>
          <br />
          <FormGenerator
            elevation={0}
            fields={[
              {
                fieldType: Fields.INPUT,
                name: "name",
                label: "Name",
                placeholder: "Eg: Kehillah Ghana Limited",
                required: true,
              },
              {
                data: removeCountriesWithEmptyCurency(),
                fieldType: Fields.DROPDOWN,
                type: "full",
                name: "country",
                label: "Country",
                placeholder: "Select Country",
                required: true,
                labelExtractor: (item) => item.name?.toUpperCase(),
                valueExtractor: (item) => item.name,
                onSearch: (value) => countrySearch(value),
              },
              {
                data: removeCountriesWithEmptyCurency(),
                fieldType: Fields.DROPDOWN,
                type: "full",
                name: "currency",
                label: "Currency",
                placeholder: "Select Currency",
                required: true,
                labelExtractor: (item) =>
                  `${item.name?.toUpperCase()} - ${item?.currency?.name?.toUpperCase()} (${
                    item?.currency?.symbol
                  })`,
                onSearch: (value) => currencySearch(value),
              },
            ]}
            onSubmit={async (datum) => {
              datum = { ...datum, user_id: dataGotten.id };
              datum.country = datum?.country?.name;
              datum.currency = `${datum?.currency?.name}-${datum?.currency?.currency?.name}`;
              const res = await ApiCallHandler.send(
                URLS.CREATE_ENTERPRISES,
                "POST",
                datum
              );
              if (res.success) {
                dispatch(setCurrentEnterpriseAction(res?.data));
                localStorage.currentEnterprise = JSON.stringify(res?.data);
                setOxidationState(res?.data);
              }
            }}
            formSubmitBtnText="Create Enterprise"
            subtitle=""
            title={message}
          />
          {hasPendingEnterpriseInvitations
            ? showAllPendingEnterpriseInvitations()
            : null}
          <br />
          <br />
        </div>
      );
    } 
  };


  const toggleAddEnterpriseModal = ()=>{
    dispatch(
      toggleModalAction({
        show: true,
        props: {
          title: `Add An Enterprise`,
          children: (
            <FormGenerator
              elevation={0}
              fields={[
                {
                  fieldType: Fields.INPUT,
                  name: "name",
                  label: "Name",
                  placeholder: "Eg: Kehillah Ghana Limited",
                  required: true,
                },
                {
                  data: removeCountriesWithEmptyCurency(),
                  fieldType: Fields.DROPDOWN,
                  type: "full",
                  name: "country",
                  label: "Country",
                  placeholder: "Select Country",
                  required: true,
                  labelExtractor: (item) => item.name?.toUpperCase(),
                  valueExtractor: (item) => item.name,
                  onSearch: (value) => countrySearch(value),
                },
                {
                  data: removeCountriesWithEmptyCurency(),
                  fieldType: Fields.DROPDOWN,
                  type: "full",
                  name: "currency",
                  label: "Currency",
                  placeholder: "Select Currency",
                  required: true,
                  labelExtractor: (item) =>
                    `${item.name?.toUpperCase()} - ${item?.currency?.name?.toUpperCase()} (${
                      item?.currency?.symbol
                    })`,
                  onSearch: (value) => currencySearch(value),
                },
              ]}
              onSubmit={async (datum) => {
                datum = { ...datum, user_id: dataGotten.id };
                datum.country = datum?.country?.name;
                datum.currency = `${datum?.currency?.name}-${datum?.currency?.currency?.name}`;
                const res = await ApiCallHandler.send(
                  URLS.CREATE_ENTERPRISES,
                  "POST",
                  datum
                );
                if (res?.success) {
                  dispatch(setCurrentEnterpriseAction(res?.data));
                  localStorage.currentEnterprise = JSON.stringify(res?.data);
                  setOxidationState(res?.data);
                }
              }}
              formSubmitBtnText="Create Enterprise"
              subtitle=""
              title={""}
            />
          ),
          size: "medium",
          themeColor: "var(--app-theme)",
        },
      })
    );
  }


  


  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "100%" }}>
        {isEmpty(dataGotten) ? (
          <LoadingPage text={"Preparing For You 🤗"} />
        ) : (
          renderCreateOrListEnterprise()
        )}
      </div>
    </div>
  );
};
