import React from "react";
import PropTypes from "prop-types";
import "./NoItems.css";
import NoItemsIcon from "./NoItemsIcon";
export default function NoItems({ text = "No items found", icon }) {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div>{icon ? icon : <NoItemsIcon />}</div>
      <p>{text}</p>
    </div>
  );
}

NoItems.propTypes = {
  text: PropTypes.string,
  /**
   * FontAwesome object
   */
  icon: PropTypes.object,
};
