import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  toggleSidePane,
  setAssetCreateOrEditTypeAction,
  toggleModalAction,
  toggleLoadingBlanket,
  setEditSetterAction,
  archiveMutationAction
} from "store/actions/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faArchive, faEye } from "@fortawesome/free-solid-svg-icons";
import Title from "components/title/Title";
import Table from "tools/components/v1/widgets/Table/Table";
import NoItems from "tools/components/v1/widgets/NoItems/NoItems";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import URLS from "../../../shared/js/urls";
import { withRouter } from "react-router-dom";



class AllAssets extends Component {
  handleEditAsset = async (item) => {
    this.props.setEditPayload({ assetEditStatus: true, assetData: item });
    this.props.switchTabs("create-and-edit-asset");
  };

  handleShowDetail = (item)=>{
        localStorage.assetID = item.id;
       this.props.history.push("/assets/asset-detail");
  }
  prepareTableData = () => {
    let { allAssets, archiveAsset } = this.props;
    let tableData = (allAssets || []).map((item) => {
      return [
        <p>{item?.name}</p>,
        <p>{item?.cost}</p>,
        <p>{item?.asset_type?.name}</p>,
        <p>{item?.identification_number}</p>,
        <p></p>,
        <div className="actions-container">
          <div
            id="edit"
            onClick={() => {
              this.handleShowDetail(item);
            }}
            style={{ marginRight: 15 }}
          >
            <FontAwesomeIcon icon={faEye} color={"white"} size={"sm"} />
          </div>

          <div
            id="edit"
            onClick={() => {
              this.handleEditAsset(item);
            }}
          >
            <FontAwesomeIcon icon={faPen} color={"white"} size={"sm"} />
          </div>
          <div
            id="delete"
            onClick={() =>
              this.props.toggleModal({
                show: true,
                props: {
                  title: `Delete "${item?.name}"`,
                  children: `Are you sure you want to delete "${item?.name}"`,
                  themeColor: "maroon",
                  cancel: true,
                  okay: {
                    text: "Yes",
                    function: () => {
                      ApiCallHandler.send(URLS.ARCHIVE_ASSETS, "POST", {
                        id: item?.id,
                      }).then(({ data }) => {
                        archiveAsset(allAssets, item?.id);
                      });
                    },
                  },
                },
              })
            }
          >
            <FontAwesomeIcon icon={faArchive} color={"white"} size={"sm"} />
          </div>
        </div>,
      ];
    });
    return tableData;
  };

  showAllAssets = () => {
    let { allAssets } = this.props;
    return (
      <div>
        <div style={{ borderRadius: 10 }}>
          <Title>Asset</Title>

          {allAssets && allAssets.length ? (
            <Table
              columns={[
                "Name",
                "Cost",
                " Asset Type",
                "ID Number",
                "\t",
                "actions",
              ]}
              data={this.prepareTableData()}
              hoverAnimation={false}
            />
          ) : (
            <NoItems text="No Assets yet" />
          )}
        </div>
      </div>
    );
  };

  render() {
    return <div>{this.showAllAssets()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    allAssets: state.assets,
    assetFormStatus: state.assetEditStatus,
    modalProps: state.modalProps,
    editStatus: state.editStatusPayload && state.editStatusPayload.assetEditStatus ? state.editStatusPayload.assetEditStatus : false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSidePane,
      setAssetFormStatus: (val) => dispatch(setAssetCreateOrEditTypeAction(val)),
      toggleModal: toggleModalAction,
      toggleLoadingBlanket,
      setEditPayload:  (val) => dispatch(setEditSetterAction(val)),
      archiveAsset: (assets, asset_to_delete_id) => dispatch(archiveMutationAction("ASSET", assets, asset_to_delete_id)),
    },
    dispatch
  );
};

export default withRouter( connect(mapStateToProps, mapDispatchToProps)(AllAssets));
