import {
  faDatabase,
  faPiggyBank,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";

export const MENU = [
  {
    groupName: "Manage",
    children: [
      {
        id: "dashboard",
        name: "Dashboard",
        icon: faDatabase,
        link: "/dashboard",
      },

      // {
      //   id: "assets",
      //   name: "Assets",
      //   icon: faMoneyCheckAlt,
      //   link: "/asset/manage"
      // },

      {
        id: "opportunities",
        name: "Opportunities",
        icon: faPiggyBank,
        link: "/opportunities",
      },
      {
        id: "partnerships",
        name: "Partnerships",
        icon: faHandshake,
        link: "/partnerships",
      },
    ],
  },
];
