import React, { Component } from 'react'
import { connect } from 'react-redux'
import FormGenerator from 'tools/components/v1/FormGenerator/FormGenerator'

class LiveInfo extends Component {
    render() {
        const {currentInvestment:editData} = this.props
        const formData = [

            {
                fieldType: FormGenerator.Fields.RADIOGROUP,
                label: "Has this opportunity started?",
                name: "has_started",
                data: ["Yes", "No"],
                required: true,
                value: editData?.has_started
                  ? "Yes"
                  : "No" || "No",
            },
            {
                fieldType: FormGenerator.Fields.DATE,
                label: "If Yes, when did it start?",
                name: "opportunity_start_date",
                placeholder: "Enter starting date...",
                value:editData?.id ? editData?.opportunity_start_date?.split("T")[0]: "",
            },
            {
                fieldType: FormGenerator.Fields.RADIOGROUP,
                label: "Should this go live?",
                name: "is_published",
                data: ["Yes", "No"],
                required: true,
                defaultValue: editData?.is_published ? "Yes" : "No" || "No",
            },

        ]

        const formFields =  editData?.id ? formData : [formData[2]]
        return (
            <div>
                <FormGenerator
                    elevation={0}
                    subtitle=""
                    title=""
                    fields={formFields}
                    hideSubmitBtn={true}
                    onStateChange={this.props.onStateChange ? this.props.onStateChange : null}
                />
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    const currentInvestment = state.investments.find(item => item?.id === localStorage.invEDT) || {}
    return {
        currentInvestment
    }
}
export default connect(mapStateToProps, null)(LiveInfo)