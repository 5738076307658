import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./wallets.css";
import bank from "./../../shared/imgs/bank.svg";
import {
  toggleModalAction,
  toggleToastAction,
  editMutationAction,
} from "../../store/actions/actions";
import NoItems from "../../tools/components/v1/widgets/NoItems/NoItems";
import URLS from "../../shared/js/urls";
import ApiCallHandler from "../../shared/js/ApiCallHandler";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faPen,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import FormGenerator from "../../tools/components/v1/FormGenerator/FormGenerator";
import Fields from "../../tools/components/v1/FormGenerator/Fields";
import { getMyCountryDetails, removeCountriesWithEmptyCurency, isEmpty } from "shared/js/utils";
class BankWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      editData: {},
      showDelete: false,
      toDeleteId: "",
    };
  }

  updateRedux = (isEdit, data) => {
    let { wallets, editRecord, enterprise, enterprises } = this.props;
    let newWallets;
    if (!isEdit) {
      newWallets = isEmpty(wallets) ? [...wallets, data] : [data];
    } else {
      newWallets = wallets.filter((wallet) => wallet.id !== data[0].id);
      newWallets.push(data[0]);
    }
    let newEnterprise = enterprise;
    newEnterprise.bank_wallets = newWallets;

    editRecord(enterprises, newEnterprise);
  };

  close = () => {
    this.props.toggleModal({
      show: false,
    });
  };

  handleSubmit = async (data, reset, isEdit) => {
    let res;
        data.currency = `${data?.currency?.name}-${data?.currency?.currency?.name}`;
        data.country = data?.country?.name;
    if (!isEdit) {
      let { id, ...rest } = data;
      res = await ApiCallHandler.send(URLS.CREATE_BANK_WALLETS, "POST", rest);
    } else {
      res = await ApiCallHandler.send(URLS.UPDATE_BANK_WALLETS, "POST", data);
    }
    this.showResponse(res, isEdit, reset);
  };

  showBankWallets = (wallet) => {
      let {currency} = getMyCountryDetails(wallet.currency);
    return (
      <div id="bank-item-container" className="elevate-float">
        <div className="bank-icon-container icon-container ">
          <img src={bank} alt="bank" />
        </div>

        <div id="bank-wallet-info-container">
         <div>
            <h3>{wallet?.account_name?.toUpperCase()}</h3>
            <p id="bank-name">
              {wallet?.bank_name} {" "}
             ({wallet?.bank_branch})
            </p>
            <p style={{fontWeight:"bold"}}>{wallet?.country}</p>
            <span>Swift - {wallet?.swift_code}</span>
            <p id="account-number" className="account-num">
              {wallet?.account_number} ({currency?.code})
            </p>
          </div>

          {this.state.showDelete && wallet?.id === this.state.toDeleteId ? (
            <div className="delete-container elevate-float">
              <div onClick={() => this.handleAdd(wallet)}>
                <FontAwesomeIcon icon={faPen} /> Edit Wallet info
              </div>
              <div onClick={() => this.handleDelete(wallet)}>
                <FontAwesomeIcon icon={faTrashAlt} /> Delete Wallet
              </div>
            </div>
          ) : null}

          <div
            className="more-container"
            onClick={() => this.showDeleteItem(wallet.id)}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </div>
        </div>
      </div>
    );
  };

  handleAdd = (editData = null) => {
    this.setState({ showDelete: false });
    this.props.toggleModal({
      show: true,
      props: {
        size: "medium",
        title: `${editData ? "Edit" : "Add New"} Bank Account`,
        children: this.form(editData),
        themeColor: "var(--app-theme)",
        cancel: false,
      },
    });
  };

  showDeleteItem = (id = null) => {
    let { showDelete } = this.state;
    if (showDelete) {
      this.setState({ showDelete: false, toDeleteId: null });
    } else {
      this.setState({ showDelete: true, toDeleteId: id });
    }
  };

  handleDelete = (item) => {
    let { wallets } = this.props;
    if (wallets?.length < 1) return;
    this.setState({ showDelete: false });
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.account_number}"`,
        children: `Are you sure you want to delete "${item?.account_number}"`,
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => {
            this.triggerBackendDelete(item, wallets);
          },
        },
      },
    });
  };
  currencySearch = (value) => {
    const filtered = removeCountriesWithEmptyCurency().filter((item) => {
      const arr = item?.currency?.name
        ?.toLowerCase()
        .split(value?.toLowerCase());
      if (arr.length > 1) return item;
      return null;
    });
    return filtered;
  };
  countrySearch = (value) => {
    const filtered = removeCountriesWithEmptyCurency().filter((item) => {
      const arr = item?.name.toLowerCase().split(value?.toLowerCase());
      if (arr.length > 1) return item;
      return null;
    });
    return filtered;
  };
  extractCountry = (country) => {
    if (!country) return {};
    let _country = country?.split("-");
    return removeCountriesWithEmptyCurency().find(
      (item) => item?.name === _country[0]
    );
  };

  form = (editData = {}) => {
    return (
      <FormGenerator
        elevation={0}
        fields={[
          {
            fieldType: Fields.INPUT,
            label: "Bank Name",
            name: "bank_name",
            placeholder: "Bank name",
            required: true,
            value: editData ? editData.bank_name : "",
          },
          {
            fieldType: Fields.INPUT,
            label: "Account Holder's Name",
            name: "account_name",
            placeholder: "Account name",
            required: true,
            value: editData ? editData.account_name : "",
          },
          {
            fieldType: Fields.INPUT,
            label: "Account Number",
            name: "account_number",
            placeholder: "Account Number",
            required: true,
            value: editData ? editData.account_number : "",
          },
          {
            data: removeCountriesWithEmptyCurency(),
            fieldType: Fields.DROPDOWN,
            type: "full",
            name: "country",
            label: "Country",
            placeholder: "Select Country",
            required: true,
            labelExtractor: (item) => item.name?.toUpperCase(),
            valueExtractor: (item) => item.name,
            onSearch: (value) => this.countrySearch(value),
            value: editData ? this.extractCountry(editData?.country) : "",
          },
          {
            data: removeCountriesWithEmptyCurency(),
            fieldType: Fields.DROPDOWN,
            type: "full",
            name: "currency",
            label: "Currency",
            placeholder: "Select Currency",
            required: true,
            labelExtractor: (item) =>
              `${item.name?.toUpperCase()} - ${item?.currency?.name?.toUpperCase()} (${
                item?.currency?.symbol
              })`,
            valueExtractor: (item) => item?.currency?.name,
            onSearch: (value) => this.currencySearch(value),
            value: editData ? this.extractCountry(editData?.currency) : "",
          },
          {
            fieldType: Fields.INPUT,
            label: "Bank Code",
            name: "bank_code",
            placeholder: "Bank Code",
            required: false,
            value: editData ? editData.bank_code : "",
          },
          {
            fieldType: Fields.INPUT,
            label: "Bank Branch",
            name: "bank_branch",
            placeholder: "Branch Name",
            required: true,
            value: editData ? editData.bank_branch : "",
          },
          {
            fieldType: Fields.INPUT,
            label: "Branch Code",
            name: "branch_code",
            placeholder: "Branch Code",
            required: false,
            value: editData ? editData.branch_code : "",
          },
          {
            fieldType: Fields.INPUT,
            name: "swift_code",
            label: "Swift Code",
            placeholder: "Bank Swift Code",
            required: false,
            value: editData ? editData.swift_code : "",
          },
        ]}
        onSubmit={(data, reset) => {
          data = {
            ...data,
            id: editData ? editData.id : null,
            enterprise: this.props.enterprise?.id,
          };
          this.handleSubmit(data, reset, editData ? true : false);
        }}
        formSubmitBtnText={editData ? "Updated Info" : "Create Wallet"}
        subtitle=""
        title=""
      />
    );
  };

  showResponse = (res, isEdit, reset) => {
    if (res && res.success) {
      this.updateRedux(isEdit, res.data);
      this.close();
      this.props.toggleToast({
        show: true,
        message: !isEdit
          ? "Bank Account created successfully"
          : "Bank Account Updated Successfully",
        type: "success",
      });
      reset();
    } else {
      this.props.toggleToast({
        show: true,
        message: "An error occured. Try again",
        type: "failure",
      });
    }
  };

  triggerBackendDelete = (item, wallets) => {
    ApiCallHandler.send(URLS.ARCHIVE_BANK_WALLETS, "POST", {
      id: item?.id,
    }).then(() => {
      let newWallets = wallets.filter((wallet) => wallet.id !== item.id);
      let newEnterprise = this.props.enterprise;
      newEnterprise.bank_wallets = newWallets;
      this.props.editRecord(this.props.enterprises, newEnterprise);
    });
  };

  render() {
    let { wallets } = this.props;
    return (
      <div id="bank-wallet-wrapper">
        <div id="header-txt-and-add-container">
          <div style={{ marginLeft: "20px" }}>
            <Title className="text-toggler" onClick={() => this.handleAdd()}>
              <FontAwesomeIcon icon={faPlus} /> New Bank Account
            </Title>
          </div>
        </div>

        <Header title="Bank Accounts" subtitle="" />
        <div id="bank-wallet-list-container">
          {!isEmpty(wallets) ? (
            wallets?.map((wallet) => this.showBankWallets(wallet))
          ) : (
            <div>
              <NoItems text="No Bank Accounts Found. PLease Create One." />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const localEnterprise = localStorage.getItem("currentEnterprise")
    ? JSON.parse(localStorage.getItem("currentEnterprise"))
    : null;
  const enterprise = state.enterprises?.find(
    (item) => item?.id === localEnterprise.id
  );
  return {
    wallets: enterprise?.bank_wallets,
    enterprise: enterprise,
    enterprises: state.enterprises,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleModal: toggleModalAction,
      toggleToast: toggleToastAction,
      editRecord: (current_data, new_data) =>
        dispatch(
          editMutationAction("ENTERPRISE_MUTATION", current_data, new_data)
        ),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BankWallet);
