import React, { Component } from "react";
import { connect } from "react-redux";
import LoadingPage from "shared/components/loading/LoadingPage";
class Playground extends Component {
  state = {
    data: [],
  };

  render() {
    return (
      <div className="" style={{ padding: 30 }}>
        <LoadingPage />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.nothing,
  };
};

export default connect(mapStateToProps, null)(Playground);
