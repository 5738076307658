import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./AllInvestorsList.css";
import Header from "../../../components/header/Header";
import Table from "../../../tools/components/v1/widgets/Table/Table";
import NoItems from "tools/components/v1/widgets/NoItems/NoItems";
import { withRouter } from 'react-router-dom';
import { slugifyMe } from "shared/js/utils";


class AllInvestorsList extends Component {
  isEnterprisePartner(partnership) {
    if (partnership?.partner_type === "ENTERPRISE") {
      return true;
    } else return false;
  }

  prepareTableData = (data) => {
    return data?.map((item) => [
      <p onClick={() => this.showDetail(item)}>
        {item?.user?.first_name} {item?.user?.last_name}
        {this.isEnterprisePartner(item) && item?.enterprise?.name }
      </p>,
      <p onClick={() => this.showDetail(item)}>{item?.opportunity?.name}</p>,
      <p onClick={() => this.showDetail(item)}>{item?.shares}</p>,
      <p onClick={() => this.showDetail(item)}>{this.isEnterprisePartner(item)? item?.enterprise?.email : item?.user?.email}</p>,
    ]);
  };

isEnterprisePartner = (item)=>{
  if(item?.partner_type === "ENTERPRISE"){
    return true;
  }else return false;
}

  showDetail = (item) => {
    localStorage.partner = item?.id + "*" + item?.user?.id;
    // let name = item?.user?.first_name + " " + item?.user?.last_name;

    const name =this.isEnterprisePartner(item) ? item?.enterprise?.name : `${item?.user?.first_name} ${item?.user?.last_name}`;

    this.props.history.push(`/partners/${slugifyMe(name)}`);
  };
  render() {
    const { partners } = this.props;
    return (
      <div style={{ width: "90%", margin: "auto", marginTop: "1rem" }}>
        <div className="all-investors-list-container">
          <Header title="List of Your Partners" subtitle="  " />

          <div>
            {partners?.length ? (
              <Table
                columns={["Name", "Opportunity", "Shares", "Contact"]}
                data={this.prepareTableData(partners)}
                hoverAnimation={false}
                rootClass="asset-docs-table"
              />
            ) : (
              <div>
                <NoItems text="No Partners" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    partners: state.enterprisePartners,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AllInvestorsList));
