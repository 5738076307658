import React,{Component} from "react";
import TabView from "tools/components/v1/widgets/TabView/TabView";
import SingleInvestment from "./SingleInvestment";
import InvestmentsPayout from "./payouts/InvestmentsPayout";
import CreateAndListReports from "./Report/CreateAndListReports";
import AllOpportunityDocuments from "pages/investments /single investment/documents/list/AllOpportunityDocuments";
import InvestorsWrapper from '../../investments /single investment/investors/InvestorsWrapper';
import { connect } from 'react-redux';
import { convertNumberToShortForm } from 'shared/js/utils';
import { getLocalEnterprise } from 'shared/js/utils';
import { getMyCountryDetails } from '../../../shared/js/utils';
import AllAssets from "pages/investments /single investment/OpportunityAssets/listings/AllAssets";
import ListSingleOpportunityIncomes from "./income/ListSingleOpportunityIncomes";
import ListSingleOpportunityExpenses from "./expense/ListSingleOpportunityExpenses";
import Title from '../../../components/title/Title';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from 'react-router-dom';
import { faPen } from '@fortawesome/free-solid-svg-icons';


class PartnershipsShowWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToast: false,
      changer: localStorage.tab
        ? localStorage?.tab
        : "view-single-investment-details",
    };
  }

  TAB_DATA = [
    {
      name: "Details",
      id: "view-single-investment-details",
      component: (
        <SingleInvestment
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Reports",
      id: "all-investment-reports",
      component: (
        <CreateAndListReports
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Assets",
      id: "view-single-investment-assets",
      component: (
        <AllAssets
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    // WHY:: This feature will be needed in the future
    // {
    //   name: "Projections",
    //   id: "investment-config",
    //   component: (
    //     <InvestmentConfigWrapper
    //       switchTabs={(changer) => {
    //         this.state.changer(changer);
    //       }}
    //     />
    //   ),
    // },
    {
      name: "Documents",
      id: "opportunity-docs",
      component: (
        <AllOpportunityDocuments
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Parnters",
      id: "view-single-investment-partnerships",
      component: (
        <InvestorsWrapper
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Income",
      id: "all-opportunity-incomess",
      component: (
        <ListSingleOpportunityIncomes
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Expenses",
      id: "all-opportunity-expensess",
      component: (
        <ListSingleOpportunityExpenses
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
    {
      name: "Payouts",
      id: "all-payouts-for-single-investment",
      component: (
        <InvestmentsPayout
          switchTabs={(changer) => {
            this.state.changer(changer);
          }}
        />
      ),
    },
  ];

  calclateTotalIncome = ()=>{
    let { investment } = this.props;
    let totalIncome = 0;
    // eslint-disable-next-line
    investment?.income?.map((income) => {
      totalIncome = totalIncome + parseFloat(income.amount);
    });
    return totalIncome;
  }

  calclateTotalExpense = ()=>{
    let { investment } = this.props;
    let totalExpense = 0;
    // eslint-disable-next-line
    investment?.expenses?.map((expense) => {
      totalExpense = totalExpense + parseFloat(expense.amount);
    });
    return totalExpense;
  }


  render() {
    let {investment, currentEnterprise} = this.props
      let {currency} = getMyCountryDetails(
        "currency",
        currentEnterprise?.currency
      );
    return (
      <>
        <div style={{ marginTop: 20 }}>
          <div style={{ width: "93%", margin: "auto" }}>
            <div className="flex-me stats-root">
              <div>
                <h1>
                  <p>{investment?.name}</p>
                </h1>
              </div>

              <div className="everyday-flex right-of-title-box  stats-container">
                <h6>In Income</h6>
                <h1>
                  {currency?.symbol}{" "}
                  {convertNumberToShortForm(
                    parseFloat(this.calclateTotalIncome())?.toFixed(2)
                  ) || parseFloat(0.0)?.toFixed(2)}
                  <span>{currency?.code} </span>
                </h1>

                <h6 style={{ color: "brown", marginTop: 10 }}>In Expenses</h6>
                <h2 style={{ color: "brown" }}>
                  {currency?.symbol}{" "}
                  {convertNumberToShortForm(
                    parseFloat(this.calclateTotalExpense())?.toFixed(2)
                  ) || parseFloat(0.0)?.toFixed(2)}
                  <span>{currency?.code} </span>
                </h2>
              </div>
            </div>

            <div style={{ marginBottom: 15 }}>
              <Title
                style={{ color: "#3498DB", fontSize: "1rem", cursor:'pointer',marginLeft:15 }}
                onClick={() => {
                  localStorage.invEDT = this.props.investment?.id || null;
                  this.props.history.push("/opportunity/create");
                }}
              >
              <FontAwesomeIcon icon={faPen} />  Update Partnership
              </Title>
            </div>

            <TabView
              data={this.TAB_DATA}
              contentAreaStyle={{ paddingTop: 30 }}
              defaultTab={this.state.changer}
              onMount={(changer) => this.setState({ changer })}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let currentInvestment = state.investments?.find((x) => x.id === localStorage.currentInvestmentId)
  const currentEnterprise = state.enterprises?.find((x) => x.id === getLocalEnterprise()?.id)
  return {
    investment: currentInvestment,
    currentEnterprise,
  }
}

export default withRouter(connect(mapStateToProps, null)(PartnershipsShowWrapper));