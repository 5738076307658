import React from "react";
import "./Title.css";
export default function Title(props) {
  const { style = {}, className = "", onClick } = props;
  return (
    <h3
      className={`k-title ${className}`}
      style={style}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      {props.children}
    </h3>
  );
}
