import Header from "components/header/Header";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import "./SingleInvestment.css";
import Title from "components/title/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLocalEnterprise } from 'shared/js/utils';
import {
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { getMyCountryDetails } from '../../../shared/js/utils';

class SingleInvestment extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  numFormatter = (num) => {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K";
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M";
    } else if (num < 900) {
      return num;
    }
  };

  convertDate = (date) => {
    return new Date(date).toLocaleString("en-us", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };


  getSharesDistribution = (opportunity) => {
    if (opportunity?.partnerships?.length >0) {
      let totalShares = 0;
      opportunity?.partnerships.forEach((partnership) => {
        totalShares += partnership.shares;
      });
      return {
        total: opportunity?.shares,
        available: opportunity?.shares - totalShares,
        bought: totalShares,
      };
    }

    return {
      total: opportunity?.shares,
      available: opportunity?.shares,
      bought: 0,
    };
  };

  showStats = (opportunity) => {
    let shares = this.getSharesDistribution(opportunity);
    let {currentEnterprise} = this.props
            let {currency} = getMyCountryDetails(
              "currency",
              currentEnterprise?.currency
            );

    return (
      <div>
        <div className="divider"></div>
        <div className="divider"></div>
        <div id="stats-container">
          <div id="shares-main-wrapper">
            <div id="item-title">Shares</div>
            <div id="shares-container">
              <div>
                <p>{this.numFormatter(shares?.total)}</p>
                <span>Total</span>
              </div>
              <div>
                <p>{this.numFormatter(shares?.bought)}</p>
                <span>Bought</span>
              </div>
              <div>
                <p>{this.numFormatter(shares?.available)}</p>
                <span>Available</span>
              </div>
            </div>
          </div>

          <div id="investors-main-wrapper">
            <div id="item-title">Fees</div>
            <div id="investors-container">
              <div>
                <p>{opportunity?.carry || 0.0} %</p>
                <span>Carry</span>
              </div>
              <div>
                <p>
                  {currency?.symbol} {opportunity?.mgmt_fee || 0.0}{" "}
                </p>
                <span>Mgmt. Fee</span>
              </div>
              <div>
                <p>{opportunity?.admin_fee || 0} %</p>
                <span>Admin fee</span>
              </div>
            </div>
          </div>

          <div id="price-main-wrapper">
            <div id="item-title">Price</div>
            <div id="price-container">
              <div>
                <p style={{ color: "green" }}>
                  {currency?.symbol} {opportunity.price_per_share}
                </p>
                <span>Price Per Share</span>
              </div>
            </div>
          </div>
        </div>
        <div className="divider"></div>
      </div>
    );
  };

  showInvestmentInfo = (opportunity) => {
    let title = "about opportunity".toUpperCase();
    return (
      <div id="other-info-wrapper">
        <div id="about-investment">
          <Title>{title}</Title>
          <div
              className="tweet-card-content "
              dangerouslySetInnerHTML={{
                __html:opportunity?.about,
              }}
            ></div>
        </div>
      </div>
    );
  };
  render() {
    const { currentInvestment: opportunity } = this.props;

    return (
      <div id="sigle-inv-wrapper">
        <Header title={opportunity.name.toUpperCase()} subtitle="" />

        <div style={{ marginLeft: "2%", marginBottom: "3rem" }}>
          <div id="closing-date-container">
            <FontAwesomeIcon icon={faClock} size="md" color="black" />
            <p>{this.convertDate(opportunity.close_date)}</p>
          </div>
          {this.showStats(opportunity)}
          {this.showInvestmentInfo(opportunity)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  let currentEnterprise = state.enterprises?.find(item=> item?.id === getLocalEnterprise()?.id);
  return {
    investments: state.investments,
    currentEnterprise,
    currentInvestment: state.investments?.filter(
      (x) => x.id === localStorage.currentInvestmentId
    )[0] || {
      id: "",
      name: "",
      enterprise: { name: "" },
      price_per_share: 0,
      asset: {
        name: "",
        asset_type: { name: "UBER" },
        identification_number: "",
        identification_number_name: "",
      },
      about: "New Uber Oportunity Eko yie papa pa apa apa",
      shares: 0,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleInvestment)
);
