import React, { Component } from "react";
import FormGenerator from "../../../tools/components/v1/FormGenerator/FormGenerator";
import Fields from "../../../tools/components/v1/FormGenerator/Fields";
import "./CreateEnterpises.css";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import URLS from "../../../shared/js/urls";
import { toggleToastAction } from "store/actions/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { editMutationAction } from "../../../store/actions/actions";

class CreateEnterprise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      showToast: false,
      enterprise: {},
    };
  }

  updateRedux = (res) => {
    let { enterprises } = this.props;
    let enterprise = this.getCurrentEnterprise();
    let newEnterprise = enterprise;
    newEnterprise.contacts =
      enterprise?.contacts !== null ? res?.data[0] : res?.data;
    this.props.editRecord(enterprises, newEnterprise);
  };

  createOrUpdateContacts = async (data, reset) => {
    let enterprise = this.getCurrentEnterprise();
    let res;
    let { id, ...toSend } = data;
    toSend.enterprise = enterprise?.id;
    if (enterprise?.contacts !== null) {
      toSend.id = enterprise?.contacts?.id;
      res = await ApiCallHandler.send(URLS.UPDATE_CONTACTS, "POST", toSend);
    } else {
      res = await ApiCallHandler.send(URLS.CREATE_CONTACTS, "POST", toSend);
    }
    if (res && res?.success) {
      this.updateRedux(res);
        this.renderResponse(res, reset);
    }
  };

  handleSubmit = async (data, reset) => {
    let { enterprises } = this.props;
    let { bio, ...contacts } = data;

    if (bio) {
      let res = await ApiCallHandler.send(URLS.UPDATE_ENTERPRISES, "POST", {
        bio,
        id: data?.id,
      });
      if (res && res?.success) {
        this.props.editRecord(enterprises, res?.data[0]);
        this.renderResponse(res, reset);
      }
    }

    if(Object.keys(contacts).length > 0) {
      this.createOrUpdateContacts(contacts, reset);
    }
  };

  renderResponse = (res, reset) => {
    if (res && res?.success) {
      this.props.toggleToast({
        show: true,
        message: "Enterprise Updated Successfully",
        type: "success",
      });
      reset();
      this.props.close();
    } else {
      this.props.toggleToast({
        show: true,
        message: "Error updating enterprise info. Try again",
        type: "failure",
      });
    }
  };
  getCurrentEnterprise = () => {
    const enterpriseLoc = localStorage.currentEnterprise
      ? JSON.parse(localStorage.currentEnterprise)
      : null;

    let enterprise = this.props?.enterprises?.find(
      (item) => item.id === enterpriseLoc.id
    );
    return enterprise;
  };

  render() {
    let enterprise = this.getCurrentEnterprise();
    if (!enterprise?.id) return <></>;
    return (
      <>
        <div>
          <FormGenerator
            elevation={0}
            fields={[
              {
                fieldType: FormGenerator.Fields.INPUT,
                label: "Twitter URL",
                name: "twitter",
                placeholder: "Twitter handle URL",
                value: enterprise?.id ? enterprise?.contacts?.twitter : "",
              },
              {
                fieldType: FormGenerator.Fields.INPUT,
                label: "Facebook",
                name: "facebook",
                placeholder: "Facebook handle URL",
                value: enterprise?.id ? enterprise?.contacts?.facebook : "",
              },
              {
                fieldType: FormGenerator.Fields.INPUT,
                label: "Instagram",
                name: "instagram",
                placeholder: "Instagram handle URL",
                value: enterprise?.id ? enterprise?.contacts?.instagram : "",
              },
              {
                fieldType: FormGenerator.Fields.INPUT,
                label: "LinkedIn",
                name: "linkedin",
                placeholder: "LinkedIn handle URL",
                value: enterprise?.id ? enterprise?.contacts?.linkedin : "",
              },

              {
                fieldType: Fields.RICHTEXT,
                name: "bio",
                label: "About the enterprise",
                placeholder:
                  "Brief description of what this Enterprise is about...",
                required: false,
                value: enterprise ? enterprise.bio : "",
              },
            ]}
            onSubmit={(data, reset) => {
              data = {
                ...data,
                id: enterprise.id,
              };
              this.handleSubmit(data, reset);
            }}
            formSubmitBtnText={"Update"}
            subtitle=""
            title=""
          />
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      editRecord: (current_data, new_data) =>
        dispatch(
          editMutationAction("ENTERPRISE_MUTATION", current_data, new_data)
        ),
    },
    dispatch
  );
};
const mapStateToProps = (state) => {
  return {
    enterprises: state.enterprises,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEnterprise);
