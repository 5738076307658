import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./SettlementsDetail.css";
import Title from "components/title/Title";
import NoItems from "tools/components/v1/widgets/NoItems/NoItems";
import Table from "tools/components/v1/widgets/Table/Table";
import { convertNumberToShortForm, getFileType } from "shared/js/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { IMAGE_TYPES } from "../../../../../shared/js/typeConstants";
import { toggleImagePreviewAction } from "store/actions/actions";
import { getMyCountryDetails } from '../../../../../shared/js/utils';

class SingleSettlementView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getRemender = () => {
    let { settlement } = this.props;

    let remender = settlement?.full_amount - settlement?.amount_to_receive;
    return remender.toFixed(2);
  };

  openImage = (image) => {
    window.open(image, "blank");
  };

  showCommentFile = (file) => {
    const fileType = getFileType(file);

    if (IMAGE_TYPES.includes(fileType))
      return (
        <img
          src={file}
          alt="  "
          onClick={() =>
            this.props.showImagePreview({ show: true, image: file })
          }
        />
      );

    return <FontAwesomeIcon icon={faFileAlt} fontSize="3rem" color="maroon" />;
  };

  showComment = (comment) => {
    return (
      <div className="comment-item-container " style={{ position: "relative" }}>
        <div style={{ display: "flex" }}>
          <div id="commentor-img-container">
            <img
              src={comment?.sender?.image || "https://i.pravatar.cc/300"}
              alt="  "
            />
          </div>
          <div id="commentor-info">
            <h4>{comment?.sender?.first_name}</h4>
            <small>4d</small>
          </div>
        </div>
        <div id="comment-body">
          <p>{comment?.body || ""}</p>
          <div id="comment-file-container">
            {comment?.attachment
              ? this.showCommentFile(comment?.attachment)
              : null}
          </div>
        </div>
      </div>
    );
  };

  calculateAmountFromPercentage = (percentage, amount) => {
    let _amount = (percentage / 100) * amount;
    return convertNumberToShortForm(_amount.toFixed(2));
  };
  getPecentage = (pref) => {
    let distribution = pref?.distribution;
    let total = 0;
    let dist;
    if (typeof distribution === "string") {
      distribution = JSON.parse(distribution);
    }
    if (distribution?.distribution === "PERCENTAGE")
      return distribution?.percentage;

    this.props?.prefs?.forEach((election) => {
      if (typeof election.distribution === "string") {
        dist = JSON.parse(election.distribution);
      } else {
        dist = election.distribution;
      }

      if (dist.distribution === "PERCENTAGE") {
        total += Number(dist.percentage);
      }
    });

    return (100 - total).toFixed(2);
  };

  prepareTableData = () => {
    let { settlement } = this.props;
    let prefs =  this.props?.prefs?.map((item) => {
let { currency } = getMyCountryDetails(item?.account?.currency);
      return [
        <p>{item?.payment_type}</p>,
        <p>{item?.account?.country}</p>,
        <p>{currency?.code}</p>,
        <p>{item?.account_type}</p>,
        <p>
          {item?.account_type === "MOMO"
            ? item?.account?.registered_name
            : item?.account?.bank_name}
        </p>,
        <p>
          *****{" "}
          {item?.account_type === "MOMO"
            ? item?.account?.phone_number.substring(5, 10)
            : item?.account?.account_number.substring(10, 14)}
        </p>,
        <p>{this.getPecentage(item)} %</p>,
        <p className="i-am-money">
          {this.calculateAmountFromPercentage(
            this.getPecentage(item),
            settlement?.amount_to_receive
          )}
        </p>,
      ];
    });
    return prefs
  };
  render() {
    let { settlement, currentEnterprise } = this.props;
    let country = getMyCountryDetails("currency", currentEnterprise?.currency);

    return (
      <>
        <div className="settlement-detail-root">
          <div className="elevate-foat top-card">
            <div id="report-card-logo-and-name-container">
              <div id="settlement-company-det">
                <h2>{settlement?.partner?.user?.first_name}</h2>
                <br />
                <p>{settlement?.opportunity?.name} </p>
              </div>
            </div>

            <div className="total-received-container">
              <p className="total-received-title">To Receive</p>

              <div className="received-money-container">
                <p>{country?.currency?.symbol}</p>
                <h2>
                  {convertNumberToShortForm(
                    settlement?.amount_to_receive?.toFixed(2)
                  )}
                </h2>
              </div>
            </div>

            <div>
              <p className="total-received-title pending">Remaining</p>

              <div className="received-money-container">
                <p>{country?.currency?.symbol}</p>
                <h2>{convertNumberToShortForm(this.getRemender()) || 0.0}</h2>
              </div>
            </div>
            <div>
              <p className="total-received-title">Charges</p>

              <div className="received-money-container">
                <p>{country?.currency?.symbol}</p>
                <h2 style={{ color: "#EC7063" }}>
                  {convertNumberToShortForm(
                    settlement?.transaction_charges?.toFixed(2)
                  ) || 0.0}
                </h2>
              </div>
            </div>
          </div>

          <div className="payment-distribution-container">
            <Title>Distribution</Title>
            {!this.props?.prefs ? (
              <NoItems text="Please check add a payment Preference" />
            ) : (
              <>
                <Table
                  columns={[
                    "Payment Type",
                    "Country",
                    "Currency",
                    "Account Type",
                    "Account",
                    "Account Number",
                    "percentage",
                    `Amount (${country?.currency?.symbol})`,
                  ]}
                  data={this.prepareTableData()}
                  hoverAnimation={false}
                  rootClass="pref-table"
                />
              </>
            )}
          </div>

          <div className="settlent-comments-container">
            <div className="comments-list-container elevate-foat">
              <Title className="comments-header"> Settlement Updates </Title>
              <div className="divider"></div>
              {!settlement?.comments ? (
                <NoItems text="No comments yet" />
              ) : (
                settlement?.comments?.map((comment) =>
                  this.showComment(comment)
                )
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  const currentInvestment = state?.investments?.filter(
    (x) => x.id === localStorage.selectedOpprtunity
  )[0];
  const settlementId = window.location.href.split(
    "opportunity-payout-settlement-detail/"
  )[1];
  const currentPayout = currentInvestment?.payouts?.filter(
    (x) => x.id === localStorage.selectedPayout
  )[0];
  const settlement = currentPayout?.settlements?.filter(
    (x) => x.id === settlementId
  )[0];

  const localEnt = localStorage.getItem("currentEnterprise") ? JSON.parse(localStorage.getItem("currentEnterprise")) : {};
  const currentEnterprise = state?.enterprises?.find(item=> item?.id ===localEnt?.id);

  return {
    settlement: currentPayout?.settlements?.filter(
      (x) => x.id === settlementId
    )[0],
    prefs: settlement?.partner?.user?.payment_election,
    currentEnterprise,

  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showImagePreview: toggleImagePreviewAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleSettlementView);
