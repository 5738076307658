import { combineReducers } from "redux";
import {
  doNothingReducer,
  getAssetExpensesReducer,
  getAssetsReducer,
  toggleSidePaneReducer,
  getAssetTypesReducer,
  getAssetExpenseTypesReducer,
  getAssetIncomesReducer,
  getAssetIncomeTypesReducer,
  toggleToastReducer,
  setAssetCreateOrEditTypeReducer,
  setManageAssetTabReducer,
  toggleModalReducer,
  toggleLoadingBlanketReducer,
  setCurrentEnterpriseReducer,
  loadInvestmentsReducer,
  setEditSetterReducer,
  setCurrentEnterpriseDashboardDetailsReducer,
  toggleImagePreviewReducer,
  getEnterpriseUserInvitationsReducer,
  getEnterprisesReducer,
  getAllCountryEnterpriseRequirementsReducer,
  getAllPartnersReducer,
  getExchangeRatesReducer,
  loadFormDataReducer,
  setTypeDataReducer,
} from "./all";

const motherReducer = combineReducers({
  nothing: doNothingReducer,
  sidePane: toggleSidePaneReducer,
  assets: getAssetsReducer,
  assetTypes: getAssetTypesReducer,
  assetExpenses: getAssetExpensesReducer,
  assetExpenseTypes: getAssetExpenseTypesReducer,
  assetIncomes: getAssetIncomesReducer,
  assetIncomeTypes: getAssetIncomeTypesReducer,
  toastProps: toggleToastReducer,
  assetEditStatus: setAssetCreateOrEditTypeReducer,
  assetManageTabId: setManageAssetTabReducer,
  modalProps: toggleModalReducer,
  blanketProps: toggleLoadingBlanketReducer,
  currentEnterprise: setCurrentEnterpriseReducer,
  investments: loadInvestmentsReducer,
  editStatusPayload: setEditSetterReducer,
  enterpriseDashboardContents: setCurrentEnterpriseDashboardDetailsReducer,
  imagePreviewProps: toggleImagePreviewReducer,
  enterpriseUserInvitations: getEnterpriseUserInvitationsReducer,
  countryEnterpriseRequirements: getAllCountryEnterpriseRequirementsReducer,
  enterprisePartners: getAllPartnersReducer,
  enterprises: getEnterprisesReducer,
  exchangeRates: getExchangeRatesReducer,
  formData:loadFormDataReducer,
  newlyCreatedTypeData:setTypeDataReducer,
});

export default motherReducer;
