import React, { Component } from "react";
import PropTypes from "prop-types";
import "./IncrementTextbox.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

export default class IncrementTextbox extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.handleChanges = this.handleChanges.bind(this);
  }

  handleChanges(e) {
    const { onChange } = this.props;
    if (onChange) onChange(e, parseFloat(e.target.value));
  }

  increase(increase = true) {
    const { onChange } = this.props;
    const ref = this.inputRef?.current;
    var value = ref?.value;
    value = parseFloat(value || 0);
    if (increase) value += 1;
    else value -= 1;
    ref.value = value;
    if (onChange) onChange({ target: { value } }, value);
  }

  render() {
    const { width, height, style, className, elevation } = this.props;
    const value = this.props.value || this.props.defaultValue;
    return (
      <div
        className={`increment-textbox ${className} elevate-${elevation}`}
        style={{
          width: `${width}px`,
          height: `${height}px`,
          ...style,
        }}
      >
        <div onClick={() => this.increase(false)} className="btn">
          <FontAwesomeIcon icon={faMinus} />
        </div>
        <input
          ref={this.inputRef}
          className="box-input"
          type="number"
          value={value || ''}
          onChange={this.handleChanges}
          placeholder={"0.0"}
        />

        <div onClick={() => this.increase()} className="btn">
          <FontAwesomeIcon icon={faPlus} />
        </div>
      </div>
    );
  }
}

IncrementTextbox.propTypes = {
  /** additional custom styling */
  style: PropTypes.object,
  /** additional custom class name */
  className: PropTypes.string,
  /** width */
  width: PropTypes.number,
  /** height */
  height: PropTypes.number,
  /** default value for the increment box */
  defaultValue: PropTypes.number,
  /** function to be called when value is updated
   * @param e event obj
   * @param newValue the updated value from the increment textbox
   */
  onChange: PropTypes.func.isRequired,
  /** Provide an int value or a text that maps to any of our predefined classes. The text or number here will be resolved to elevate-{whatever you provide}. Take a look at our elevation class list */
  elevation: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
};

IncrementTextbox.defaultProps = {
  style: {},
  width: 150,
  height: 45,
  defaultValue: 12,
  elevation: 1,
};
