import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FormGenerator from "../../../tools/components/v1/FormGenerator/FormGenerator";
import { editMutationAction } from "../../../store/actions/actions";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import URLS from "../../../shared/js/urls";


class EnterpriseContactInfo extends Component {
  constructor(props) {
    super(props);
    this.contactInfoRef = React.createRef();
  }

  componentDidMount() {
    let { onMount } = this.props;
    if (onMount) {
      onMount(() => this.saveDataToDB());
    }
  }

  saveDataToDB = async () => {
    let { enterprise, enterprises } = this.props;
    const data = this.contactInfoRef.current?.onClickFromOutside();
    let res;
    if (data) {
      data.enterprise = enterprise?.id;
      if(enterprise?.contacts !== null){
        data.id = enterprise?.contacts?.id;
      res = await ApiCallHandler.send( URLS.UPDATE_CONTACTS,"POST",data);
      }
      else{

        res = await ApiCallHandler.send(URLS.CREATE_CONTACTS, "POST", data);
      }
      if (res && res?.success) {
        let newEnterprise  = enterprise
        newEnterprise.contacts = enterprise?.contacts !== null ? res?.data[0] : res?.data
        this.props.editRecord(enterprises, newEnterprise);
      }
    }
  };
  render() {
        let { enterprise } = this.props;
    const formData = [
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "General email",
        name: "primary_email",
        placeholder: "General email or official email",
        required: true,
        value: enterprise?.id ? enterprise?.contacts?.primary_email : "",
      },
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "Other email",
        name: "secondary_email",
        placeholder: "Secondary email",
        value: enterprise?.id ? enterprise?.contacts?.secondary_email : "",
      },
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "Primary phone Nuber",
        name: "primary_phone",
        placeholder: "Primary phone number (e.g. +233244445555)",
        required: true,
        value: enterprise?.id ? enterprise?.contacts?.primary_phone : "",
      },
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "Secondary Phone Number",
        name: "secondary_phone",
        placeholder: "Secondary Phone Number (e.g. +233244445555)",
        value: enterprise?.id ? enterprise?.contacts?.secondary_phone : "",
      },
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "Website URL",
        name: "website",
        placeholder: "Website URL",
        value: enterprise?.id ? enterprise?.contacts?.website : "",
      },
    ];

    return (
      <div>
        {" "}
        <FormGenerator
          elevation={0}
          subtitle=""
          title=""
          formSubmitBtnText={"Save Changes"}
          fields={formData}
          hideSubmitBtn={true}
          ref={this.contactInfoRef}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const enterpriseLoc = localStorage.currentEnterprise
    ? JSON.parse(localStorage.currentEnterprise)
    : { name: "" };
  return {
    enterprise: state.enterprises?.filter(
      (item) => item.id === enterpriseLoc.id
    )[0],
    enterprises: state.enterprises,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editRecord: (current_data, new_data) =>
        dispatch(
          editMutationAction("ENTERPRISE_MUTATION", current_data, new_data)
        ),
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(EnterpriseContactInfo);
