import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleToastAction, toggleModalAction } from "store/actions/actions";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";

import { withRouter } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Title from "components/title/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "tools/components/v1/widgets/Table/Table";
import "./Investors.css";
import InvitationForm from "./InvitationForm";
import ShowActions from "../../../../shared/components/showActions/ShowActions";
import NoItems from '../../../../tools/components/v1/widgets/NoItems/NoItems';

class SingleInvestmentInvitations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resending: false,
      id: "",
      showActions: "",
    };
  }

  calculatesAvailableShares = () => {
    let { currentInvestment: investment } = this.props;
    let totalShares = 0;
    if (investment?.partnerships && investment?.partnerships?.length) {
      investment.partnerships.forEach((partnership) => {
        totalShares += partnership.shares;
      });
    }
    
       if (investment?.invitations?.length) {
         investment.invitations
           ?.filter((item) => item?.has_responded === null)
           ?.forEach((invitation) => {
             totalShares += invitation.shares;
           });
       }

    return Number(totalShares);
  };

  resendInvitation = async (item) => {
    this.setState({ resending: true });
    let { currentInvestment: opportunity } = this.props;

    const dataToSend = {
      invitation: item.id,
      name: item.name,
      email: item.email,
      opportunity: opportunity.id,
      url: window.location.protocol + "//" + window.location.host + "/",
      action: "RESEND",
    };
    const res = await ApiCallHandler.send(
      URLS.SEND_OPPORTUNITY_INVITATION_EMAIL,
      "POST",
      dataToSend
    );
    if (res.success) {
      this.setState({ resending: false });
      this.props.toggleToast({
        show: true,
        message: "Email Invitation Resent Successfully",
        type: "success",
      });
    } else {
      this.setState({ resending: false });
      this.props.toggleToast({
        show: true,
        message:
          "Sorry something happened, couldnt resend invitation. please try again later. Thanks",
        type: "failure",
      });
    }
  };
  toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      this.setState({
        showActions: false,
        id: "",
      });
    } else {
      this.setState({
        showActions: true,
        id: item?.id,
      });
    }
  };
  prepareTableData = () => {
    let { currentInvestment: opportunity } = this.props;
    let { invitations } = opportunity;

    if (invitations?.length > 0) {
      return invitations.map((item) => [
        <p>{item?.name}</p>,
        <p>{item?.email}</p>,
        <p>{item?.shares}</p>,
        <p>
          {item?.has_accepted ? (
            <span style={{ color: "green" }}>Yes</span>
          ) : (
            <span style={{ color: "maroon" }}>No</span>
          )}
        </p>,
        <p>
          {item?.has_responded ? (
            <span style={{ color: "green" }}>Yes</span>
          ) : (
            <span style={{ color: "maroon" }}>No</span>
          )}
        </p>,
          item?.has_accepted ? (
            <div>Approved</div>
          ) : (
            <ShowActions
              onEdit={this.handleAddOrEditInvitation}
              onResend={this.resendInvitation}
              item={item}
              id={this.state.id}
              toggleShowMoreOptions={this.toggleShowMoreOptions}
              show={this.state.showActions}
              loading={this.state.resending}
            />
          )
      ]);
    }
    return [];
  };

  handleAddOrEditInvitation = (item = {}) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `${item?.id ? "Update" : "Send"} Invitation`,
        children: (
          <InvitationForm
            opportunity={this.props.currentInvestment}
            totalShares={this.calculatesAvailableShares}
            editData={item}
          />
        ),
        themeColor: "var(--app-theme)",
      },
    });
  };

  renderTable = () => {
    return (
      <div style={{ marginBottom: 20 }}>
        <Title>Invitations Sent</Title>
        {this.props.currentInvestment?.invitations?.length > 0 ? (
          <Table
            columns={[
              "Name",
              "Email",
              "Shares",
              "Accecpted",
              "Responded",
              "Status",
            ]}
            data={this.prepareTableData()}
          />
        ) : (
          <div>
            <NoItems text="No Invitations Sent" />
          </div>
        )}
      </div>
    );
  };

  render() { 
    return (
      <>
        <Title
          className="text-toggler"
          onClick={() => this.handleAddOrEditInvitation()}
        >
          Invite Partners <FontAwesomeIcon icon={faPlus} />
        </Title>
        <br />
        {this.renderTable()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    investments: state.investments,
    currentInvestment: state?.investments?.filter(
      (x) => x.id === localStorage.currentInvestmentId
    )[0],
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleModal: toggleModalAction,
      toggleToast: toggleToastAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleInvestmentInvitations)
);
