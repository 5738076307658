import React, { Component } from "react";
import "./CreateAsset.css";
import FormGenerator from "tools/components/v1/FormGenerator/FormGenerator";
import Fields from "tools/components/v1/FormGenerator/Fields";
import ApiCallHandler from "shared/js/ApiCallHandler";
import URLS from "shared/js/urls";
import { connect } from "react-redux";
import {
  toggleToastAction,
  editMutationAction,
  setEditSetterAction,
  toggleModalAction,
} from "store/actions/actions";
import { bindActionCreators } from "redux";
import { hasEmptyFields, isEmpty } from "../../../../../shared/js/utils";
import { findAndReturnDraft } from "../../../../../shared/js/globalReduxInteractions";
import {
  createDraftWithParentFormData,
  updateTypeDataInRedux,
} from "shared/js/globalReduxInteractions";

import { getRandomStringKey } from "tools/components/v1/shared/js/utils";
class CreateAsset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentData: {
        name: "",
        id: "",
        identification_number_name: "",
        description: "",
        identification_number: "",
        cost: "",
        asset_type: "",
      },
      key: Math.random(),
      formData: {},
    };
  }

  componentDidMount() {
    let { item } = this.props;
    if (item) {
      this.setState({ currentData: item });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.item) {
      return {
        currentData: hasEmptyFields(state.currentData)
          ? { ...props.item, ...{} }
          : { ...props.item, ...state.currentData },
      };
    }
    return null;
  }

  updateReduxWithResponseData = (editMode, res) => {
    let { currentInvestment } = this.props;
    let assets = currentInvestment?.assets;
    if (!editMode) {
      assets = isEmpty(assets) ? [res] : [res, ...assets];
    } else {
      assets = assets.filter((item) => item?.id !== res[0]?.id);
      assets?.unshift(res[0]);
    }
    currentInvestment.assets = assets;
    this.props.editRecord(this.props.investments, currentInvestment);
  };

  handleCreate = async (data, reset) => {
    let { item: editData } = this.props;
    const currentData = editData;
    let res;
    let toSend = {
      ...data,
      asset_type: data && data.asset_type ? data.asset_type.id : "",
      opportunity: this.props.currentInvestment.id,
    };

    if (!this.props.item?.id) {
      res = await ApiCallHandler.send(
        URLS.CREATE_NEW_OPPORTUNITY_ASSET,
        "POST",
        toSend
      );
    } else {
      const dataToSend = {
        ...toSend,
        id: currentData.id,
      };
      res = await ApiCallHandler.send(
        URLS.UPDATE_NEW_OPPORTUNITY_ASSET,
        "POST",
        dataToSend
      );
    }
    if (res && res.success) {
      this.updateReduxWithResponseData(this.props.item?.id, res?.data);
      this.props.toggleToast({
        show: true,
        message: !this.props.item?.id
          ? "Asset created successfully"
          : "Asset Updated Successfully",
        type: "success",
      });
      reset();
      this.props.setEditPayload({});
      this.props.close();
    } else {
      this.props.close();
      this.props.toggleToast({
        show: true,
        message: "Error creating an asset. Try again",
        type: "failure",
      });
    }
  };

  close = () => {
    this.props.toggleModal({ show: false });
  };

  updateFields = (res) => {
    let { currentData } = this.state;
    let data = currentData;
    let draft = findAndReturnDraft(res?.draftId) || {};
    data = { ...data, ...draft };
    data.asset_type = res?.data;
    this.setState({ currentData: data, key: getRandomStringKey() });
  };

  render() {
    let { assetTypes: types } = this.props;
    let getFieldValue = (field) => {
      let value = this.state.currentData[field] || "";
      return value;
    };
    return (
      <div key={this.state.key}>
        <div id="form-container">
          <FormGenerator
            elevation={0}
            fields={[
              {
                fieldType: Fields.INPUT,
                label: "Enter asset name",
                name: "name",
                placeholder: "Asset name",
                required: true,
                value: getFieldValue("name"),
              },
              {
                data: types || [],
                fieldType: Fields.DROPDOWN,
                label: "Asset type",
                name: "asset_type",
                placeholder: "Your asset is of which type?",
                required: false,
                type: "full",
                value: getFieldValue("asset_type"),
                labelExtractor: (item) => item?.name,
                valueExtractor: (item) => item?.id,
                child: {
                  fields: [
                    {
                      fieldType: Fields.INPUT,
                      label: "Enter asset type name",
                      name: "name",
                      placeholder: "Enter asset type name",
                      required: true,
                      value: "",
                    },
                  ],
                  metaData: {
                    endpoint: URLS.CREATE_OPPORTUNITY_ASSET_TYPE,
                    additionalData: {
                      opportunity: this.props.currentInvestment?.id,
                    },
                    onSuccess: (res) => {
                      updateTypeDataInRedux(res?.data, "asset_types");
                      this.updateFields(res);
                    },
                    createDraft: (id) =>
                      createDraftWithParentFormData(this.state.formData, id),
                  },
                },
              },
              {
                fieldType: Fields.INPUT,
                label: "Enter asset identification number name",
                name: "identification_number_name",
                placeholder: "Asset identification number name",
                required: true,
                value: getFieldValue("identification_number_name"),
              },
              {
                fieldType: Fields.INPUT,
                label: "Enter asset identification number",
                name: "identification_number",
                placeholder: "Asset identification number ",
                required: true,
                value: getFieldValue("identification_number"),
              },
              {
                fieldType: Fields.INPUT,
                label: "Enter cost of asset",
                name: "cost",
                placeholder: "cost ",
                required: true,
                value: getFieldValue("cost"),
              },

              {
                fieldType: Fields.TEXTAREA,
                label: "Enter Description",
                name: "description",
                placeholder: "Write your description here... ",
                required: false,
                value: getFieldValue("description"),
              },
            ]}
            onSubmit={this.handleCreate}
            formSubmitBtnText={
              !this.props.item?.id ? "create asset" : "Save Changes"
            }
            subtitle=""
            title=""
            onStateChange={(data) => this.setState({ formData: data })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownerProps) => {
  const currentInvestment = state.investments.filter(
    (x) => x.id === ownerProps?.currentInvestment
  )[0];
  return {
    investments: state.investments,
    assetTypes: currentInvestment?.asset_types,
    currentInvestment: currentInvestment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      toggleModal: toggleModalAction,
      setEditPayload: (val) => dispatch(setEditSetterAction(val)),
      editRecord: (current_data, new_data) =>
        dispatch(editMutationAction("INVESTMENT", current_data, new_data)),
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateAsset);
