export const TAB_IDS = [
  "view-single-investment-details",
  "all-investment-reports",
  "view-single-investment-assets",
  "investment-config",
  "opportunity-docs",
  "view-single-investment-partnerships",
  "all-opportunity-incomess",
  "all-opportunity-expensess",
  "all-payouts-for-single-investment",
];