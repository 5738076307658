import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import FormGenerator from '../../../../../../tools/components/v1/FormGenerator/FormGenerator';
import Fields from '../../../../../../tools/components/v1/FormGenerator/Fields';
import ApiCallHandler from '../../../../../../shared/js/ApiCallHandler';
import URLS from '../../../../../../shared/js/urls';
import { DAYS_OF_WEEK } from 'shared/js/typeConstants';
import { parseMe } from '../../../../../../shared/js/utils';
import { editMutationAction } from '../../../../../../store/actions/actions';



class CreateAndEditAssetManager extends Component {
  onSubmit = (data, reset, toEdit) => {
    const { asset, enterprise } = this.props;
    data.asset = asset?.id;
    data.opportunity = asset?.opportunity;
    data.enterprise = enterprise?.id;
    data.info = JSON.stringify({
      sales_day: data?.sales_day || "MONDAY",
      status: data?.status || "PROBATION",
      more_info: data?.more_info || "Add extra information",
    });

    this.sendDataToBackend(data, reset, toEdit);
  };

  updateRedux = (toEdit,data )=>{
  const { asset, currentInvestment, updateItemInredux, investments} = this.props;
    let finalInvestment = currentInvestment;
    if (!toEdit?.id){
     let updatedManagers = asset?.managers?.length || asset?.managers !== null ? [...asset?.managers, data]: [data];
     asset.managers = updatedManagers;
     let newAssets = finalInvestment?.assets?.filter(item=> item.id !== asset?.id)
      newAssets.push(asset)
      finalInvestment.assets = newAssets

    }
    
    else{
        toEdit.user = data
        let updatedManager =toEdit
        let updatedManagers = asset?.managers?.filter(item=>item?.id !== toEdit?.id)
        updatedManagers.push(updatedManager)
        asset.managers = updatedManagers

        let newAssets = finalInvestment?.assets?.filter((item) => item.id !== asset?.id);
        newAssets.push(asset);
        finalInvestment.assets = newAssets;
    }
    updateItemInredux(investments, finalInvestment)


  }

  sendDataToBackend = (data, reset, toEdit) => {
    if (!toEdit?.id) {
      data.role_name = "ASSET MANAGER";
      ApiCallHandler.send(
        URLS.CREATE_OPPORTUNITY_ASSET_MANAGER,
        "POST",
        data
      ).then((res) => {
        if (res?.success) {
          this.updateRedux(toEdit, res?.data);
          reset()
          this.props.close()
        }
      });
    } else {
      data.id = toEdit?.id;
      ApiCallHandler.send(URLS.UPDATE_USERS, "POST", {
        id: toEdit?.user?.id,
        first_name: data?.first_name,
        last_name: data?.last_name,
        email: data?.email,
        phone_number: data?.phone_number,
        info: data?.info,
      }).then((res) => {
        if (res?.success) {
           this.updateRedux(toEdit, res?.data[0]);
           reset();
           this.props.close();
        }
      });
    }
  };

  render() {
    let { item: manager } = this.props;
    return (
      <div>
        <div id="form-container" style={{ marginBottom: 100 }}>
          <FormGenerator
            elevation={0}
            fields={[
              {
                fieldType: Fields.INPUT,
                label: "First Name",
                name: "first_name",
                placeholder: "First Name",
                required: true,
                value: manager?.id ? manager?.user?.first_name : "",
              },
              {
                fieldType: Fields.INPUT,
                label: "Last Name",
                name: "last_name",
                placeholder: "Last Name",
                required: true,
                value: manager?.id ? manager?.user?.last_name : "",
              },
              {
                fieldType: Fields.INPUT,
                label: "Email",
                name: "email",
                placeholder: "Email",
                required: true,
                value: manager?.id ? manager?.user?.email : "",
              },
              {
                fieldType: Fields.INPUT,
                label: "Phone number",
                name: "phone_number",
                placeholder: "Phone Number",
                required: true,
                value: manager?.id ? manager?.user?.phone_number : "",
              },
              {
                data: ["PROBATION", "WORKING", "TERMINATED"],
                fieldType: Fields.DROPDOWN,
                label: "Contract Status",
                name: "status",
                placeholder: "contract status",
                required: false,
                type: "full",
                value: manager?.id ? parseMe(manager?.user?.info)?.status : "",
              },
              {
                data: DAYS_OF_WEEK,
                fieldType: Fields.DROPDOWN,
                label: "Sales Day",
                name: "sales_day",
                placeholder: "Sales Day",
                required: false,
                type: "full",
                value: manager?.id
                  ? parseMe(manager?.user?.info)?.sales_day
                  : "",
              },
              {
                fieldType: Fields.RICHTEXT,
                label: "Adding Information",
                name: "more_info",
                placeholder:
                  "More information about manager(links, images anything).",

                value: manager?.id
                  ? parseMe(manager?.user?.info)?.more_info
                  : "",
              },
            ]}
            onSubmit={(data, reset) => this.onSubmit(data, reset, manager)}
            formSubmitBtnText={
              !this.props.item?.id ? "Create Manager" : "Save Changes"
            }
            subtitle=""
            title=""
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state =>{
  const localEnterprise = localStorage.currentEnterprise
      ? JSON.parse(localStorage.getItem('currentEnterprise'))
      : {}; 
    const enterprise = state.enterprises?.find(enterprise=> enterprise?.id === localEnterprise?.id)
return {
  enterprise,
  investments: state.investments,
  currentInvestment: state.investments.find(
    (item) => item?.id === localStorage.getItem("currentInvestmentId")
  ),
};
} 

const mapDispatchToProps = dispatch =>{
     return bindActionCreators(
       {
         updateItemInredux: (current_data, new_data) =>
           dispatch(editMutationAction("INVESTMENT", current_data, new_data)),
       },
       dispatch
     );
}


export default connect(mapStateToProps, mapDispatchToProps)(CreateAndEditAssetManager);