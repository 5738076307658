import React from "react";
import {
  faBuilding,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DEFAULT_FIRST_LAYER_NAME, SPLIT_KEY } from "./values";
import { Link } from "react-router-dom";
import {
  setCurrentEnterpriseAction,
} from "store/actions/actions";
import { useDispatch } from "react-redux";
import "./EnterpriseSwitch.css";
import logo from "../../../../../shared/imgs/klogo.png";
import { useSelector } from "react-redux";
import Verified from '../../../../../shared/components/verified/Verified';
export const BottomMenuBox = (props) => {
  const { menu } = props;
  return <div className="sb-bottom-menu-box">{generateMenu(menu, props)}</div>;
};

const GhostCurtain = (props) => {
  const { onClick } = props;
  return <div className="ghost" onClick={() => onClick()}></div>;
};

const createProperMenuText = (text, inShrankMode) => {
  if (inShrankMode) return text.length > 12 ? text.substr(0, 12) + "..." : text;
  return text.length > 20 ? text.substr(0, 20) + "..." : text;
};
// @TODO will find a proper way of swapping classes so that we can support more themes instead of 2
export const generateMenu = (children, props, otherParams) => {
  const { dark, shrink, onMenuItemClick, activeMenu } = props;
  const { parentID, parentName } = otherParams || {};
  if (!children) return;
  const jsx = (children || []).map((child) => {
    let { name, icon, link, onClick, className, style } = child;
    name = createProperMenuText(name, shrink);
    const activeMenuKeys = activeMenu?.subs || [];
    const isATopLevelChild = child?.id;
    const id = parentID || child?.id;
    const subMenu = child?.children;
    const menuKey = parentName + SPLIT_KEY + child?.name;
    const isSelected = activeMenuKeys.includes(menuKey);
    return (
      <div key={`${menuKey}`} id="menubox-content-container">
        <Link
          to={link}
          className={`sb-menu-item ${
            dark && isATopLevelChild && "sb-menu-item-dark"
          } 
          ${dark && !shrink && "sb-menu-item-dark"}
          
          ${shrink && "shrink-icon-size"} ${
            isSelected && dark && "menu-dark-selected"
          } ${
            isSelected &&
            dark &&
            shrink &&
            !isATopLevelChild &&
            "menu-light-selected"
          } ${isSelected && !dark && "menu-light-selected"} ${className}`}
          style={style || {}}
          onClick={(e) => {
            if (onClick) onClick(e);
            if (children) return onMenuItemClick(id, menuKey, parentName);
          }}
        >
          {icon && <FontAwesomeIcon icon={icon} />}
          {shrink && !isATopLevelChild && (
            <>
              <p style={{ fontSize: "1rem" }}>{name}</p>
              {subMenu && <FontAwesomeIcon icon={faCaretDown} />}
            </>
          )}
          {!shrink && (
            <>
              <p>{name}</p>
              {subMenu && <FontAwesomeIcon icon={faCaretDown} />}
            </>
          )}
        </Link>
        {/* ----------SUB CHILD LEVEL MAPPING ------ */}
        {subMenu && activeMenuKeys.includes(menuKey) && (
          <>
            {shrink && (
              <GhostCurtain
                onClick={() => onMenuItemClick(id, menuKey, parentName)}
              />
            )}
            <div
              className={`sub-menu-box ${
                shrink && "shrink-mode-dropdown elevate-float show-from-left"
              }`}
            >
              {generateMenu(subMenu, props, { parentID: id, parentName: name })}
            </div>
          </>
        )}
      </div>
    );
  });
  return jsx;
};

export const MenuBox = (props) => {
  const { dark, menu, animationClass, shrink } = props;
  if (!menu) return <></>;

  return menu.map((options, index) => {
    const { groupName, children } = options;
    const isDuringEnlarginAnimation = animationClass === "enlarge" && shrink;
    // -------------------- GROUP LEVEL MAPPING -------------
    return (
      <div
        className="sidebar-main-menu-box"
        key={"group-" + index.toString()}
        style={{ opacity: isDuringEnlarginAnimation ? 0 : 1 }}
      >
        <p
          className={`group-title ${dark ? "text-for-dark" : "text-for-light"}`}
          style={{
            fontSize: shrink ? ".6rem" : "0.8rem",
          }}
          id="group-name"
        >
          {groupName}
        </p>
        {generateMenu(children, props, {
          parentID: null,
          parentName: DEFAULT_FIRST_LAYER_NAME,
        })}
      </div>
    );
  });
};

export const CompanyName = (props) => {
  const { dark, shrink, companyName } = props;
  const dispatch = useDispatch();
  const loc = localStorage.currentEnterprise
    ? JSON.parse(localStorage.currentEnterprise)
    : { name: "KEHILLAH GHANA LTD" };

    const enterprise = useSelector((state) => state.enterprises?.find(item=>item?.id===loc?.id));
  const userData = localStorage.userDeta
    ? JSON.parse(localStorage.userDeta)
    : [];
  const [overlay, showOverlay] = React.useState(false);
 const allEnterprises = useSelector((state) => state?.enterprises);
  const showImage = (logo) => {
    return (
      <div>
        <img src={userData?.logo|| logo} alt="logo" id="ent-img" />
      </div>
    );
  };


  const showOtherEnterprises = (data) => (
    <div id="other-ent-item-wrapper">
      {data?.map((item, index) => {
        if (item?.name === enterprise?.name) return (null);
        return (
          <div
            key={index}
            id="other-ent-item-container"
            onClick={() => {
              dispatch(setCurrentEnterpriseAction(item));
              localStorage.currentEnterprise = JSON.stringify(item);
              window.location.href = "/dashboard";
            }}
          >
            {showImage(item?.logo || logo, null)}
            <div style={{ flexDirection: "column", marginLeft:15, display:"flex", justifyContent:"center", alignItems:'center' }}>
              <p>{item.name}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
  const showAllEnterprises = (allData) => {
    return (
      <div style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
        <div id="current-enterprise-det-container">
          {showImage(enterprise?.logo || logo, "main")}
          <div id="curr-text-container">
            <p>{enterprise?.name}</p>
            <span>{userData?.email}</span>
          </div>
        </div>

        <div>
          {allData && allData.length ? (
            <>
        <div className="item-divider"></div>
            {showOtherEnterprises(allData)}

            </>
          ) : (
            <></>
          )}
        </div>
        {/* this feature will be needed in the future */}
        {/* <div className="item-divider"></div>
        <center>
          <Button theme="themed" variant="Button.SOLID" style={{marginTop:20}}>
            <FontAwesomeIcon
              icon={faPlus}
              style={{
                color: "white",
                fontSize: "1.5rem",
              }}
            />{" "}
            Add An Enterprise
          </Button>
        </center> */}
      </div>
    );
  };
  const displayOverlay = () => {
   
    return (
      <div
        className="enterprise-switch-wrapper"
        onClick={() => showOverlay(!overlay)}
      >
        <div
          className={`elevate-float enterprise-switch-container ${
            shrink ? "on-shrink" : ""
          }`}
        >
          {showAllEnterprises(allEnterprises)}
        </div>
      </div>
    );
  };

  if (!companyName) return <></>;
  return (
    <>
      <div className={`company ${dark ? "company-dark" : "company-light"}`} onClick={() => showOverlay(!overlay)}>
        {shrink ? (
          <div style={{ marginLeft: 8 }}>
            <FontAwesomeIcon icon={faBuilding} />
          </div>
        ) : (
          <FontAwesomeIcon icon={faBuilding} />
        )}
        {!shrink && (
          <p style={{ margin: 0, marginLeft: 10 }}>{enterprise?.name} {" "} {enterprise?.is_verified ? <Verified bg={"green"} height={"11px"} width={"11px"}  id='verified'/> : null}</p>
        )}
        <FontAwesomeIcon icon={faCaretDown} id="my-carret" />
      </div>

      {overlay ? displayOverlay() : null}
    </>
  );
};

export const SidebarInfoBoxx = (props) => {
  const { dark, shrink, details } = props;
  const { userName, companyLogo, role } = details || {};
  if (!userName || !companyLogo || !role) return <></>; // yeah, dont show the entire section even if only one value isnt present
  return (
    <div className="sidebar-info-box">
      <div className="sidebar-info-head">
        <img
          className={`sidebar-info-img ${
            dark ? "dark-img-border" : "light-img-border"
          }`}
          src={companyLogo}
          alt="info media"
        />
        {!shrink && (
          <div className="sb-info-details">
            <p className={`sb-info-p ${dark && "sb-info-p-dark"}`}>
              {userName}
            </p>
            <small className={`sb-info-small ${dark && "sb-info-small-dark"}`}>
              {role}
            </small>
          </div>
        )}
      </div>
    </div>
  );
};

export const SidebarInfoBox = () => {
  return <></>;
};
