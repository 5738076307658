import React, { Component } from "react";
import FormGenerator from "../../../tools/components/v1/FormGenerator/FormGenerator";
import Fields from "../../../tools/components/v1/FormGenerator/Fields";
import Header from "components/header/Header";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import URLS from "../../../shared/js/urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faArchive, faPlus } from "@fortawesome/free-solid-svg-icons";
import Table from "tools/components/v1/widgets/Table/Table";
import Title from "components/title/Title";
import { connect } from "react-redux";
import {
  getAssetExpenseTypesAction,
  toggleToastAction,
} from "../../../store/actions/actions";
import "./CreateAssetExpenseType.css";

class CreateAssetExpenseType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      toEdit: {},
      show: false,
    };
  }

  toggleShow = () => this.setState({ show: !this.state.show });

  handleDeleteAssetExpenseType = async (item) => {
    let res = await ApiCallHandler.roamAndFind(
      URLS.ARCHIVE_ASSETS_EXPENSES_TYPES,
      "POST",
      { id: item.id }
    );
    if (res && res.success) {
      this.props.createToastMessage({
        show: true,
        message: "Asset Expense Type successfully deleted",
        type: "success",
      });
      this.props.getAllAssetExpenseTypes();
    } else {
      this.props.createToastMessage({
        show: true,
        message: "Error deleting the asset expense type. Try again",
        type: "failure",
      });
    }
  };

  handleEditAssetExpenseType = (item) => {
    this.setState({ editMode: true, toEdit: item, show: true });
    let topOfPage = document.getElementById(
      "create-asset-expense-type-container"
    );
    topOfPage.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  prepareTableData = () => {
    let { assetExpenseTypes } = this.props;
    let tableData = [];
    if (assetExpenseTypes?.length > 0) {
      assetExpenseTypes.forEach((item) => {
        tableData.push([
          <p>{item?.name}</p>,
          <p></p>,
          <p></p>,
          <p></p>,
          <p></p>,
          <div className="actions-container">
            <div
              id="edit"
              onClick={() => this.handleEditAssetExpenseType(item)}
            >
              <FontAwesomeIcon icon={faPen} color={"white"} size={"sm"} />
            </div>
            <div
              id="delete"
              onClick={() => this.handleDeleteAssetExpenseType(item)}
            >
              <FontAwesomeIcon icon={faArchive} color={"white"} size={"sm"} />
            </div>
          </div>,
        ]);
      });
    }
    return tableData;
  };

  showAllAssetExpenseTypes = () => {
    let { assetExpenseTypes } = this.props;
    return (
      <div>
        <div style={{ borderRadius: 10 }}>
          <Title>Asset Expense Types</Title>

          {assetExpenseTypes && assetExpenseTypes.length ? (
            <Table
              columns={["Name", " ", "  ", "  ", "\t", "Actions"]}
              data={this.prepareTableData()}
              hoverAnimation={false}
            />
          ) : (
            <div id="no-items-container">
              <h3>No Asset Expense Types</h3>
            </div>
          )}
        </div>
      </div>
    );
  };

  handleCreate = async (data, reset) => {
    let { toEdit, editMode } = this.state;
    let res;
    if (editMode) {
      res = await ApiCallHandler.send(
        URLS.UPDATE_ASSETS_EXPENSES_TYPES,
        "POST",
        {
          id: toEdit.id,
          name: data.name,
        }
      );
    } else {
      let ent = localStorage.currentEnterprise ? JSON.parse(localStorage.currentEnterprise) : {}
      res = await ApiCallHandler.send(
        URLS.CREATE_ASSETS_EXPENSES_TYPES,
        "POST",
        {
          name: data.name,
          enterprise: ent?.id,
        }
      );
    }

    if (res && res.success) {
      const msg = `Asset Expense Type ${
        editMode ? "updated" : "created"
      } successfully`;
      this.setState({
        toEdit: {},
        editMode: false,
      });
      this.props.createToastMessage({
        show: true,
        message: msg,
        type: "success",
      });
      reset();
      this.toggleShow();
      this.props.getAllAssetExpenseTypes();
    } else {
      const msg = `Error ${
        editMode ? "updating" : "creating"
      } the asset expense type. Try again`;
      this.props.createToastMessage({
        show: true,
        message: msg,
        type: "failure",
      });
    }
  };
  showFieldValue = () => {
    let { editMode, toEdit } = this.state;
    let value = editMode ? toEdit.name : "";
    return value;
  };

  renderForm = (editMode, status) => {
    if (status) {
      return (
        <div className="scale-and-fade-in">
          <Header
            title={
              !editMode
                ? "Create Asset Expense Type"
                : `Edit "${this.state.toEdit.name}" `
            }
            subtitle="Create all your asset expense types here"
          />

          <FormGenerator
            elevation={0}
            fields={[
              {
                fieldType: Fields.INPUT,
                label: "Enter expense type name",
                name: "name",
                placeholder: "Asset Expense Type Name",
                required: true,
                defaultValue: this.showFieldValue(),
              },
            ]}
            onSubmit={this.handleCreate}
            formSubmitBtnText={!editMode ? "create" : "Save Changes"}
            subtitle=""
            title=""
            hasCancel
            cancel={(reset) => {
              reset();
              this.toggleShow();
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <Title className="text-toggler" onClick={this.toggleShow}>
            Create new asset expense type <FontAwesomeIcon icon={faPlus} />
          </Title>
        </div>
      );
    }
  };

  render() {
    let { editMode, show } = this.state;
    return (
      <div id="create-asset-expense-type-container">
        {this.renderForm(editMode, show)}
        {this.showAllAssetExpenseTypes()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assetExpenseTypes: state.assetExpenseTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllAssetExpenseTypes: () => dispatch(getAssetExpenseTypesAction()),
    createToastMessage: (obj) => dispatch(toggleToastAction(obj)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAssetExpenseType);
